import React from "react";
import { Card, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    stretch: { height: "100%", padding: theme.spacing(1) },
    item: { display: "flex", flexDirection: "column" }, // KEY CHANGES
}));

const Item = ({ content, ...rest }) => {
    const classes = useStyles();
    // console.log(props.children, rest);

    return (
        <Grid className={classes.item} item {...rest}>
            <Card className={classes.stretch}>{content}</Card>
        </Grid>
    );
};

export default Item;

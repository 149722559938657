import React, { useEffect, useState } from "react";

import { makeStyles, Box } from "@material-ui/core";

import CopyRight from "../CopyRight";
import Header from "../../layout/Header";
import PortalRouter from "../../routes/PortalRouter";

import NonManagerHeader from "../../layout/NonManagerHeader";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        minHeight: "100vh",
    },
    appBarSpacer: {
        paddingTop: theme.spacing(4),
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        padding: theme.spacing(3, 1),
        backgroundColor: "#fcfffa",
    },
    center: {
        [theme.breakpoints.down("md")]: {
            paddingTop: theme.spacing(3),
            flexGrow: 1,
            maxWidth: "100%",
            overflowX: "hidden",
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        [theme.breakpoints.up("md")]: {
            paddingTop: theme.spacing(4),
            flexGrow: 1,
            maxWidth: "100%",
            overflowX: "hidden",
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
        },
    },
    //============
    content2: {
        flexGrow: 1,
        padding: theme.spacing(3, 1),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -200,
        backgroundColor: "#fcfffa",
    },
    center2: {
        paddingTop: theme.spacing(4),
        flexGrow: 1,
        maxWidth: "100%",
        overflowX: "hidden",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    contentShift2: {
        padding: theme.spacing(3, 1),

        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 200,
    },
}));

export default function Portal(props) {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const [scoreboard, setScoreboard] = useState(
        // props.history.location.pathname === "/crm/package/select" ||
        props.history.location.pathname === "/crm/packages/picklist"
    );
    useEffect(() => {
        if (
            // props.history.location.pathname === "/crm/package/selectpuller" ||
            props.history.location.pathname === "/crm/packages/picklist"
        ) {
            setScoreboard(true);
        } else {
            setScoreboard(false);
        }
    }, [props.history.location.pathname]);

    useEffect(() => {
        setOpen(false);
    }, [props.history.location.pathname]);

    return (
        <div className={classes.root}>
            {/* {!scoreboard ? <Header /> : <NonManagerHeader handleOpen={(o) => setOpen(o)} open={open} />} */}
            <Header />
            <main
                // className={clsx(!scoreboard ? classes.content : classes.content2, open ? classes.contentShift2 : null)}
                className={clsx(classes.content, open ? classes.contentShift2 : null)}
            >
                <div className={classes.appBarSpacer}>
                    <div
                        // className={!scoreboard ? classes.center : classes.center2}
                        className={classes.center}
                    >
                        <PortalRouter basePath={"/crm"} />
                        <Box pt={4}>
                            <CopyRight />
                        </Box>
                    </div>
                </div>
            </main>
        </div>
    );
}

import Roles from "./Roles";

// import Alerts from "../components/Portal/Manager/Alerts/Alerts";
// import Orders from "../components/Portal/Manager/Orders/Orders";
// import OnDuty from '../components/Portal/Staff/OnDuty';
import Inventory from "../components/Portal/Manager/Inventory/Inventory";
import Staff from "../components/Portal/Manager/Staff/Staff";
import NewOrder from "../components/Portal/Pickers/NewOrder";
import PickList from "../components/Portal/Pickers/PickList";
import PrepList from "../components/Portal/Pickers/PrepList";
import SelectPuller from "../components/Portal/Pickers/SelectPuller";
// import Packing from "../components/Portal/Packers/Packing";
// import NewBox from "../components/Portal/Shipping/NewBox";
// import NewBox from "../components/Portal/Boxer/NewBox";
// import BoxOrder from "../components/Portal/Shipping/BoxOrder";
// import Shipping from "../components/Portal/Shipping/Shipping";
import Staffer from "../components/Portal/Manager/Staff/Staffer";
import GoogleMap from "../components/Portal/Map/Map";
import Schools from "../components/Portal/Schools/Schools";
import School from "../components/Portal/Schools/School";
import Report from "../components/Portal/Report/Report";
import Stocker from "../components/Portal/Stocker/Stocker";
// import IdentifyUser from "../components/Portal/IdentifyUser";
import PullList from "../components/Portal/Pickers/PullList";
import PurchaseOrders from "../components/Portal/Receiver/PurchaseOrders";
import PurchaseOrder from "../components/Portal/Receiver/PurchaseOrder";
import Feeder from "../components/Portal/Feeder/Feeder";
import FloatingInventory from "../components/Portal/Floating/FloatingInventory";
import ReadyToPack from "../components/Portal/Pickers/ReadyToPack";
// import PickupStation from "../components/Portal/Pickup/Pickup";
// import PickupOrderStation from "../components/Portal/Pickup/PickupOrder";
import Unaccounted from "../components/Portal/Unaccounted";
import Products from "../components/Portal/Products/Products";
import Product from "../components/Portal/Products/Product";
import Batch2Go from "../components/Portal/SubjectStation/SubjectWaiting";
import Palletizer from "../components/Portal/Palletizer/Palletizer";
import BoxInfo from "../components/Portal/Boxer/BoxInfo";
import Pallet from "../components/Portal/Palletizer/Pallet";
import Release from "../components/Portal/Release/Release";
import Pallets from "../components/Portal/Palletizer/Pallets";

const PrivateRoutes = [
    {
        component: Staff,
        path: "/staff",
        exact: true,
        permission: [Roles.SALES_REP],
        children: [
            {
                component: Staffer,
                path: "/:id",
                exact: true,
            },
        ],
    },
    {
        component: NewOrder,
        path: "/packages",
        exact: true,
        permission: [Roles.WCRM],
        children: [
            {
                component: PrepList,
                path: "/preplist",
                exact: true,
            },
            {
                component: SelectPuller,
                path: "/selectpuller",
                exact: true,
            },
            {
                component: PickList,
                path: "/picklist",
                exact: true,
            },
            {
                component: PullList,
                path: "/review_pulllist",
                exact: true,
            },
            {
                component: ReadyToPack,
                path: "/ready-to-pack",
                exact: true,
            },
        ],
    },
    // {
    //     component: Packing,
    //     path: "/packing",
    //     exact: true,
    //     permission: [Roles.WCRM],
    // },
    {
        component: Feeder,
        path: "/sorting",
        exact: true,
        permission: [Roles.WCRM],
    },
    {
        component: BoxInfo,
        path: "/boxing",
        exact: true,
        permission: [Roles.WCRM],
    },
    // {
    //     component: Pallet,
    //     path: "/pallet",
    //     exact: true,
    //     permission: [Roles.WCRM],
    //     children: [
    //         {
    //             component: Pallet,
    //             path: "/:id",
    //             exact: true,
    //         },
    //     ],
    // },
    {
        component: Pallets,
        path: "/pallets",
        exact: true,
        permission: [Roles.WCRM],
        children: [
            {
                component: Pallet,
                path: "/:id",
                exact: true,
            },
        ],
    },
    {
        component: Release,
        path: "/release",
        exact: true,
        permission: [Roles.WCRM],
    },
    {
        component: Inventory,
        path: "/floor-inventory",
        exact: true,
        permission: [Roles.SALES_REP, Roles.WCRM],
    },
    {
        component: Stocker,
        path: "/stocker",
        exact: true,
        permission: [Roles.SALES_REP, Roles.WCRM],
    },
    {
        component: PurchaseOrders,
        path: "/purchase-orders",
        exact: true,
        permission: [Roles.SALES_REP, Roles.WCRM],
        children: [
            {
                component: PurchaseOrder,
                path: "/:id",
                exact: true,
            },
        ],
    },
    {
        component: Report,
        path: "/report",
        exact: true,
        permission: [Roles.SALES_REP],
    },
    {
        component: Schools,
        path: "/schools",
        exact: true,
        permission: [Roles.SALES_REP],
        children: [
            {
                component: School,
                path: "/:id",
                exact: true,
            },
        ],
    },
    {
        component: GoogleMap,
        path: "/map",
        exact: true,
        permission: [Roles.SALES_REP],
    },
    {
        component: FloatingInventory,
        path: "/floating-inventory",
        exact: true,
        permission: [Roles.WCRM],
    },
    // {
    //     component: PickupStation,
    //     path: "/pickups",
    //     exact: true,
    //     permission: [Roles.WCRM],
    // },
    {
        component: Unaccounted,
        path: "/unaccounted",
        exact: true,
        permission: [Roles.WCRM, Roles.SALES_REP],
    },
    {
        component: Products,
        path: "/products",
        exact: true,
        permission: [Roles.WCRM],
        children: [
            {
                component: Product,
                path: "/:id",
                exact: true,
            },
        ],
    },
    // {
    //     component: IdentifyUser,
    //     path: "/user",
    //     exact: true,
    //     permission: [Roles.SALES_REP, Roles.WCRM],
    // },
    {
        component: Batch2Go,
        path: "/batch2go",
        exact: true,
        permission: [Roles.WCRM, Roles.SALES_REP],
    },
    {
        component: Palletizer,
        path: "/palletizer",
        exact: true,
        permission: [Roles.WCRM, Roles.SALES_REP],
    },
];
// {
//     component: Orders,
//     path: "/orders/pastorders",
//     exact: true,
//     permission: [Roles.ADMIN, Roles.MANAGER],
//     // children: [
//     //     {
//     //         component: Orders,
//     //         path: "/pastorders",
//     //         exact: true,
//     //     },
//     // ],
// },
export default PrivateRoutes;

import React, { useEffect, useRef, useState } from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    Grid,
    IconButton,
    InputBase,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DoneIcon from "@mui/icons-material/Done";
import Alert from "@material-ui/lab/Alert";
import Title from "../../../layout/Title";
import settings from "../../../constants/setting";
import dateFormatter from "../../../helpers/DateFormatter";
import NumberIncrementDecrement from "../../Reuseables/NumberIncrementDecrement";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    info: {
        padding: theme.spacing(2, 1),
    },
    table: {
        // margin: theme.spacing(2, 0),
    },
    spacing: {
        margin: theme.spacing(2, 0),
    },
    dialog: {
        width: "1000px",
        margin: "auto",
    },
    qtyBtnGroup: {
        width: "250px",
    },
    // disabled: { backgroundColor: "#bdbdbd" },
    completeBtn: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(2, 4),
    },
    search_bar: {
        padding: "1px 4px",
        display: "flex",
        alignItems: "center",
        margin: theme.spacing(0, 0, 2),
    },
    input: {
        marginLeft: theme.spacing(1),
        // flex: 1,
        width: "80%",
    },

    search_select: {
        width: "20%",
        fontSize: "20px",
    },

    divider: {
        height: 28,
        margin: 4,
    },
}));

export default function PurchaseOrder(props) {
    const classes = useStyles();
    const isMounted = useRef(false);
    const [po, setPo] = useState({});
    const [openProduct, setOpenProduct] = useState(false);
    const [openedProduct, setOpenedProduct] = useState({});
    const [receiveQty, setReceiveQty] = useState(0);
    const [error, setError] = useState(false);
    const [completeItemWarning, setCompleteItemWarning] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isPoComplete, setIsPoComplete] = useState(false);
    const [completePOError, setCompletePOError] = useState({
        error: false,
        errorMessage: "",
    });
    const [search, setSearch] = useState({
        searchBy: "product",
        searchString: "",
        results: "",
    });
    const [searchedProducts, setSearchedProducts] = useState([]);
    // const [loading, setLoading] = useState(false);
    const handleOpenProduct = (e, p) => {
        setOpenProduct(true);
        setOpenedProduct(p);
        setReceiveQty(p.quantityExpected);
    };

    const handleCompleteItem = (e, p) => {
        setOpenedProduct(p);
        setCompleteItemWarning(true);
    };

    const handleProductReceived = () => {
        if (receiveQty > 0 && openedProduct.quantityReceived < openedProduct.quantityExpected) {
            const addProduct = {
                quantity: receiveQty,
                productBinId: null,
                POProductId: openedProduct.poProductId,
            };
            const token = localStorage.getItem("warehouse-token");
            console.log(addProduct);
            fetch(settings.api().floating, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(addProduct),
            }).then((res) => {
                console.log(res);
                if (res.ok) {
                    fetchPO(po.id);
                    setError(false);
                    setOpenProduct(false);
                } else {
                    setError(true);
                }
            });
        }
        checkPoCompletion();
    };

    useEffect(() => {
        isMounted.current = true;
        const index = props.history.location.pathname.lastIndexOf("/");
        const id = props.history.location.pathname.substring(index + 1);

        fetchPO(id);

        return () => {
            isMounted.current = false;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchPO = (poId) => {
        const token = localStorage.getItem("warehouse-token");
        fetch(settings.api().pos + "/" + poId, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                // console.log(data);
                setPo(data);
                // setAllProducts(data);
                setIsLoading(false);
            });
    };

    const checkPoCompletion = () => {
        // let items = fetchPO.products;

        let items = po.products;
        // console.log(fetchPO);
        let completed = true;
        // console.log(items);

        for (let index = 0; completed && index < items.length; index++) {
            if (index.quantityExpected !== index.quantityReceived) {
                completed = false;
            }
        }
        setIsPoComplete(completed);
    };

    const handleCompletePO = () => {
        setCompletePOError({
            error: false,
            errorMessage: "data",
        });
        const token = localStorage.getItem("warehouse-token");
        fetch(settings.api().pos + `/${po.id}/complete`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status !== 200) {
                    return res.text();
                }
            })
            .then((data) => {
                setCompletePOError({
                    error: true,
                    errorMessage: data,
                });
            })
            .catch((error) => console.error(error));
    };

    if (isLoading) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "500px",
                }}
            >
                <CircularProgress />
            </div>
        );
    }
    // console.log(po.products);
    const handleSearch = () => {
        // setLoading(true);
        if (search.searchBy === "product") {
            if (search.searchString !== "") {
                const filteredProducts = po.products.filter((p) =>
                    p.productName.toLowerCase().includes(search.searchString.toLowerCase())
                );
                // console.log(filteredProducts);
                setSearchedProducts(filteredProducts);
                if (filteredProducts.length === 0) {
                    setSearch({ ...search, results: `0 results with search ${search.searchString}.` });
                }
            } else {
                setSearchedProducts([]);
            }
        } else {
            if (search.searchString !== "") {
                // console.log(search.searchString);
                const filteredProducts = po.products.filter((p) =>
                    p.sku.toLowerCase().includes(search.searchString.toLowerCase())
                );
                // console.log(filteredProducts);
                setSearchedProducts(filteredProducts);
                if (filteredProducts.length === 0) {
                    setSearch({ ...search, results: `0 results with search ${search.searchString}.` });
                }
            } else {
                setSearchedProducts([]);
            }
        }
        // setTimeout(function () {
        //     setLoading(false);
        // }, 1000);
    };
    return (
        <div>
            <Title title={`Purchase Order ID ${po.id}`} subtitle="Purchase Order" />
            <div className={classes.info}>
                <Grid container>
                    <Grid item xs>
                        <div>
                            <Typography variant="subtitle2" color="textSecondary" display="inline">
                                PO#{" "}
                            </Typography>
                            <Typography variant="h6" color="textPrimary" display="inline">
                                {po.poNumber}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="subtitle2" color="textSecondary" display="inline">
                                Supplier{" "}
                            </Typography>
                            <Typography variant="subtitle1" color="textPrimary" display="inline">
                                {po.supplierName}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="subtitle2" color="textSecondary" display="inline">
                                Status{" "}
                            </Typography>
                            <Typography variant="subtitle1" color="textPrimary" display="inline">
                                {po.poStatusName}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs align="right">
                        <div>
                            <Typography variant="subtitle2" color="textSecondary" display="inline">
                                Order Date{" "}
                            </Typography>
                            <Typography variant="subtitle1" color="textPrimary" display="inline">
                                {dateFormatter(po.createdUtc)}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="subtitle2" color="textSecondary" display="inline">
                                Last Modified{" "}
                            </Typography>
                            <Typography variant="subtitle1" color="textPrimary" display="inline">
                                {dateFormatter(po.modifiedUtc)}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Paper component="form" className={classes.search_bar}>
                <InputBase
                    autoFocus
                    className={classes.input}
                    placeholder="Search by"
                    inputProps={{
                        "aria-label": "search by",
                        style: {
                            fontSize: "20px",
                        },
                    }}
                    value={search.searchString}
                    onChange={(e) => setSearch({ ...search, searchString: e.target.value })}
                />
                <IconButton
                    // type="submit" onSubmit={handleSearch}
                    onClick={() => {
                        setSearch({ ...search, searchString: "", results: "" });
                        setSearchedProducts([]);
                    }}
                    className={classes.iconButton}
                    aria-label="clear"
                    size="medium"
                >
                    <CloseIcon />
                </IconButton>
                <Divider className={classes.divider} orientation="vertical" />
                <Select
                    disableUnderline
                    variant="standard"
                    className={classes.search_select}
                    value={search.searchBy}
                    onChange={(e) => setSearch({ ...search, searchBy: e.target.value })}
                >
                    <MenuItem value={"product"}>Product</MenuItem>
                    <MenuItem value={"sku"}>SKU</MenuItem>
                </Select>
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton
                    // type="submit" onSubmit={handleSearch}
                    onClick={handleSearch}
                    className={classes.iconButton}
                    aria-label="search"
                    size="medium"
                >
                    <SearchIcon style={{ fontSize: "2rem" }} />
                </IconButton>
            </Paper>
            {/* {loading && <CircularProgress className={classes.loading} />} */}
            {search.results !== "" && (
                <Alert severity="error" style={{ marginBottom: "16px" }}>
                    {search.results}
                </Alert>
            )}
            <Paper>
                <TableContainer className={classes.table}>
                    <Table stickyHeader>
                        <TableHead>
                            {/* <TableRow onClick={handleOpenProduct}>
                                <TableCell colSpan={5}>
                                    <Typography variant="subtitle1">Products</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography variant="body1">4 Total</Typography>
                                </TableCell>
                            </TableRow> */}
                            <TableRow>
                                <TableCell align="left">SKU</TableCell>
                                <TableCell>Product</TableCell>
                                <TableCell>Variation</TableCell>
                                <TableCell align="center">Expected Quantity</TableCell>
                                <TableCell align="center">Received</TableCell>
                                <TableCell align="center">Remaining</TableCell>
                                <TableCell align="center">Add To Inventory</TableCell>
                                <TableCell align="center">Complete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* {po.products &&
                                po.products.map((p) => (
                                    <TableRow
                                        key={p.poProductId}
                                        style={{
                                            backgroundColor:
                                                p.quantityExpected === p.quantityReceived ? "#e0e0e0" : undefined,
                                        }}
                                    >
                                        <TableCell>{p.sku}</TableCell>
                                        <TableCell>{p.productName}</TableCell>
                                        <TableCell align="center">{p.productVariationName}</TableCell>
                                        <TableCell align="center">{p.quantityExpected}</TableCell>
                                        <TableCell align="center">{p.quantityReceived}</TableCell>
                                        <TableCell align="center">{p.quantityRemaining}</TableCell>
                                        <TableCell align="center">
                                            <IconButton
                                                aria-label="add to inventory"
                                                disabled={p.quantityExpected === p.quantityReceived}
                                                onClick={(e) => handleOpenProduct(e, p)}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton
                                                aria-label="Complete"
                                                disabled={p.quantityExpected === p.quantityReceived}
                                                onClick={(e) => handleCompleteItem(e, p)}
                                            >
                                                <DoneIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))} */}
                            {searchedProducts.length > 0
                                ? searchedProducts.map((p, i) => (
                                      <TableRow
                                          key={i}
                                          style={{
                                              backgroundColor:
                                                  p.quantityExpected === p.quantityReceived ? "#e0e0e0" : undefined,
                                          }}
                                      >
                                          <TableCell>{p.sku}</TableCell>
                                          <TableCell>{p.productName}</TableCell>
                                          <TableCell>{p.productVariationName}</TableCell>
                                          <TableCell align="center">{p.quantityExpected}</TableCell>
                                          <TableCell align="center">{p.quantityReceived}</TableCell>
                                          <TableCell align="center">{p.quantityRemaining}</TableCell>
                                          <TableCell align="center">
                                              <IconButton
                                                  aria-label="add to inventory"
                                                  disabled={p.quantityExpected === p.quantityReceived}
                                                  onClick={(e) => handleOpenProduct(e, p)}
                                              >
                                                  <AddIcon />
                                              </IconButton>
                                          </TableCell>
                                          <TableCell align="center">
                                              <IconButton
                                                  aria-label="Complete"
                                                  disabled={p.quantityExpected === p.quantityReceived}
                                                  onClick={(e) => handleCompleteItem(e, p)}
                                              >
                                                  <DoneIcon />
                                              </IconButton>
                                          </TableCell>
                                      </TableRow>
                                  ))
                                : po.products &&
                                  po.products.map((p, i) => (
                                      <TableRow
                                          key={i}
                                          style={{
                                              backgroundColor:
                                                  p.quantityExpected === p.quantityReceived ? "#e0e0e0" : undefined,
                                          }}
                                      >
                                          <TableCell>{p.sku}</TableCell>
                                          <TableCell>{p.productName}</TableCell>
                                          <TableCell>{p.productVariationName}</TableCell>
                                          <TableCell align="center">{p.quantityExpected}</TableCell>
                                          <TableCell align="center">{p.quantityReceived}</TableCell>
                                          <TableCell align="center">{p.quantityRemaining}</TableCell>
                                          <TableCell align="center">
                                              <IconButton
                                                  aria-label="add to inventory"
                                                  disabled={p.quantityExpected === p.quantityReceived}
                                                  onClick={(e) => handleOpenProduct(e, p)}
                                              >
                                                  <AddIcon />
                                              </IconButton>
                                          </TableCell>
                                          <TableCell align="center">
                                              <IconButton
                                                  aria-label="Complete"
                                                  disabled={p.quantityExpected === p.quantityReceived}
                                                  onClick={(e) => handleCompleteItem(e, p)}
                                              >
                                                  <DoneIcon />
                                              </IconButton>
                                          </TableCell>
                                      </TableRow>
                                  ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <Button
                variant="outlined"
                size="large"
                className={classes.completeBtn}
                disabled={!isPoComplete}
                onClick={handleCompletePO}
            >
                Mark PO as Completed
            </Button>

            {openedProduct.poProductId && (
                <Dialog
                    fullWidth
                    open={openProduct}
                    onClose={() => setOpenProduct(false)}
                    aria-labelledby="receive po product"
                    aria-describedby="receive po product and add to floating inventory"
                >
                    {error && (
                        <Alert severity="error">
                            Invalid quantity - Please ensure quantity entered is within range.
                        </Alert>
                    )}
                    <DialogContent>
                        <Grid container justifyContent="space-between">
                            <Grid item xs>
                                <Typography variant="h6" color="textPrimary">
                                    Receive PO Product
                                </Typography>
                                <Typography variant="h5" color="textPrimary" display="inline">
                                    {openedProduct.productName}
                                </Typography>
                            </Grid>
                            {/* <Grid item>
                                <Button variant="outlined" color="secondary">
                                    Complete
                                </Button>
                            </Grid> */}
                        </Grid>
                        <Grid container className={classes.spacing}>
                            <Grid item xs>
                                <Typography variant="subtitle2" color="textSecondary">
                                    SKU{" "}
                                    <Typography variant="subtitle1" color="textPrimary" display="inline">
                                        {openedProduct.sku}
                                    </Typography>
                                </Typography>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Variation{" "}
                                    <Typography variant="subtitle1" color="textPrimary" display="inline">
                                        {openedProduct.productVariationName}
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item xs align="right">
                                {/*   <Typography variant="subtitle2" color="textSecondary">
                                    Last Modified{" "}
                                    <Typography variant="subtitle1" color="textPrimary" display="inline">
                                        {dateFormatter(openedProduct.modifiedUtc)}
                                    </Typography>
                                </Typography>*/}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="space-between" className={classes.spacing}>
                            <Grid item xs align="left">
                                <Typography variant="h6" color="textPrimary">
                                    {openedProduct.quantityExpected}
                                </Typography>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Expected Qty
                                </Typography>
                            </Grid>
                            <Grid item xs align="center">
                                <Typography variant="h6" color="textPrimary">
                                    {openedProduct.quantityRemaining}
                                </Typography>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Remaining Qty
                                </Typography>
                            </Grid>
                            <Grid item xs align="right">
                                <Typography variant="h6" color="textPrimary">
                                    {openedProduct.quantityReceived}
                                </Typography>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Received Qty
                                </Typography>
                            </Grid>
                        </Grid>

                        <div align="center">
                            <NumberIncrementDecrement value={receiveQty} handleValue={(v) => setReceiveQty(v)} />{" "}
                            <Typography variant="subtitle1" color="textSecondary">
                                Enter quantity received.
                            </Typography>
                        </div>
                    </DialogContent>

                    <DialogActions>
                        <div style={{ margin: "auto" }}>
                            <Button
                                style={{ width: "250px" }}
                                onClick={handleProductReceived}
                                color="primary"
                                variant="contained"
                            >
                                Add to Floating
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
            )}
            <Dialog
                fullWidth
                open={completeItemWarning}
                onClose={() => setCompleteItemWarning(false)}
                aria-labelledby="receive po product"
                aria-describedby="receive po product and add to floating inventory"
            >
                <DialogContent>
                    <Typography variant="body1" color="textPrimary" display="inline">
                        Are you sure that no more of{" "}
                    </Typography>
                    <Typography variant="subtitle1" color="textPrimary" display="inline">
                        <strong>{openedProduct.productName}</strong>
                    </Typography>
                    <Typography variant="body1" color="textPrimary" display="inline">
                        {" "}
                        is going to be received for this PO?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button
                        // onClick={handleProductReceived}
                        color="primary"
                        variant="outlined"
                    >
                        Yes
                    </Button>
                    <Button onClick={() => setCompleteItemWarning(false)} color="primary" variant="contained">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth
                open={completePOError.error}
                onClose={() => setCompletePOError({ ...completePOError, error: false })}
                aria-labelledby="Complete Purchase Order Error"
                aria-describedby="Complete Purchase Order Error"
            >
                <DialogContent>{completePOError.errorMessage}</DialogContent>

                <DialogActions>
                    <Button
                        onClick={() => setCompletePOError({ ...completePOError, error: false })}
                        color="primary"
                        variant="contained"
                    >
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

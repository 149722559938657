import React from "react";
import Chart from "react-apexcharts";

export default function Graph() {
    const graph = {
        series: [
            {
                name: "Revenue",
                data: [31, 40, 28, 51, 42, 109, 100],
            },
            {
                name: "Products Sold",
                data: [11, 32, 45, 32, 34, 52, 41],
            },
        ],
        xaxis: {
            type: "datetime",
            categories: [
                "2018-09-19T00:00:00.000Z",
                "2018-09-19T01:30:00.000Z",
                "2018-09-19T02:30:00.000Z",
                "2018-09-19T03:30:00.000Z",
                "2018-09-19T04:30:00.000Z",
                "2018-09-19T05:30:00.000Z",
                "2018-09-19T06:30:00.000Z",
            ],
        },
        options: {
            chart: {
                height: 350,
                type: "area",
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
            },

            tooltip: {
                x: {
                    format: "dd/MM/yy HH:mm",
                },
            },
        },
    };

    return (
        <div>
            <Chart options={graph.options} series={graph.series} type="area" height={350} />
        </div>
    );
}

import React, { useEffect, useRef, useState } from "react";
// import Title from "../../../layout/Title";
import OrderDetails from "./OrderDetails";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
    List,
    ListItem,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DoneIcon from "@material-ui/icons/Done";
import { CreatePackageId } from "../../../helpers/createPackageId";
import settings from "../../../constants/setting";
import Alert from "@material-ui/lab/Alert/Alert";
import clsx from "clsx";
// import { CreatePackageId } from "../../../helpers/createPackageId";

const useStyles = makeStyles((theme) => ({
    textField: {
        margin: "auto",
    },
    puller_list: {
        maxHeight: "50vh",
        overflowY: "auto",
    },
    onError: { backgroundColor: "#ffebee", color: "#EAE9D6" },

    collected: { backgroundColor: "#eeeeee", color: "#9e9e9e" },
}));

export default function PullList(props) {
    const classes = useStyles();
    const isMounted = useRef(false);
    const [packageScanned, setPackageScanned] = useState({});
    // const [pullerItems, setPullerItems] = useState([]);
    const [userId, setUserId] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [flaggedItems, setFlaggedItems] = useState([]);

    // console.log(props);

    useEffect(() => {
        isMounted.current = true;

        const obj = props.history.location.state;

        let sortedProducts = obj.products.sort((a, b) => a.pullOrder - b.pullOrder);
        let fItems = sortedProducts.filter((i) => i.error);

        setPackageScanned({ ...obj, products: sortedProducts });
        setFlaggedItems(fItems);

        // const packageId = props.history.location.search.split("=")[1];
        // // setPackageScanned({ ...packageScanned, packageId });
        // console.log(packageId);

        // const token = localStorage.getItem("warehouse-token");

        // const url =
        //     obj.packageType === 1
        //         ? settings.api().puller_kit_list + `/${obj.packageId}`
        //         : settings.api().puller_detail_list + `/${obj.packageId}`;
        // console.log(url);
        // const token = localStorage.getItem("warehouse-token");
        // fetch(url, {
        //     headers: {
        //         Authorization: `Bearer ${token}`,
        //         "Content-Type": "application/json",
        //         Accept: "application/json",
        //     },
        // })
        //     .then((res) => {
        //         if (res.status === 200) {
        //             return res.json();
        //         } else if (res.status === 401) {
        //             localStorage.clear();
        //             props.history.push("/");
        //         } else {
        //             const error = new Error(res.error);
        //             console.log(error);
        //             return res.json();
        //             // throw error;
        //         }
        //     })
        //     .then((data) => {
        //         setOrderPackage(data);
        //         const pullerList = data.products.filter((d) => !d.prepperProduct);
        //         isMounted.current && setPullerItems(pullerList);

        //         const prepperList = data.products.filter((d) => d.prepperProduct);
        //         isMounted.current && setPrepperItems(prepperList);

        //         const tmp = pullerList[0];
        //         setSelectedItem(tmp);
        //     })
        //     .catch((err) => {
        //         console.error(err);
        //     });

        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // console.log(packageScanned);
    // PK0000072314
    function handleUserIdEntered(e) {
        e.preventDefault();
        const { value } = e.target;
        setUserId(value);
        setErrorMessage("");
        setLoading(true);

        if (value.length === 13) {
            const token = localStorage.getItem("warehouse-token");
            // console.log(token);
            fetch(settings.api().fobs + `/${value}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    // console.log(res);
                    if (res.ok) {
                        return res.json();
                    } else {
                        const error = new Error(res.error);
                        throw error;
                    }
                })
                .then((data) => {
                    // console.log(data);
                    data.userId &&
                        props.history.push({
                            pathname: "/crm/packages/picklist",
                            search: `?package=${CreatePackageId(packageScanned.packageType, packageScanned.packageId)}`,
                            state: {
                                ...packageScanned,
                                pullerId: data.userId,
                            },
                        });

                    setUserId("");
                })
                .catch((err) => {
                    setErrorMessage("User does not exist. Try again.");
                    console.error(err);
                    setUserId("");
                });
        }

        setLoading(false);
    }

    // const doSomething = () => {
    //     // console.log(packageScanned, CreatePackageId(packageScanned.packageType, packageScanned.packageId));
    //     props.history.push({
    //         pathname: "/crm/packages/picklist",
    //         search: `?package=${CreatePackageId(packageScanned.packageType, packageScanned.packageId)}`,
    //         state: {
    //             ...packageScanned,
    //             pullerId: 77,
    //         },
    //     });
    // };

    // console.log(packageScanned.products.map((p) => console.log(p)));
    // console.log(
    //     packageScanned?.products.find((p) => {
    //         return p.productSku === "31445-00";
    //     })
    // );

    // console.log(flaggedItems);

    return (
        <div>
            {/* {isMounted.current && (
                <Title subtitle="Fulfillment" title={`Scan Employee ID to Pull Package# ${packageScanned.packageId}`} />
            )} */}
            <List>
                <ListItem>
                    <TextField
                        autoFocus
                        className={classes.textField}
                        variant="outlined"
                        placeholder="Scan Employee ID"
                        inputProps={{
                            maxLength: 13,
                            style: {
                                backgroundColor: "white",
                                fontSize: "24px",
                                textAlign: "center",
                                width: "300px",
                            },
                        }}
                        value={userId}
                        onChange={handleUserIdEntered}
                    />
                </ListItem>
                <ListItem>
                    <div style={{ margin: "auto" }}>
                        {loading && <CircularProgress />}
                        {errorMessage !== "" && errorMessage}
                    </div>
                </ListItem>
                <ListItem>
                    <Accordion style={{ width: "100%" }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="Order Details">
                            <Typography variant="subtitle1">Order Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordianDetails}>
                            <OrderDetails className={classes.package_info} orderPackage={packageScanned} />
                        </AccordionDetails>
                    </Accordion>
                    {/* <Card style={{ width: "100%" }}>
                        <CardContent>
                            <OrderDetails className={classes.package_info} orderPackage={packageScanned} />
                        </CardContent>
                    </Card> */}
                </ListItem>
                <ListItem>
                    {flaggedItems.length > 0 && (
                        <Alert severity="error" style={{ width: "100%" }}>
                            Flagged Item: There are {flaggedItems.length} items in this package flagged.
                        </Alert>
                    )}
                </ListItem>
                <ListItem>
                    <TableContainer component={Paper} className={classes.puller_list}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">ID</TableCell>
                                    <TableCell>Item</TableCell>
                                    {/* <TableCell align="center">Puller</TableCell> */}
                                    <TableCell align="center">Quantity</TableCell>
                                    <TableCell align="center">Location</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="center">Outstanding</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {packageScanned.products &&
                                    packageScanned.products.map((i) => (
                                        <TableRow
                                            key={i.packageProductId}
                                            className={clsx(
                                                i.error ? classes.onError : null,
                                                i.collected ? classes.collected : null
                                            )}
                                        >
                                            <TableCell className={`${i.collected && classes.collected}`}>
                                                {i.productSku}
                                            </TableCell>
                                            <TableCell className={`${i.collected && classes.collected}`}>
                                                {i.productName}
                                            </TableCell>
                                            {/* <TableCell align="center" className={`${i.collected && classes.collected}`}>
                                                {i.prepperProduct ? "true" : "false"}
                                            </TableCell> */}
                                            <TableCell align="center" className={`${i.collected && classes.collected}`}>
                                                {i.quantityNeeded}
                                            </TableCell>
                                            <TableCell align="center" className={`${i.collected && classes.collected}`}>
                                                {i.locations &&
                                                    i.locations.map((l, index) =>
                                                        index + 1 !== i.locations.length
                                                            ? `${l.location}, `
                                                            : l.location
                                                    )}
                                            </TableCell>
                                            <TableCell className={`${i.collected && classes.collected}`} align="center">
                                                {i.collected ? (
                                                    <DoneIcon className={classes.doneIcon} />
                                                ) : i.error ? (
                                                    i.errorMsg
                                                ) : (
                                                    "-"
                                                )}
                                            </TableCell>
                                            <TableCell className={`${i.collected && classes.collected}`} align="center">
                                                {i.quantityNeeded - i.quantityPicked}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ListItem>
            </List>

            {/* <Accordion className={classes.accordian}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="Order Details">
                    <Typography variant="subtitle1">Order Details</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordianDetails}> */}

            {/* </AccordionDetails> 
             </Accordion> */}
            {/* <Button onClick={doSomething}>testing</Button> */}
        </div>
    );
}

import React, { useEffect, useRef, useState } from "react";
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    ListItem,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    TableHead,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    TextField,
    Typography,
    useTheme,
    Divider,
    InputAdornment,
} from "@material-ui/core";
import TableCell from "@mui/material/TableCell"; // { tableCellClasses }
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Clear";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ErrorIcon from "@material-ui/icons/Error";
import Alert from "@material-ui/lab/Alert";
import settings from "../../../constants/setting";
import { formatNumber } from "../../Reuseables/Functions";
import StyledTableCell from "../../Reuseables/StyledTableCell";
import { Link, useHistory } from "react-router-dom";
// import NumberPad from "../../Reuseables/NumberPad";

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: theme.spacing(3),
    },
    barcodeScanner: {
        // padding: theme.spacing(1, 2),
        backgroundColor: "white",
        margin: theme.spacing(0, 0, 2),
    },
    card: {
        padding: theme.spacing(1),
        margin: theme.spacing(2, 0, 2),
    },
    buttons: { margin: theme.spacing(2, 0, 2) },
    info: {
        height: "100%",
        padding: theme.spacing(1),
        position: "relative",
    },
    image: {
        [theme.breakpoints.down("sm")]: {
            width: 200,
            height: 200,
        },
        width: 250,
        height: 250,
    },
    barcode: {
        width: 160,
    },
    cardHeader: {
        fontSize: 28,
        paddingLeft: 0,
        fontWeight: 600,
    },
    root: {
        backgroundColor: "#ff9800",
        borderColor: "#f57c00",
        color: "white",
        width: "200px",
        "&:hover": {
            backgroundColor: "#ef6c00",
            borderColor: "#ff9800",
            color: "white",
        },
    },
    onItem: {
        backgroundColor: "rgba(84,188,76,0.3)",
        color: "black",
    },
    // changeDialog: { padding: theme.spacing(1, 0) },
    formControl: {
        padding: theme.spacing(1, 0),
    },
}));

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//     [`&.${tableCellClasses.head}`]: {
//         backgroundColor: "#eeeeee",
//     },
// }));

export default function Stocker(props) {
    const classes = useStyles();
    const theme = useTheme();
    const inputRef = useRef();
    const history = useHistory();
    const [openTakeDialog, setOpenTakeDialog] = useState(false);
    const [openPutDialog, setOpenPutDialog] = useState(false);
    const [openAdjustDialog, setOpenAdjustDialog] = useState(false);
    const [barcode, setBarcode] = useState("");
    const [product, setProduct] = useState({});
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState({ icon: undefined, message: "" });
    const [changeProductDialog, setChangeProductDialog] = useState(false);
    const [unlinkProductDialog, setUnlinkProductDialog] = useState(false);
    const [selectProductDialog, setSelectProductDialog] = useState(false);
    const [bin, setBin] = useState([]);
    const [token] = useState(localStorage.getItem("warehouse-token"));
    const [takeQuantity, setTakeQuantity] = useState(0);
    const [takeRequest, setTakeRequest] = useState({ error: null, message: "" });
    const [putQuantity, setPutQuantity] = useState("");
    const [putRequest, setPutRequest] = useState({ error: null, message: "" });
    const [adjustValue, setAdjustValue] = useState({ total: 0, reason: "" });
    const [adjustRequest, setAdjustRequest] = useState({ error: null, message: "" });
    const [moveScannedBin, setMoveScannedBin] = useState({ error: null, errorMsg: "", binBarcode: "", binId: 0 });
    const [unaccountedReasons, setUnaccountedReasons] = useState([]);

    const [disableBarcodeScan, setDisableBarcodeScan] = useState(false);

    useEffect(() => {
        getUnaccountedReasons();
        return () => {
            clearTimeout();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeProduct = () => {
        setChangeProductDialog(true);
    };

    const handleTakeDialog = () => {
        if (takeQuantity <= 0) {
            setTakeRequest({ error: true, message: "Value cannot be zero." });
        } else {
            const data = new FormData();
            data.append("quantity", takeQuantity);
            fetch(settings.api().product_bins + `/${product.productBinId}/take`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    // "Content-Type": "application/json",
                },
                // body: JSON.stringify({ quantity: takeQuantity }),
                body: data,
            })
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        return res.json();
                    } else {
                        throw new Error(res.error);
                    }
                })
                .then((data) => {
                    setProduct({
                        ...product,
                        totalFloating: data.floating,
                        productBin: { ...product.productBin, quantity: product.productBin.quantity - takeQuantity },
                        bins: product.bins.map((b) =>
                            b.binBarcode === product.productBin.binBarcode
                                ? { ...b, quantity: product.productBin.quantity - takeQuantity }
                                : b
                        ),
                    });
                    setTakeQuantity(0);
                    setTakeRequest({
                        error: false,
                        message: "Items have been removed from the bin and added to the floating inventory.",
                    });
                })
                .catch((err) => {
                    setTakeRequest({ error: true, message: "Error, Check your quantity or contact the developer." });
                    console.error(err);
                });
        }
    };

    const handlePutDialog = () => {
        if (putQuantity <= 0) {
            setPutRequest({ error: true, message: "Value cannot be zero." });
        } else {
            const data = new FormData();
            data.append("quantity", putQuantity);
            fetch(settings.api().product_bins + `/${product.productBinId}/put`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: data,
            })
                .then(async (res) => {
                    // console.log(res);
                    if (res.status === 200) {
                        return res.json();
                    } else {
                        throw await res.json();
                    }
                })
                .then((data) => {
                    console.log(data);
                    setProduct({
                        ...product,
                        totalFloating: data.floating,
                        productBin: { ...product.productBin, quantity: data.binQuantity },
                        bins: product.bins.map((b) =>
                            b.binBarcode === product.productBin.binBarcode ? { ...b, quantity: data.binQuantity } : b
                        ),
                    });
                    setPutQuantity("");
                    setPutRequest({ error: false, message: "Items have been added to the bin." });
                })
                .catch((err) => {
                    setPutRequest({ error: err.value.error, message: err.value.errorMsg });
                    // console.error(err);
                });
        }
    };

    const handleAdjustDialog = () => {
        // console.log(product);
        if (adjustValue.reason === "") {
            setAdjustRequest({
                error: true,
                message: "Please include a reason for adjustment.",
            });
        } else {
            const data = JSON.stringify({
                ProductBinId: product.productBinId,
                QuantityFrom: product.productBin.quantity,
                QuantityTo: parseInt(adjustValue.total),
                UnaccountedReasonId: adjustValue.reason,
            });

            fetch(settings.api().product_bins + `/${product.productBinId}/adjust`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: data,
            })
                .then(async (res) => {
                    // console.log(res);
                    if (res.status === 200) {
                        return res.json();
                    } else {
                        throw await res.json();
                    }
                })
                .then((data) => {
                    // console.log(data);
                    setProduct({
                        ...product,
                        productBin: { ...product.productBin, quantity: data.quantity },
                        bins: product.bins.map((b) =>
                            b.binBarcode === product.productBin.binBarcode ? { ...b, quantity: data.quantity } : b
                        ),
                        totalInventory: data.totalQuantity,
                    });
                    setAdjustValue({
                        total: 0,
                        reason: "",
                    });
                    setAdjustRequest({
                        error: false,
                        message: "Bin quantity has been adjusted.",
                    });
                })
                .catch((err) => {
                    setAdjustRequest({ error: err.value.error, message: err.value.errorMsg });
                    console.error(err);
                });
        }
    };

    const handleScan = (e) => {
        const { value } = e.target;
        setBarcode(value);
        if (value.length === 12 && value[0] === "B" && value[1] === "C") {
            setLoading(true);
            fetch(settings.api().stocker + `?barcode=${value}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    // console.log(res);
                    setLoading(false);
                    if (res.status === 200) {
                        setErrorMessage("");
                        // props.history.push({
                        //     pathname: "/stocker",
                        //     search:"?binbarcode="
                        // });

                        return res.json();
                    } else if (res.status === 401) {
                        localStorage.clear();
                        props.history.push("/");
                    } else {
                        setProduct({});
                        setErrorMessage({
                            icon: "ErrorIcon",
                            message: "Invalid Product Bin Scanned. Please try again.",
                        });
                        const error = new Error(res.error);
                        throw error;
                    }
                })
                .then((data) => {
                    // console.log(data);
                    handleMultipleProductInBin(data, value);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };
    const handleFlagForPrinting = () => {
        const token = localStorage.getItem("warehouse-token");
        fetch(settings.api().stocker + `/${product.productBin.productBinId}/attributes?flagPrint=true`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: "POST",
        })
            .then((res) => {
                if (res.status === 200) {
                    setProduct({
                        ...product,
                        flaggedForPrinting: true,
                    });
                } else if (res.status === 401) {
                    localStorage.clear();
                    props.history.push("/");
                } else {
                    const error = new Error(res.error);
                    throw error;
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const handleMultipleProductInBin = (data, value) => {
        // console.log(data);
        if (data.length > 1) {
            setBin(data);
            setSelectProductDialog(true);
            setErrorMessage({ icon: "InfoOutlinedIcon", message: "There are more than one product in this bin." });
        } else if (data.length === 1) {
            setBin(data);
            setProduct({ ...data[0], productBin: data[0].bins.find((b) => b.binBarcode === value) });
            setErrorMessage({ icon: "InfoOutlinedIcon", message: "There is one product only in this bin." });
            setBarcode("");
        } else {
            setBin([]);
            setProduct({});
            setErrorMessage({ icon: "ErrorIcon", message: "There are zero products in this bin." });
            setBarcode("");
        }
    };
    const handleRowClick = (e, obj) => {
        // console.log(obj.bins, barcode);
        setProduct({ ...obj, productBin: obj.bins.find((b) => b.binBarcode === barcode) });
        setSelectProductDialog(false);
        setBarcode("");
    };

    useEffect(() => {
        let inputScan = inputRef.current;
        // console.log(!openTakeDialog, !openPutDialog, !openAdjustDialog, !changeProductDialog, !unlinkProductDialog);
        if (!openTakeDialog && !openPutDialog && !openAdjustDialog && !changeProductDialog && !unlinkProductDialog) {
            inputScan.focus();
        } else {
            setDisableBarcodeScan(true);
        }

        return () => {
            inputScan = null;
        };
    }, [openTakeDialog, openPutDialog, openAdjustDialog, changeProductDialog, unlinkProductDialog]);

    const handleUnlinkFromBin = () => {
        fetch(settings.api().product_bins + `/${product.productBinId}/unlink`, {
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => {
                console.log(res);
                if (res.status === 200) {
                    // window.location.reload();
                    history.go(0);
                }
            })
            .catch((error) => console.error(error));
    };

    const handleMoveBinScanned = (value) => {
        setMoveScannedBin({ ...moveScannedBin, binBarcode: value });
        if (value.length === 12 && value[0] === "B" && value[1] === "C") {
            getProductBinData(value);
        }
    };

    const getProductBinData = (value) => {
        fetch(settings.api().barcodes + `/${value}/bins`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                console.log(res);
                return res.json();
            })
            .then((data) => {
                console.log(data);
                if (data.error) {
                    setMoveScannedBin({ error: data.error, errorMsg: data.errorMsg });
                } else {
                    setMoveScannedBin({ error: data.error, errorMsg: "", binId: data.binId });
                }
            })
            .catch((err) => console.error(err));
    };

    const handleMoveProductToNewBin = () => {};

    const getUnaccountedReasons = () => {
        fetch(settings.api().unaccounted_reasons, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                // console.log(res);
                return res.json();
            })
            .then((data) => {
                // console.log(data);
                setUnaccountedReasons(data);
            })
            .catch((err) => console.error(err));
    };

    // console.log(product.productBin, product?.productBin?.quantity);
    return (
        <div>
            <div>
                <TextField
                    id="barcodeField"
                    // inputRef={(input) => input && input.focus()}
                    inputRef={inputRef}
                    disabled={disableBarcodeScan}
                    autoFocus
                    fullWidth
                    className={classes.barcodeScanner}
                    variant="outlined"
                    value={barcode}
                    color="primary"
                    onChange={handleScan}
                    placeholder="Scan Bin Barcode"
                    inputProps={{
                        maxLength: 12,
                        style: {
                            textAlign: "center",
                        },
                        // onBlur: () => {
                        //     if (
                        //         !openTakeDialog &&
                        //         !openPutDialog &&
                        //         !openAdjustDialog &&
                        //         !changeProductDialog &&
                        //         !unlinkProductDialog
                        //     ) {
                        //         const inputScan = inputRef.current;
                        //         setTimeout(() => {
                        //             inputScan.focus();
                        //         }, 1000);
                        //     }
                        // },
                    }}
                    onBlur={() => {
                        if (
                            !openTakeDialog &&
                            !openPutDialog &&
                            !openAdjustDialog &&
                            !changeProductDialog &&
                            !unlinkProductDialog
                        ) {
                            const inputScan = inputRef.current;
                            setTimeout(() => {
                                inputScan.focus();
                            }, 1000);
                        }
                    }}
                />
                {loading && (
                    <ListItem>
                        <div style={{ margin: "auto" }}>
                            <CircularProgress />
                        </div>
                    </ListItem>
                )}
                {errorMessage.message !== "" && (
                    <Alert
                        icon={
                            errorMessage.icon === "InfoOutlinedIcon" ? (
                                <InfoOutlinedIcon fontSize="inherit" />
                            ) : (
                                <ErrorIcon fontSize="inherit" />
                            )
                        }
                        severity={errorMessage.icon === "InfoOutlinedIcon" ? "info" : "warning"}
                        fullwidth="true"
                        style={{ marginBottom: theme.spacing(2) }}
                    >
                        {errorMessage.message}
                    </Alert>
                )}
            </div>

            <Grid container spacing={3} style={{ marginBottom: theme.spacing(2) }}>
                <Grid item xs={6} md={3}>
                    <Card align="center">
                        <CardContent style={{ padding: theme.spacing(1, 0) }}>
                            <span>Bin Inventory</span>
                            <h3 className={classes.cardHeader}>
                                {product.productBinId ? formatNumber(product.productBin.quantity) : 0}
                            </h3>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Card align="center">
                        <CardContent style={{ padding: theme.spacing(1, 0) }}>
                            <span>Total Inventory</span>
                            <h3 className={classes.cardHeader}>
                                {product.totalInventory ? formatNumber(product.totalInventory) : 0}
                            </h3>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Card align="center">
                        <CardContent style={{ padding: theme.spacing(1, 0) }}>
                            <span>Floating Inventory</span>
                            <h3 className={classes.cardHeader}>
                                {product.totalFloating ? formatNumber(product.totalFloating) : 0}
                            </h3>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Card align="center">
                        <CardContent style={{ padding: theme.spacing(1, 0) }}>
                            <span>Needed on Deck</span>
                            <h3 className={classes.cardHeader}>
                                {product.totalProductsOnDeck ? formatNumber(product.totalProductsOnDeck) : 0}
                            </h3>
                        </CardContent>
                    </Card>
                </Grid>
                {/* <Grid item xs={6} md={3}>
                    <Card align="center">
                        <CardContent style={{ padding: theme.spacing(1, 0) }}>
                            <span>Total in Orders</span>
                            <h3 className={classes.cardHeader}>
                                {product.totalProductsOrdered ? formatNumber(product.totalProductsOrdered) : 0}
                            </h3>
                        </CardContent>
                    </Card>
                </Grid> */}
            </Grid>
            <Grid container spacing={2} className={classes.container}>
                <Grid item xs={12} sm={12} md={"auto"} align="center">
                    <Card
                        style={{
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {!product.productBin ? (
                            <img
                                className={classes.image}
                                src={product.thumbUrl ? product.thumbUrl : "/images/no-image.jpg"}
                                alt="product"
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "/images/no-image.jpg";
                                }}
                            />
                        ) : (
                            <Link to={`/crm/products/${product.variationId}`}>
                                <img
                                    className={classes.image}
                                    src={product.thumbUrl ? product.thumbUrl : "/images/no-image.jpg"}
                                    alt="product"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = "/images/no-image.jpg";
                                    }}
                                />
                            </Link>
                        )}
                    </Card>
                </Grid>
                <Grid item xs={12} md>
                    <Card className={classes.info}>
                        <Grid container justify="space-between" spacing={1}>
                            <Grid item xs={8}>
                                <div>
                                    <Typography variant="body1" display="inline" color="textSecondary">
                                        Product{" "}
                                    </Typography>
                                    <Typography variant="h6" display="inline">
                                        {product.productName ? product.productName : "Product Name"}
                                    </Typography>
                                </div>
                                {!product.productBin ? (
                                    <Typography variant="h5" gutterBottom>
                                        {product.variationName ? product.variationName : "Variation"}
                                    </Typography>
                                ) : (
                                    <Link to={`/crm/products/${product.variationId}`} style={{ color: "black" }}>
                                        <Typography variant="h5" gutterBottom>
                                            {product.variationName ? product.variationName : "Variation"}
                                        </Typography>
                                    </Link>
                                )}

                                <div>
                                    <Typography variant="body1" display="inline" color="textSecondary">
                                        SKU{" "}
                                    </Typography>
                                    <Typography display="inline" variant="body1">
                                        {product.productSku && product.productSku}
                                    </Typography>
                                </div>

                                <div>
                                    <Typography variant="body1" display="inline" color="textSecondary">
                                        Var. ID{" "}
                                    </Typography>
                                    <Typography display="inline" variant="body1">
                                        {product.variationId && product.variationId}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={4} align="right">
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    style={{ marginTop: theme.spacing(1) }}
                                    fullWidth
                                    disabled={!product.productBin || product?.productBin?.quantity !== 0}
                                    onClick={() => {
                                        setUnlinkProductDialog(true);
                                    }}
                                >
                                    Unlink from Bin
                                </Button>
                                <Button
                                    disabled={!product.productBin}
                                    variant={product.flaggedForPrinting ? "contained" : "outlined"}
                                    color="secondary"
                                    style={{ marginTop: theme.spacing(3) }}
                                    fullWidth
                                    onClick={handleFlagForPrinting}
                                >
                                    {product.flaggedForPrinting ? "Reprint Requested" : "Reprint"}
                                </Button>
                            </Grid>
                        </Grid>

                        <div style={{ margin: 0, padding: 0 }}>
                            <Typography variant="body1" display="inline">
                                Location{" "}
                            </Typography>
                            <Typography display="inline" variant="h5">
                                {product.productBin && product.productBin.location}
                            </Typography>
                        </div>

                        <Grid container justify="space-between" style={{ margin: "0 0 16px", padding: 0 }}>
                            <Grid item xs style={{ margin: 0, padding: 0 }}>
                                <Typography variant="body1" display="inline">
                                    Level{" "}
                                </Typography>
                                <Typography display="inline" variant="h5">
                                    {product.productBin && product.productBin.levelName}
                                </Typography>
                            </Grid>
                            <Grid item xs style={{ margin: 0, padding: 0 }} align="right">
                                <Typography variant="body1" display="inline">
                                    Unit Var.{" "}
                                </Typography>
                                <Typography display="inline" variant="h5">
                                    {product.itemsInVariation && product.itemsInVariation}
                                </Typography>
                            </Grid>
                            {/* <Grid item xs style={{ margin: 0, padding: 0 }}>
                                <Typography variant="body1" display="inline">
                                    Rack{" "}
                                </Typography>
                                <Typography display="inline" variant="h5">
                                    {product.productBin && product.productBin.rack}
                                </Typography>
                            </Grid>
                            <Grid item xs style={{ margin: 0, padding: 0 }}>
                                <Typography variant="body1" display="inline">
                                    Shelf{" "}
                                </Typography>
                                <Typography display="inline" variant="h5">
                                    {product.productBin && product.productBin.shelf}
                                </Typography>
                            </Grid>
                            <Grid item xs style={{ margin: 0, padding: 0 }}>
                                <Typography variant="body1" display="inline">
                                    Bin{" "}
                                </Typography>
                                <Typography display="inline" variant="h5">
                                    {product.productBin && product.productBin.bin}
                                </Typography>
                            </Grid> */}
                        </Grid>
                        <Grid container justify="space-between">
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <Button
                                            disabled={!product.productBin}
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            onClick={() => setOpenTakeDialog(true)}
                                        >
                                            Take
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Button
                                            disabled={!product.productBin}
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            onClick={() => setOpenPutDialog(true)}
                                        >
                                            Put
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Button
                                            disabled={!product.productBin}
                                            variant="contained"
                                            color="secondary"
                                            fullWidth
                                            onClick={() => setOpenAdjustDialog(true)}
                                        >
                                            Adjust
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            <div style={{ margin: "20px 0 0" }}>
                <Typography variant="overline">Other Locations</Typography>
                {!product.bins ? (
                    <Card>
                        <CardContent style={{ textAlign: "center" }}>No Product Bin has been scanned.</CardContent>
                    </Card>
                ) : (
                    <TableContainer component={Paper}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Location</StyledTableCell>
                                    <StyledTableCell align="center">Level</StyledTableCell>
                                    {/* <StyledTableCell align="center">Rack</StyledTableCell>
                                    <StyledTableCell align="center">Shelf</StyledTableCell>
                                    <StyledTableCell align="center">Bin</StyledTableCell> */}
                                    <StyledTableCell align="center">Bin Inventory</StyledTableCell>
                                    <StyledTableCell align="center">Time to Get (min)</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {product.bins.map((b, i) => (
                                    <TableRow
                                        key={i}
                                        className={
                                            product?.productBin?.binBarcode === b.binBarcode
                                                ? classes.onItem
                                                : undefined
                                            // barcode === b.binBarcode ? classes.onItem : undefined
                                        }
                                    >
                                        <TableCell>{b.location}</TableCell>
                                        <TableCell align="center">{b.levelName}</TableCell>
                                        {/* <TableCell align="center">{b.rack}</TableCell>
                                        <TableCell align="center">{b.shelf}</TableCell>
                                        <TableCell align="center">{b.bin}</TableCell> */}
                                        <TableCell align="center">{b.quantity}</TableCell>
                                        <TableCell align="center">{b.timeToGet}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </div>
            <div style={{ margin: "20px 0 0" }}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "15px" }}>
                    <Typography variant="overline">Other Variations</Typography>
                    <Button
                        variant="contained"
                        classes={{ root: classes.root }}
                        disabled={!product.productBin}
                        onClick={handleChangeProduct}
                    >
                        Move
                    </Button>
                </div>

                {!product.otherVariations ? (
                    <Card>
                        <CardContent style={{ textAlign: "center" }}>No Product Bin has been scanned.</CardContent>
                    </Card>
                ) : (
                    <TableContainer component={Paper}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Location</StyledTableCell>
                                    <StyledTableCell>Variation Name</StyledTableCell>
                                    <StyledTableCell align="center">Bin Inventory</StyledTableCell>
                                    <StyledTableCell align="center">Units in Var.</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {product.otherVariations.map((b, i) => (
                                    <TableRow
                                        key={i}
                                        className={
                                            product?.productBin?.binBarcode === b.binBarcode
                                                ? classes.onItem
                                                : undefined
                                            // barcode === b.binBarcode ? classes.onItem : undefined
                                        }
                                    >
                                        <TableCell>{b.location}</TableCell>
                                        <TableCell>{b.variationName}</TableCell>
                                        <TableCell align="center">{b.quantity}</TableCell>
                                        <TableCell align="center">{b.itemsInVariation}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </div>
            {bin.length > 0 && (
                <Dialog open={selectProductDialog}>
                    <DialogTitle>
                        <Grid container alignItems="center" justify="space-between">
                            <Grid item>Select Product</Grid>
                            <Grid item>
                                <IconButton aria-label="close" onClick={() => setSelectProductDialog(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <Divider />
                    {/* <DialogContent> */}
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2}>Product</TableCell>
                                    <TableCell>SKU</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bin.map((b, i) => (
                                    <TableRow hover key={i} onClick={(e) => handleRowClick(e, b)}>
                                        <TableCell width="20%">
                                            <img
                                                src={b.thumbUrl ? b.thumbUrl : "/images/no-image.jpg"}
                                                alt={b.productName}
                                                style={{ width: "100%" }}
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; // prevents looping
                                                    currentTarget.src = "/images/no-image.jpg";
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>{b.productName}</TableCell>
                                        <TableCell>{b.productSku}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* </DialogContent> */}
                    <DialogActions>
                        <Button
                            // onClick={handleTakeDialog}
                            color="primary"
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {product.variationId && (
                <Dialog
                    open={unlinkProductDialog}
                    onClose={() => {
                        setUnlinkProductDialog(false);
                        setDisableBarcodeScan(false);
                    }}
                    aria-labelledby="replace product in bin"
                    aria-describedby="replace product in bin"
                >
                    <DialogTitle id="alert-dialog-title">
                        <Typography variant="subtitle1">Unlink Product Variation from Bin</Typography>
                    </DialogTitle>
                    <DialogContent>
                        Are you sure you want to unlink <strong>{product.variationName}</strong> from{" "}
                        <strong>{product?.productBin?.location}</strong>?
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setUnlinkProductDialog(false);
                                setDisableBarcodeScan(false);
                            }}
                            color="primary"
                            variant="contained"
                        >
                            Cancel
                        </Button>
                        <Button onClick={handleUnlinkFromBin} color="secondary" variant="outlined">
                            UNLINK
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <Dialog
                open={openTakeDialog}
                // fullWidth max-width="xs"
            >
                <DialogTitle>
                    <Grid container alignItems="center" justify="space-between">
                        <Grid item>Taking From Bin</Grid>
                        <Grid item>
                            <IconButton
                                aria-label="close"
                                onClick={() => {
                                    setTakeQuantity(0);
                                    setTakeRequest({ error: null, message: "" });
                                    setOpenTakeDialog(false);

                                    setDisableBarcodeScan(false);
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    {takeRequest.error !== null && !takeRequest.error ? (
                        <Alert severity={"success"} style={{ marginTop: theme.spacing(2) }}>
                            {takeRequest.message}
                        </Alert>
                    ) : (
                        <>
                            <Typography style={{ marginBottom: "20px" }}>
                                Current Bin Quantity: {product?.productBin?.quantity}
                            </Typography>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Enter Quantity Taken"
                                fullWidth
                                variant="outlined"
                                type="number"
                                InputProps={{
                                    inputProps: { min: 0 },
                                }}
                                onFocus={(event) => {
                                    event.target.select();
                                }}
                                value={takeQuantity}
                                onChange={(e) => {
                                    const parsedInt = parseInt(e.target.value);
                                    setTakeQuantity(Number.isNaN(parsedInt) ? "" : parsedInt);
                                }}
                            />
                            {takeRequest.error !== null && (
                                <Alert
                                    severity={takeRequest.error ? "error" : "success"}
                                    style={{ marginTop: theme.spacing(2) }}
                                >
                                    {takeRequest.message}
                                </Alert>
                            )}
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setTakeQuantity(0);
                            setTakeRequest({ error: null, message: "" });
                            setOpenTakeDialog(false);

                            setDisableBarcodeScan(false);
                        }}
                        color="primary"
                        variant="outlined"
                    >
                        Close
                    </Button>
                    {(takeRequest.error === null || takeRequest.error) && (
                        <Button onClick={handleTakeDialog} color="primary" variant="contained">
                            Confirm
                        </Button>
                    )}
                </DialogActions>
            </Dialog>

            <Dialog open={openPutDialog}>
                <DialogTitle>
                    <Grid container alignItems="center" justify="space-between">
                        <Grid item>Adding To Bin</Grid>
                        <Grid item>
                            <IconButton
                                aria-label="close"
                                onClick={() => {
                                    setPutQuantity("");
                                    setPutRequest({ error: null, message: "" });
                                    setOpenPutDialog(false);

                                    setDisableBarcodeScan(false);
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent dividers>
                    {putRequest.error !== null && !putRequest.error ? (
                        <Alert
                            severity={putRequest.error ? "error" : "success"}
                            style={{ marginTop: theme.spacing(2) }}
                        >
                            {putRequest.message}
                        </Alert>
                    ) : (
                        <>
                            <Typography style={{ marginBottom: "20px" }}>
                                Bin Quantity: {product?.productBin?.quantity}
                            </Typography>
                            <Typography style={{ marginBottom: "20px" }}>
                                Floating Inventory: {product?.totalFloating}
                            </Typography>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="takenQuantity"
                                label="Enter Quantity Added"
                                fullWidth
                                variant="outlined"
                                type="number"
                                InputProps={{
                                    inputProps: { min: 0 },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setPutQuantity("")}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onFocus={(event) => {
                                    event.target.select();
                                }}
                                value={putQuantity}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    const parsedInt = parseInt(value);
                                    setPutQuantity(Number.isNaN(parsedInt) ? "" : parsedInt);
                                }}
                            />
                            {putRequest.error !== null && (
                                <Alert
                                    severity={putRequest.error ? "error" : "success"}
                                    style={{ marginTop: theme.spacing(2) }}
                                >
                                    {putRequest.message}
                                </Alert>
                            )}
                            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                <NumberPad
                                    onNumberClick={(v) => {
                                        const tmp = `${putQuantity}${v}`;
                                        setPutQuantity(tmp);
                                    }}
                                />
                            </div> */}
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setPutQuantity("");
                            setPutRequest({ error: null, message: "" });
                            setOpenPutDialog(false);

                            setDisableBarcodeScan(false);
                        }}
                        color="primary"
                        variant="outlined"
                    >
                        Close
                    </Button>
                    {(putRequest.error === null || putRequest.error) && (
                        <Button onClick={handlePutDialog} color="primary" variant="contained">
                            Confirm
                        </Button>
                    )}
                </DialogActions>
            </Dialog>

            <Dialog open={openAdjustDialog}>
                <DialogTitle>
                    <Grid container alignItems="center" justify="space-between">
                        <Grid item>Adjust Quantity</Grid>
                        <Grid item>
                            <IconButton
                                aria-label="close"
                                onClick={() => {
                                    setAdjustValue({ total: 0, reason: "" });
                                    setAdjustRequest({ error: null, message: "" });
                                    setOpenAdjustDialog(false);

                                    setDisableBarcodeScan(false);
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent dividers>
                    {adjustRequest.error !== null && !adjustRequest.error ? (
                        <Alert severity={"success"} style={{ marginTop: theme.spacing(2) }}>
                            {adjustRequest.message}
                        </Alert>
                    ) : (
                        <>
                            <Grid container alignItems="center">
                                <Grid item xs={"auto"}>
                                    <Typography display="inline">
                                        From <span style={{ fontSize: "1.5rem" }}>{product?.productBin?.quantity}</span>{" "}
                                        to
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} />
                                <Grid item xs>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="takenQuantity"
                                        label="Adjust to "
                                        variant="outlined"
                                        type="number"
                                        fullWidth
                                        InputProps={{
                                            inputProps: { min: 0 },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setAdjustValue({ ...adjustValue, total: 0 })}
                                                    >
                                                        <ClearIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onFocus={(event) => {
                                            event.target.select();
                                        }}
                                        value={adjustValue.total}
                                        onChange={(e) => {
                                            const parsedInt = parseInt(e.target.value);
                                            if (e.target.value === "" || parsedInt) {
                                                setAdjustValue({
                                                    ...adjustValue,
                                                    total: Number.isNaN(parsedInt) ? "" : parsedInt,
                                                });
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <FormControl variant="outlined" fullWidth style={{ marginTop: theme.spacing(2) }}>
                                <InputLabel id="adjust_reason">Reason:</InputLabel>
                                <Select
                                    labelId="adjust_reason"
                                    value={adjustValue.reason}
                                    label="Reason"
                                    onChange={(e) =>
                                        setAdjustValue({
                                            ...adjustValue,
                                            reason: e.target.value,
                                        })
                                    }
                                    // defaultValue={0}
                                >
                                    {unaccountedReasons.length > 0 &&
                                        unaccountedReasons.map((r) => (
                                            <MenuItem key={r.id} value={r.id}>
                                                {r.reason}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                <NumberPad
                                    onNumberClick={(v) => {
                                        const tmp = `${adjustValue.total}${v}`;
                                        setAdjustValue({
                                            ...adjustValue,
                                            total: tmp,
                                        });
                                    }}
                                />
                            </div> */}
                            {adjustRequest.error !== null && (
                                <Alert
                                    severity={adjustRequest.error ? "error" : undefined}
                                    style={{ marginTop: theme.spacing(2) }}
                                >
                                    {adjustRequest.message}
                                </Alert>
                            )}
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpenAdjustDialog(false);
                            setAdjustValue({ total: 0, reason: "" });
                            setAdjustRequest({ error: null, message: "" });

                            setDisableBarcodeScan(false);
                        }}
                        color="primary"
                        variant="outlined"
                    >
                        Close
                    </Button>
                    {(adjustRequest.error === null || adjustRequest.error) && (
                        <Button
                            onClick={handleAdjustDialog}
                            color="secondary"
                            variant="contained"
                            disabled={adjustValue.reason === 0}
                        >
                            Adjust
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Dialog open={changeProductDialog}>
                <DialogTitle>Move Product to New Bin</DialogTitle>
                <DialogContent dividers>
                    <Typography style={{ marginBottom: "10px" }}>
                        Move <strong>{product.variationName}</strong> to new bin:
                    </Typography>
                    <TextField
                        id="barcodeField"
                        autoFocus
                        variant="outlined"
                        value={moveScannedBin.binBarcode}
                        onChange={(e) => handleMoveBinScanned(e.target.value)}
                        fullWidth
                        placeholder="Scan Bin Barcore"
                        inputProps={{
                            maxLength: 12,
                            style: {
                                textAlign: "center",
                                backgroundColor: "white",
                            },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="clear textfield"
                                        onClick={() => setMoveScannedBin({ ...moveScannedBin, binBarcode: "" })}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    {moveScannedBin.error && (
                        <Alert severity="error" style={{ marginTop: "20px" }}>
                            {moveScannedBin.errorMsg}
                        </Alert>
                    )}
                    {/* {linkError.error && (
                        <Alert severity="error" style={{ marginTop: "20px" }}>
                            {linkError.errorMsg}
                        </Alert>
                    )} */}
                </DialogContent>
                <DialogActions style={{ justify: "space-between" }}>
                    <Button
                        onClick={() => {
                            setChangeProductDialog(false);
                            setMoveScannedBin({ ...moveScannedBin, binBarcode: "", error: false, errorMsg: "" });

                            setDisableBarcodeScan(false);
                        }}
                        color="primary"
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button disabled={true} onClick={handleMoveProductToNewBin} color="primary" variant="contained">
                        Move
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

import React from "react";
import { makeStyles, Typography, Grid, Card } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundImage: "url('/images/error_bg.jpg')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    card: {
        position: "absolute",
        left: "50%",
        top: "50%",
        "&-webkit-transform": "translate(-50%, -50%)",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        width: "60vw",
        padding: theme.spacing(4),
    },
    logo: { width: "25%" },
    text: {
        margin: theme.spacing(8, 0, 4),
    },
    img: {
        width: "50%",
    },
}));

export default function Error404() {
    const classes = useStyles();

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            className={classes.root}
            style={{ minHeight: "100vh" }}
        >
            <Card className={classes.card}>
                <Grid container direction="column" alignItems="center">
                    <Grid item md>
                        <img
                            className={classes.logo}
                            src="/images/School-Start-logo-Color.jpg"
                            alt="School Start Logo"
                        />
                        <div className={classes.text}>
                            <Typography variant="h3">Error 404</Typography>
                            <Typography variant="h5">Page Not Found ¯\_(ツ)_/¯</Typography>
                            <Typography variant="h6" className={classes.subtitleText}>
                                Did you <a href="/">Sign in</a>?{/* Or return to <a href="/">Home</a>. */}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item md>
                        <img className={classes.img} src="/images/shrug.png" alt="Error 404" />
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    );
}

import React, { useEffect, useState } from "react";

import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

export default function CopyRight() {
    const [activeDatabase, setActiveDatabase] = useState("");

    useEffect(() => {
        const tmp = localStorage.getItem("user");
        if (tmp !== null) {
            setActiveDatabase(JSON.parse(tmp).activeDatabase);
        }
    }, []);
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            {new Date().getFullYear()}{" "}
            <Link target="_blank" color="inherit" href="/">
                School Start
            </Link>
            <br />
            {"Created By "}
            <Link target="_blank" color="inherit" href="https://www.saphera.com/">
                Saphera Software
            </Link>
            <br />
            {
                activeDatabase !== "" &&
                    // <Typography variant="body2" color="textSecondary" align="center">
                    `[DB: ${activeDatabase}]`
                // </Typography>
            }
        </Typography>
    );
}

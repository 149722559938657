import React, { useEffect, useRef, useState } from "react";
import { Route, Switch } from "react-router-dom";
import getAllowedRoutes from "../helpers/getAllowedRoutes";
import PrivateRoutesConfig from "../constants/PrivateRoutesConfig";

import Dashboard from "../components/Portal/Manager/Dashboard/Dashboard";
import Inner404 from "../components/Portal/404";
import withAuth from "../withAuth";

function PortalRouter({ basePath, ...rest }) {
    const isMounted = useRef(false);
    const [availableRoutes, setAvailableRoutes] = useState([]);

    useEffect(() => {
        isMounted.current = true;
        const allowedRoutes = getAllowedRoutes(PrivateRoutesConfig);

        let result = [];

        allowedRoutes.forEach(({ path, exact, component: Component, children }) => {
            result.push(<Route key={path} path={`${basePath}${path}`} exact={exact} component={Component} />);
            children &&
                children.forEach(
                    ({ path: childPath, exact: childExact, component: childComponent, children: grandchild }) => {
                        result.push(
                            <Route
                                key={childPath}
                                path={`${basePath}${path}${childPath}`}
                                exact={childExact}
                                component={childComponent}
                            />
                        );
                        grandchild &&
                            grandchild.forEach(
                                ({ path: grandchildPath, exact: grandchildExact, component: grandchildComponent }) => {
                                    result.push(
                                        <Route
                                            key={childPath}
                                            path={`${basePath}${path}${childPath}${grandchildPath}`}
                                            exact={grandchildExact}
                                            component={withAuth(grandchildComponent)}
                                        />
                                    );
                                }
                            );
                    }
                );
        });

        setAvailableRoutes([...result]);

        // const tmp = allowedRoutes.map(({ path, exact, component: Component, children }) => {
        //     // return <Route key={path} path={`${basePath}${path}`} exact={exact} component={Component} />;
        //     let child = [];
        //     // if (children) {
        //     child =
        //         children &&
        //         children.map(
        //             ({ path: childPath, exact: childExact, component: childComponent, children: grandchild }) => {
        //                 let grand = [];
        //                 // if (grandchild) {
        //                 grand =
        //                     grandchild &&
        //                     grandchild.map(
        //                         ({ path: grandchildPath, exact: grandchildExact, component: grandchildComponent }) => {
        //                             return (
        //                                 <Route
        //                                     key={childPath}
        //                                     path={`${basePath}${path}${childPath}${grandchildPath}`}
        //                                     exact={grandchildExact}
        //                                     component={grandchildComponent}
        //                                 />
        //                             );
        //                         }
        //                     );
        //                 // }
        //                 // console.log(...grand);

        //                 return grand ? (
        //                     [
        //                         <Route
        //                             key={childPath}
        //                             path={`${basePath}${path}${childPath}`}
        //                             exact={childExact}
        //                             component={childComponent}
        //                         />,
        //                         ...grand,
        //                     ]
        //                 ) : (
        //                     <Route
        //                         key={childPath}
        //                         path={`${basePath}${path}${childPath}`}
        //                         exact={childExact}
        //                         component={childComponent}
        //                     />
        //                 );
        //             }
        //         );
        //     // }
        //     child && console.log(...child);
        //     return child ? (
        //         [<Route key={path} path={`${basePath}${path}`} exact={exact} component={Component} />, ...child]
        //     ) : (
        //         <Route key={path} path={`${basePath}${path}`} exact={exact} component={Component} />
        //     );
        //     // console.log(child);
        // });
        // console.log(tmp);

        return () => {
            isMounted.current = false;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // function getPath(routes) {
    //     if (routes.length > 0) {
    //         routes.map((r, i) => {
    //             // let tmp = "";
    //             if (!r.children) {
    //                 return r.path;
    //             }
    //             // result.push(r.path);
    //             // result.push(r.path + getPath(r.children));
    //             return r.path + getPath(r.children);
    //         });
    //     }
    // }
    // console.log(result);

    // function getPath(routeObj) {
    //     let tmp;
    //     if (!routeObj.children) {
    //         tmp = routeObj.path;
    //         // console.log("if: ", tmp);
    //         // return tmp;
    //     } else {
    //         const nested = routeObj.children.map((c) => {
    //             // console.log(routeObj.path + c);
    //             tmp = routeObj.path + getPath(c);
    //             // console.log("else: ", tmp);

    //             return tmp;
    //             // return routeObj.path + getPath(c);
    //         });
    //         // console.log(nested);
    //     }
    //     console.log(tmp);
    //     // result
    //     //     .push
    //     // <Route key={routeObj.path} path={`${routeObj.path}`} exact={routeObj.exact} component={routeObj.child} />
    //     // ();
    // }

    return (
        <div>
            <Switch>
                <Route path={basePath} exact component={Dashboard} />
                {availableRoutes.map((r) => r)}
                <Route path="*" component={Inner404} />
            </Switch>
        </div>
    );
}

export default PortalRouter;

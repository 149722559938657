import React from "react"; // { useState }
import {
    // Card, CardContent, CardHeader, Grid,
    Typography,
    // makeStyles,
} from "@material-ui/core";
// import Chart from "react-apexcharts";
// import Title from "../../../../layout/Title";

// import Settings from "../../../../constants/setting";
// const useStyles = makeStyles((theme) => ({
//     cardHeader: {
//         fontSize: 28,
//         paddingLeft: 0,
//         fontWeight: 700,
//     },
//     charts: {
//         marginTop: theme.spacing(3),
//     },
// }));

// const chartData = {
//     series: [44, 55, 28, 49],
//     options: {
//         labels: ["Shift A", "Shift B", "Shift C", "Shift D"],
//         legend: {
//             position: "right",
//             width: 100,
//         },
//         theme: {
//             palette: "palette4",
//         },
//         responsive: [
//             {
//                 breakpoint: 480,
//                 options: {
//                     chart: {
//                         width: 200,
//                     },
//                     legend: {
//                         position: "bottom",
//                     },
//                 },
//             },
//         ],
//     },
// };

// const chartData2 = {
//     series: [44, 55, 41, 28, 37],
//     options: {
//         labels: ["Levi A.", "Historia R.", "Gabi B.", "Sasha B.", "Hannah D."],
//         legend: {
//             position: "bottom",
//         },
//         theme: {
//             monochrome: {
//                 enabled: true,
//                 color: "#54BC4C",
//                 shadeTo: "dark",
//             },
//         },
//         plotOptions: {
//             pie: {
//                 donut: {
//                     labels: {
//                         show: true,
//                         name: {
//                             // show: true,
//                         },
//                         value: {
//                             // show: true,
//                         },
//                         total: {
//                             show: true,
//                             label: "Total",
//                             formatter: function (w) {
//                                 return w.globals.seriesTotals.reduce((a, b) => {
//                                     return a + b;
//                                 }, 0);
//                             },
//                         },
//                     },
//                 },
//             },
//         },
//         responsive: [
//             {
//                 breakpoint: 480,
//                 options: {
//                     chart: {
//                         width: 200,
//                     },
//                     legend: {
//                         position: "top",
//                     },
//                 },
//             },
//         ],
//     },
// };

// const chartData3 = {
//     series: [15, 6, 13, 17, 5, 11, 16],
//     options: {
//         labels: ["Armin A.", "Erin Y.", "Misaki A.", "Erwin S", "Reiner B.", "Annie L.", "Christa R."],
//         legend: {
//             position: "bottom",
//         },
//         theme: {
//             palette: "palette4",
//         },
//         plotOptions: {
//             pie: {
//                 donut: {
//                     labels: {
//                         show: true,
//                         name: {
//                             show: true,
//                         },
//                         value: {
//                             show: true,
//                         },
//                         total: {
//                             show: true,
//                             label: "Total",
//                             formatter: function (w) {
//                                 return w.globals.seriesTotals.reduce((a, b) => {
//                                     return a + b;
//                                 }, 0);
//                             },
//                         },
//                     },
//                 },
//             },
//         },

//         responsive: [
//             {
//                 breakpoint: 480,
//                 options: {
//                     chart: {
//                         width: 200,
//                     },
//                     legend: {
//                         position: "top",
//                     },
//                 },
//             },
//         ],
//     },
// };
export default function Dashboard() {
    // const classes = useStyles();

    // const [chart1] = useState(chartData);
    // const [chart2] = useState(chartData2);
    // const [chart3] = useState(chartData3);

    // const formatNumber = (num) => {
    //     if (num) return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    //     else return num;
    // };

    // useEffect(() => {
    //     fetch(settings.api() + "saphera")
    //         .then((res) => res.json())
    //         .then((data) => console.log(data));
    // }, []);

    return (
        <div>
            {/* <h1>Warehouse Dashboard</h1> */}
            {/* <Title subtitle="Management" title="Dashboard" /> */}
            <div style={{ margin: "100px 0" }}>
                <div style={{ display: "flex", justifyContent: "center", margin: "60px 0" }}>
                    <img src="/images/School-Start-logo-Color.jpg" alt="SchoolStart" style={{ width: "50%" }} />
                </div>
                <Typography variant="h2" style={{ textAlign: "center" }}>
                    Welcome to the WCRM
                </Typography>{" "}
            </div>
            {/* <Grid container spacing={3}>
                <Grid item xs={3} md={3} lg={3}>
                    <Card align="center">
                        <CardContent>
                            <div>Orders Waiting</div>
                            <h3 className={classes.cardHeader}>72</h3>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                    <Card align="center">
                        <CardContent>
                            <div>Orders Completed</div>
                            <h3 className={classes.cardHeader}>634</h3>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                    <Card align="center">
                        <CardContent>
                            <div>Today's Top Puller</div>
                            <h3 className={classes.cardHeader}>Levi A.</h3>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                    <Card align="center">
                        <CardContent>
                            <div>Errors</div>
                            <h3 className={classes.cardHeader}>12</h3>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Card className={classes.charts}>
                <CardHeader title="Orders by Shift" />
                <div align="center">
                    <Chart options={chart1.options} series={chart1.series} type="pie" width="500" />
                </div>
            </Card>

            <Grid container className={classes.charts} spacing={3}>
                <Grid item md={6} sm={12}>
                    <Card>
                        <CardHeader title="Items by Puller (Current Shift)" />
                        <div align="center">
                            <Chart options={chart3.options} series={chart3.series} type="donut" width="400" />
                        </div>
                    </Card>
                </Grid>
                <Grid item md={6} sm={12}>
                    <Card>
                        <CardHeader title="Items by Puller (Previous Shift)" />
                        <div align="center">
                            <Chart options={chart2.options} series={chart2.series} type="donut" width="400" />
                        </div>
                    </Card>
                </Grid>
            </Grid> */}
        </div>
    );
}

import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        // margin: theme.spacing(0, 0, 3),
        margin: theme.spacing(0, 0, 0),
    },
    sub: {
        letterSpacing: "0.00735em",
        lineHeight: 1.5,
    },
    title: {
        marginBottom: theme.spacing(0),
        fontSize: "1.70rem",
        fontWeight: 500,
    },
    desc: {
        marginBottom: theme.spacing(3),
        fontWeight: 400,
    },
}));

export default function Title(props) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            {props.subtitle && (
                <Typography className={classes.sub} variant="overline">
                    {props.subtitle}
                </Typography>
            )}
            <Typography className={classes.title} component="h1" variant="h4" gutterBottom={props.description && true}>
                {props.title}
            </Typography>
            {props.description && (
                <Typography className={classes.desc} variant="subtitle2" gutterBottom>
                    {props.description}
                </Typography>
            )}
        </div>
    );
}

import React, { useState } from "react";
import { CircularProgress, Container, List, ListItem, makeStyles, TextField } from "@material-ui/core";
import Title from "../../../layout/Title";
import settings from "../../../constants/setting";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(4),
        display: "flex",
        justifyContent: "center",
        height: "80vh",
    },
    textField: {
        padding: "150px 0 0",
        margin: "auto",
    },
}));
export default function NewOrder(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [packageID, setPackageID] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handlePackageIdEntered = (e) => {
        setErrorMessage("");
        setPackageID(e.target.value);
        if (
            e.target.value.length === 12 &&
            e.target.value[0] === "P" &&
            (e.target.value[1] === "K" || e.target.value[1] === "D")
        ) {
            const packageId = e.target.value;
            // console.log(packageId);
            setPackageID(packageId);
            setLoading(true);
            setTimeout(() => {
                fetchPackage(packageId);
            }, 800);
        }
    };
    // PK0000072314
    const fetchPackage = (pId) => {
        // console.log(pId);
        const token = localStorage.getItem("warehouse-token");
        // console.log(settings.api().prepper_list + `?barcode=${pId}`);

        console.log(settings.api().pullers + `/${pId}`);
        fetch(settings.api().pullers + `/${pId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    localStorage.clear();
                    props.history.push("/");
                } else {
                    const error = new Error(res.error);
                    throw error;
                }
            })
            .then((data) => {
                setLoading(false);
                // console.log(data);

                // if (data.products.find((p) => !p.collected)) {
                props.history.push({
                    pathname: "/crm/packages/review_pulllist",
                    search: `?package=${pId}`,
                    state: data,
                });
                // } else {
                //     props.history.push({
                //         pathname: "/crm/packages/ready-to-pack",
                //         state: { orderPackage: data },
                //     });
                // }
            })
            .catch((err) => {
                setLoading(false);
                setPackageID("");
                setErrorMessage(`${pId} is a invalid package ID. Please scan another package.`);
                console.error(err);
            });
    };

    return (
        <div>
            <Title subtitle="Fulfillment" title="Collect a New Package" />
            <Container className={classes.root}>
                <List>
                    <ListItem>
                        <TextField
                            autoFocus
                            className={classes.textField}
                            variant="outlined"
                            placeholder="Scan New Package"
                            inputProps={{
                                maxLength: 12,
                                style: {
                                    backgroundColor: "white",
                                    fontSize: "24px",
                                    textAlign: "center",
                                    width: "300px",
                                },
                            }}
                            value={packageID}
                            onChange={handlePackageIdEntered}
                        />
                    </ListItem>
                    <ListItem>
                        <div style={{ margin: "auto", color: "red" }}>
                            {loading && <CircularProgress />}
                            {errorMessage !== "" && errorMessage}
                        </div>
                    </ListItem>
                </List>
            </Container>
        </div>
    );
}

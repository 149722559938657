import { Button } from "@material-ui/core";
import React, { useEffect } from "react";

const Palletizer = () => {
    var selected_device;
    var devices = [];

    function setup() {
        //Get the default device from the application as a first step. Discovery takes longer to complete.
        window.BrowserPrint.getDefaultDevice(
            "printer",
            function (device) {
                //Add device to list of devices and to html select element
                selected_device = device;
                devices.push(device);
                var html_select = document.getElementById("selected_device");
                var option = document.createElement("option");
                option.text = device.name;
                html_select.add(option);

                //Discover any other devices available to the application
                window.BrowserPrint.getLocalDevices(
                    function (device_list) {
                        for (var i = 0; i < device_list.length; i++) {
                            //Add device to list of devices and to html select element
                            var device = device_list[i];
                            if (!selected_device || device.uid !== selected_device.uid) {
                                devices.push(device);
                                var option = document.createElement("option");
                                option.text = device.name;
                                option.value = device.uid;
                                html_select.add(option);
                            }
                        }
                    },
                    function () {
                        alert("Error getting local devices");
                    },
                    "printer"
                );
            },
            function (error) {
                alert(error);
            }
        );
    }
    function getConfig() {
        window.BrowserPrint.getApplicationConfiguration(
            function (config) {
                alert(JSON.stringify(config));
            },
            function (error) {
                alert(JSON.stringify(new window.BrowserPrint.ApplicationConfiguration()));
            }
        );
    }
    function writeToSelectedPrinter(dataToWrite) {
        selected_device.send(dataToWrite, undefined, errorCallback);
    }
    var readCallback = function (readData) {
        if (readData === undefined || readData === null || readData === "") {
            alert("No Response from Device");
        } else {
            alert(readData);
        }
    };
    var errorCallback = function (errorMessage) {
        console.log(errorMessage);
        alert("Error: " + errorMessage);
    };
    function readFromSelectedPrinter() {
        selected_device.read(readCallback, errorCallback);
    }
    function getDeviceCallback(deviceList) {
        console.log(JSON.stringify(deviceList));
        return deviceList;
        // alert("Devices: \n" + JSON.stringify(deviceList, null, 4));
    }

    function sendImage(imageUrl) {
        let url = window.location.href.substring(0, window.location.href.lastIndexOf("/"));
        url = url + "/" + imageUrl;
        selected_device.convertAndSendFile(url, undefined, errorCallback);
    }
    function sendFile(fileUrl) {
        // let url = window.location.href.substring(0, window.location.href.lastIndexOf("/"));
        // url = url + "/" + fileUrl;
        let url = fileUrl;
        selected_device.sendFile(url, undefined, errorCallback);
    }
    function onDeviceSelected(selected) {
        for (var i = 0; i < devices.length; ++i) {
            if (selected.value === devices[i].uid) {
                selected_device = devices[i];
                return;
            }
        }
    }
    useEffect(() => {
        // setup();
        // window.BrowserPrint.getLocalDevices(getDeviceCallback, errorCallback);
    }, []);

    function print() {
        var zpl = document.getElementById("zpl").value;
        var ip_addr = document.getElementById("ip_addr").value;
        var output = document.getElementById("output");
        var url = "http://" + ip_addr + "/pstprnt";
        var method = "POST";
        var async = true;
        var request = new XMLHttpRequest();

        request.onload = function () {
            var status = request.status; // HTTP response status, e.g., 200 for "200 OK"
            var data = request.responseText; // Returned data, e.g., an HTML document.
            output.innerHTML = "Status: " + status + "<br>" + data;
        };

        // console.log(request);
        request.open(method, url, async);
        // request.setRequestHeader("Content-Length", zpl.length);

        // Actually sends the request to the server.
        request.send(zpl);
    }

    return (
        <div>
            Printer IP Address: <br />
            <input type="text" id="ip_addr" style={{ width: "500px" }} />
            <br />
            <br />
            <textarea id="zpl" style={{ width: "500px" }} rows="20"></textarea>
            <br />
            <br />
            <Button variant="outlined" onClick={() => print()}>
                Test Print
            </Button>
            {/* <Button variant="outlined" onClick={() => sendFile("/label.zpl")}>
                Test Print
            </Button> */}
        </div>
    );
};
export default Palletizer;

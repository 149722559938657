import React, { useState } from "react";
import moment from "moment";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography,
} from "@material-ui/core";
import MaiIcon from "@material-ui/icons/MailOutline";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: 0,
    },
    grid: {
        // margin: theme.spacing(3, 0),
    },
    // tableRow: {
    //     "&.Mui-selected, &.Mui-selected:hover": {
    //         backgroundColor: "#eceff1",
    //     },
    // },
    actions: {
        // flexDirection: "column",
        // alignItems: "flex-start",
        // "& > * + *": {
        //     marginLeft: 0,
        // },
    },
    buttonIcon: {
        marginRight: theme.spacing(1),
    },
    sendButton: {
        marginTop: theme.spacing(2),
    },
    mailIcon: {
        marginRight: theme.spacing(1),
    },
    table: {
        marginTop: theme.spacing(2),
    },
    cell: {
        padding: theme.spacing(1),
    },
}));

const options = ["Resend last invoice", "Send password reset", "Send verification"];

export default function Summary({ customer, className, ...props }) {
    const classes = useStyles();
    const [emailOption, setEmailOption] = useState(options[0]);

    const handleChange = (e) => {
        e.persist();
        setEmailOption(e.target.value);
    };

    const [openEdit, setOpenEdit] = useState(false);

    const handleEdit = () => {
        setOpenEdit(!openEdit);
    };

    return (
        <div>
            <Grid container className={classes.grid} justifyContent="space-between" spacing={3}>
                <Grid item xs={12} md={8}>
                    <Card>
                        <CardHeader title={<Typography variant="subtitle1">School Infomation</Typography>} />
                        <Divider />
                        <CardContent className={classes.content}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Email</TableCell>
                                        <TableCell>{props.school.email}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow} selected>
                                        <TableCell>Phone</TableCell>
                                        <TableCell>{props.school.phone}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Address</TableCell>
                                        <TableCell>
                                            {props.school.address1}, {props.school.address2}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow} selected>
                                        <TableCell>Region</TableCell>
                                        <TableCell>
                                            {props.school.city}, {props.school.province}, {props.school.postal}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </CardContent>
                        <CardActions className={classes.actions}>
                            <Button onClick={handleEdit}>
                                <EditIcon className={classes.buttonIcon} />
                                Edit
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardHeader title={<Typography variant="subtitle1">Send Emails</Typography>} />
                        <Divider />
                        <CardContent>
                            <TextField
                                fullWidth
                                name="option"
                                onChange={handleChange}
                                select
                                SelectProps={{ native: true }}
                                value={emailOption}
                                variant="outlined"
                            >
                                {options.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </TextField>
                            <Button className={classes.sendButton} variant="contained">
                                <MaiIcon className={classes.mailIcon} />
                                Send email
                            </Button>
                            <Table className={classes.table}>
                                <TableBody>
                                    {props.emails.map((email) => (
                                        <TableRow key={email.id}>
                                            <TableCell className={classes.cell}>
                                                {moment(email.created_at).format("DD/MM/YYYY | HH:MM")}
                                            </TableCell>
                                            <TableCell className={classes.cell}>{email.description}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

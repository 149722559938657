import MuiTableHead from "./MuiTableHead";
import MuiTableRow from "./MuiTableRow";
import MuiTableCell from "./MuiTableCell";

const overrides = {
    MuiTableHead,
    MuiTableRow,
    MuiTableCell,
};
export default overrides;

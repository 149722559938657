import React, { useState } from "react";
import { Box, makeStyles, Tab, Tabs } from "@material-ui/core";
import Title from "../../../layout/Title";
import Summary from "./Tabs/Summary";
import SchoolHistory from "./Tabs/History";

const useStyles = makeStyles((theme) => ({
    tabpanel: {
        margin: theme.spacing(2, 0),
    },
}));
function TabPanel(props) {
    const classes = useStyles();

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className={classes.tabpanel}>
                    {/* <Typography> */}
                    {children}
                    {/* </Typography> */}
                </Box>
            )}
        </div>
    );
}

export default function School() {
    const [value, setValue] = useState(0);

    const handleChange = (e, newValue) => {
        setValue(newValue);
    };
    return (
        <div>
            <Title subtitle="Schools" title={school.summary.name} />
            <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
            >
                <Tab label="Summary" />
                <Tab label="Invoices" />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Summary school={school.summary} emails={school.history.emails} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <SchoolHistory history={school.history} />
            </TabPanel>
        </div>
    );
}

const school = {
    summary: {
        name: "ABC School",
        email: "email@email.com",
        contact: 123456789,
        address1: "address 1",
        address2: "address 2",
        city: "That City",
        province: "AB",
        postal: "123456",
    },
    history: {
        emails: [
            {
                id: 87365,
                date: "some date",
                description: "Order Recipt",
            },
            {
                id: 87365,
                date: "some date",
                description: "Order confirmation",
            },
            {
                id: 87365,
                date: "some date",

                description: "Quote",
            },
        ],
        invoice: [
            {
                id: 534857843,
                date: "some date",
                total: 7834,
                status: "Waiting for Response",
            },
        ],
        purchases: [
            {
                id: 534857843,
                date: "some date",
                total: 7834,
                status: "Completed",
            },
        ],
    },
};

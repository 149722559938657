import { colors } from "@material-ui/core";

const white = "#FFFFFF";

export default {
    primary: {
        contrastText: white,
        // main: '#50b94d'
        main: "#54BC4C",
        // dark: "#3a8435",
    },
    secondary: {
        contrastText: white,
        main: "#f44336",
    },
    error: {
        main: "#f44336",
    },
    warning: {
        light: "#ffb74d",
        main: "#ff9800",
        dark: "#f57c00",
        contrastText: "rgba(0, 0, 0, 0.87)",
        // main: "#ff9800",
        // contrastText: white,
    },
    icon: "#50b94d",
    background: {
        default: "#F4F6F8",
    },
    divider: colors.grey[200],
};

import React from "react";
import { Grid, makeStyles, Typography, useTheme } from "@material-ui/core";
import { CreatePackageId } from "../../../helpers/createPackageId";
import DisplayDataCell from "../../Reuseables/DisplayDataCell";

const useStyles = makeStyles((theme) => ({
    orderDetails: {
        // padding: theme.spacing(1, 2, 2),
        margin: theme.spacing(1, 0),
    },
    // orderContent: {
    //     padding: theme.spacing(1, 2),
    // },
    card: {
        backgroundColor: "white",
        border: "1px solid #3a8435",
        borderRadius: "5px",
        padding: theme.spacing(1),
    },
    orderType: {
        padding: theme.spacing(0.25, 6),
        fontSize: "18px",
    },
}));

export default function OrderDetails({ orderPackage }) {
    const classes = useStyles();
    // const theme = useTheme();
    // console.log(orderPackage);
    return (
        // <Card
        //     style={{
        //         padding: theme.spacing(2),
        //         marginBottom: theme.spacing(2),
        //     }}
        // >
        <>
            {Object.keys(orderPackage).length !== 0 && (
                <Grid container justifycontent="space-evenly">
                    <Grid item xs={12}>
                        <DisplayDataCell
                            a="Package #"
                            b={`${CreatePackageId(orderPackage.packageType, orderPackage.packageId)}`}
                            i={1}
                        />
                        <DisplayDataCell a="Package Type" b={orderPackage.packageType === 1 ? "Kit" : "Detail"} i={2} />
                        <DisplayDataCell
                            a="Total Product"
                            b={orderPackage.products && orderPackage.products.length}
                            i={1}
                        />
                        <DisplayDataCell a="Order #" b={orderPackage.orderId} i={2} />
                        <DisplayDataCell
                            a="Order Type"
                            b={
                                <span
                                    className={classes.orderType}
                                    style={{
                                        color: `#${orderPackage.orderTypeForegroundColour}`,
                                        backgroundColor: `#${orderPackage.orderTypeBackgroundColour}`,
                                    }}
                                >
                                    {orderPackage.orderType}
                                </span>
                            }
                            i={1}
                        />
                        <DisplayDataCell a="Delivery Type" b={orderPackage.deliveryType} i={2} />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                        <DisplayDataCell
                            a="Shipping Address"
                            b={`${orderPackage.address1} ${orderPackage.address2 ? `, ${orderPackage.address2}` : ""}`}
                            i={1}
                        />
                        <DisplayDataCell a="Shipping City" b={`${orderPackage.city && orderPackage.city}`} i={2} />
                        <DisplayDataCell
                            a="Shipping Province"
                            b={`${orderPackage.provinceCode && orderPackage.provinceCode}`}
                            i={1}
                        />
                        <DisplayDataCell
                            a="Shipping Postal Code"
                            b={`${orderPackage.postal && orderPackage.postal}`}
                            i={2}
                        />
                      
                    </Grid> */}
                    {/* <Grid item xs={12} sm={6} md={4}>
                        <div style={{ marginBottom: theme.spacing(2) }}>
                            <Typography variant="h6" gutterBottom>
                                <strong>Package Info</strong>
                            </Typography>

                            <div>
                                <Typography variant="body1" color="textSecondary" display="inline">
                                    Package#{" "}
                                </Typography>
                                <Typography variant="body1" display="inline">
                                    {CreatePackageId(orderPackage.packageType, orderPackage.packageId)}
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="body1" color="textSecondary" display="inline">
                                    Type{" "}
                                </Typography>
                                <Typography variant="body1" display="inline">
                                    {orderPackage.packageType === 1 ? "Kit" : "Detail"}
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="body1" color="textSecondary" display="inline">
                                    Total Product{" "}
                                </Typography>
                                <Typography variant="body1" display="inline">
                                    {orderPackage.products && orderPackage.products.length}
                                </Typography>
                            </div>
                            <Typography variant="body1">1 of {orderPackage.packages.length} Packages</Typography>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <div>
                            <Typography variant="body1" color="textSecondary" gutterBottom>
                                <strong>Shipping Address</strong>
                            </Typography>

                            <Typography variant="body1">
                                {orderPackage.shippingAddress.address1}
                                {orderPackage.shippingAddress.address2
                                    ? `, ${orderPackage.shippingAddress.address2}`
                                    : ""}
                            </Typography>
                            <Typography variant="body1">
                                {orderPackage.shippingAddress.city ? `${orderPackage.shippingAddress.city}` : ""}
                                {orderPackage.shippingAddress.provinceCode
                                    ? `, ${orderPackage.shippingAddress.provinceCode}`
                                    : ""}
                                {orderPackage.shippingAddress.postal ? `, ${orderPackage.shippingAddress.postal}` : ""}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item md={1} />

                    <Grid item xs={12} md={3}>
                        <div>
                            <div>
                                <Typography variant="body1" color="textSecondary" display="inline">
                                    Order#{" "}
                                </Typography>
                                <Typography variant="body1" display="inline">
                                    {orderPackage.orderId}
                                </Typography>
                            </div>
                            <div>
                                <span
                                    className={classes.orderType}
                                    style={{
                                        color: `#${orderPackage.orderTypeForegroundColour}`,
                                        backgroundColor: `#${orderPackage.orderTypeBackgroundColour}`,
                                    }}
                                >
                                    {orderPackage.orderType}
                                </span>
                            </div>
                            <div style={{ marginBottom: theme.spacing(3) }}>
                                <Typography variant="body1" color="textSecondary" display="inline">
                                    Delivery{" "}
                                </Typography>
                                <Typography variant="body1" display="inline">
                                    {orderPackage.deliveryType}
                                </Typography>
                            </div>

                            <Typography variant="body1" color="textSecondary" gutterBottom>
                                <strong>Order Packages</strong>
                            </Typography>

                            {orderPackage.packages.map((p) => (
                                <Typography
                                    key={p.packageId}
                                    variant="body1"
                                    color={`${p.packageId !== orderPackage.packageId ? "textSecondary" : "initial"}`}
                                >
                                    {p.packageId === orderPackage.packageId ? (
                                        <strong>{CreatePackageId(p.packageType, p.packageId)} - Current</strong>
                                    ) : p.completed ? (
                                        <>{CreatePackageId(p.packageType, p.packageId)} - Completed</>
                                    ) : (
                                        <>{CreatePackageId(p.packageType, p.packageId)} - Not Started</>
                                    )}
                                </Typography>
                            ))}
                        </div>
                    </Grid> */}
                </Grid>
            )}
        </>
        // </Card>
    );
}

// <Card className={classes.orderDetails}>
//     <Tabs
//         indicatorColor="primary"
//         textColor="primary"
//         value={tabValue}
//         onChange={handleTabChange}
//         aria-label="Prepper Tabs"
//     >
//         <Tab label="Package Info" {...a11yProps(0)} />
//         <Tab label="Order Info" {...a11yProps(1)} />
//     </Tabs>
//     <Divider />
//     <TabPanel value={tabValue} index={0}>
//         {/* <Grid container justifycontent="space-between">
//             <Grid item xs={12} sm={6}>
//                 <Typography variant="subtitle1">Order# 9876543210</Typography>
//             </Grid>
//             <Grid item xs={12} sm={6} align={!matchesXS && "right"}>
//                 <div>
//                     <span className={classes.orderType}>Bulk</span>
//                 </div>
//             </Grid>
//         </Grid> */}
//         {/* <div className={classes.card}> */}

//         <Grid container>
//             <Grid item xs={12} sm={6} md={3}>
//                 <Typography variant="body1" gutterBottom>
//                     <strong>Package Details</strong>
//                 </Typography>
//                 <Typography variant="body1" display="inline">
//                     1 of 4 Packages
//                 </Typography>
//                 <div style={{ margin: 0 }}>
//                     <Typography variant="body1" color="textSecondary" display="inline">
//                         Package ID:{" "}
//                     </Typography>
//                     <Typography variant="body1" display="inline">
//                         12
//                     </Typography>
//                 </div>
//                 <div>
//                     <Typography variant="body1" color="textSecondary" display="inline">
//                         Package Type:{" "}
//                     </Typography>
//                     <Typography variant="body1" display="inline">
//                         Details
//                     </Typography>
//                 </div>
//             </Grid>
//             <Grid item xs={12} sm={6} md={3}>
//                 <Typography variant="body1" gutterBottom>
//                     <strong>Kit/Detail Summary</strong>
//                 </Typography>
//                 <Typography variant="body1">John Doe</Typography>
//                 <Typography variant="body1">Grade 1 Kit</Typography>
//                 <Typography variant="body1">That Elementary School</Typography>
//             </Grid>
//             {/* <Grid item xs={1} /> */}
//             {/* <Grid item xs={12} sm={6} md={3}>
//                 <Typography variant="body1" gutterBottom>
//                     <strong>Shipping Address</strong>
//                 </Typography>

//                 <Typography variant="body1">Jane Doe</Typography>
//                 <Typography variant="body1">123 Some Street</Typography>
//                 <Typography variant="body1">Somecity, ON, A1B 2C3</Typography>
//             </Grid> */}
//         </Grid>
//         {/* </div> */}
//     </TabPanel>
//     <TabPanel value={tabValue} index={1}>
//         <Grid container>
//             <Grid item xs={12} sm={6} md={3}>
//                 {/* <Typography variant="body1" gutterBottom>
//                     <strong>Order Details</strong>
//                 </Typography> */}
//                 <div style={{ margin: 0 }}>
//                     <Typography variant="body1" color="textSecondary" display="inline">
//                         Order ID:{" "}
//                     </Typography>
//                     <Typography variant="body1" display="inline">
//                         9876543210
//                     </Typography>
//                 </div>
//                 <div style={{ margin: 0 }}>
//                     <Typography variant="body1" color="textSecondary" display="inline">
//                         Order Type:{" "}
//                     </Typography>
//                     <Typography variant="body1" display="inline">
//                         Student
//                     </Typography>
//                 </div>
//                 <Typography variant="body1">3 Kits + 1 Details</Typography>
//                 <Typography variant="body1"></Typography>
//             </Grid>
//             <Grid item xs={12} sm={6} md={3}>
//                 <Typography variant="body1" gutterBottom>
//                     <strong>Packages</strong>
//                 </Typography>
//                 <Grid container>
//                     <Grid item xs={6}>
//                         <Typography variant="body1">PK000000076312</Typography>
//                     </Grid>
//                     <Grid item xs={6}>
//                         <Typography variant="body1">Started</Typography>
//                     </Grid>
//                 </Grid>
//                 <Grid container>
//                     <Grid item xs={6}>
//                         <Typography variant="body1">PK000000076313</Typography>
//                     </Grid>
//                     <Grid item xs={6}>
//                         <Typography variant="body1">-</Typography>
//                     </Grid>
//                 </Grid>
//                 <Grid container>
//                     <Grid item xs={6}>
//                         <Typography variant="body1">PK000000076314</Typography>
//                     </Grid>
//                     <Grid item xs={6}>
//                         <Typography variant="body1">Completed</Typography>
//                     </Grid>
//                 </Grid>
//             </Grid>
//             <Grid item xs={0} sm={0} md={3} />

//             <Grid item xs={12} sm={6} md={3}>
//                 <Typography variant="body1" gutterBottom>
//                     <strong>Shipping Address</strong>
//                 </Typography>
//                 <Typography variant="body1">Jane Doe</Typography>
//                 <Typography variant="body1">123 Some Street</Typography>
//                 <Typography variant="body1">Somecity, ON, A1B 2C3</Typography>
//             </Grid>
//         </Grid>
//     </TabPanel>
// </Card>

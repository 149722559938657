import React, { useEffect, useState } from "react";
import OrderDetails from "./OrderDetails";
import { useHistory } from "react-router-dom";
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Divider,
    List,
    ListItem,
    makeStyles,
    TextField,
    Typography,
    useTheme,
} from "@material-ui/core";
import Title from "../../../layout/Title";
import settings from "../../../constants/setting";

const useStyles = makeStyles((theme) => ({
    spacing: {
        margin: theme.spacing(2, 0),
    },
    textField: {
        margin: "auto",
    },
    btn: {
        margin: theme.spacing(2, "auto", 0),
        height: "50px",
        width: "330px",
        fontSize: "20px",
    },
}));

const ReadyToPack = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [orderPackage, setOrderPackage] = useState({});
    const [packerUserID, setPackerUserID] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [validUser, setValidUser] = useState({});

    useEffect(() => {
        const obj = history.location.state.orderPackage;
        setOrderPackage(obj);

        if (obj === null) {
        }
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUserID = (e) => {
        const { value } = e.target;
        setPackerUserID(value);

        if (value.length === 13) {
            setLoading(true);
            const token = localStorage.getItem("warehouse-token");
            // console.log(token);
            fetch(settings.api().fobs + `/${value}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    console.log(res);
                    if (res.ok) {
                        return res.json();
                    } else {
                        const error = new Error(res.error);
                        throw error;
                    }
                })
                .then((data) => {
                    console.log(data);
                    setErrorMessage("");
                    data.userId && setValidUser(data);
                })
                .catch((err) => {
                    setErrorMessage("User does not exist. Try again.");
                    console.error(err);
                });
            setLoading(false);

            setPackerUserID("");
        }
    };
    const handlePackedPacked = (e) => {
        setLoading(true);
        const token = localStorage.getItem("warehouse-token");
        // console.log(orderPackage);
        fetch(settings.api().packer, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({
                PackageId: orderPackage.packageId,
                PackageType: orderPackage.packageType,
                UserId: validUser.userId,
            }),
        })
            .then((res) => {
                // console.log(res);
                if (res.ok) {
                    history.push("/crm/sorting");
                } else {
                    const error = new Error(res.error);
                    throw error;
                }
            })
            .catch((err) => {
                console.error(err);
            });
        setLoading(false);
    };

    return (
        <div>
            <Title title="Ready to Pack" />
            <List>
                <ListItem>
                    <TextField
                        autoFocus
                        className={classes.textField}
                        variant="outlined"
                        placeholder="Scan Employee ID"
                        inputProps={{
                            maxLength: 13,
                            style: {
                                backgroundColor: "white",
                                fontSize: "24px",
                                textAlign: "center",
                                width: "300px",
                            },
                        }}
                        value={packerUserID}
                        onChange={(e) => handleUserID(e)}
                    />
                </ListItem>
                <ListItem>
                    <div style={{ margin: "auto", color: "red" }}>
                        {loading && <CircularProgress />}
                        {errorMessage !== "" && errorMessage}
                    </div>
                </ListItem>
                <Card className={classes.spacing}>
                    <div
                        style={{
                            padding: theme.spacing(3, "auto"),
                            textAlign: "center",
                        }}
                    >
                        <Typography variant="h3" color={orderPackage?.batchNumber ? "textPrimary" : "textSecondary"}>
                            {`${orderPackage.products ? orderPackage.products.length : ""}`}
                        </Typography>
                        <Typography variant="h6" color={"textSecondary"}>
                            Total Products
                        </Typography>
                    </div>
                    <Divider style={{ margin: theme.spacing(1, 0) }} />
                    <CardContent>
                        <OrderDetails orderPackage={orderPackage} />
                    </CardContent>
                </Card>
                <ListItem className={classes.spacing}>
                    {validUser.userId && (
                        <Button
                            className={classes.btn}
                            color="primary"
                            // fullWidth
                            variant="contained"
                            onClick={() => {
                                handlePackedPacked();
                            }}
                            // disabled={!validUser.userId}
                        >
                            Done
                        </Button>
                    )}
                </ListItem>
            </List>
        </div>
    );
};

export default ReadyToPack;

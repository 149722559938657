import { useState, useRef } from "react";

const useTimer = (props) => {
    const [seconds, setSeconds] = useState(0);

    const interval = useRef(null);

    const setSecondsHook = (sec) => {
        setSeconds(sec === 0 ? 0 : sec);
    };

    const startTimer = () => {
        interval.current = setInterval(() => {
            setSeconds((prev) => prev - 1);
        }, 1000);
    };

    const stopTimer = () => {
        clearInterval(interval.current);
    };

    return { seconds, startTimer, stopTimer, setSecondsHook };
};

export default useTimer;

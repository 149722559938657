import React, { useEffect, useRef, useState } from "react";
import {
    Button,
    Card,
    CardActionArea,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    TextField,
    Typography,
    useTheme,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import clsx from "clsx";
// import DoneIcon from "@material-ui/icons/Done";

const useStyles = makeStyles((theme) => ({
    itemCard: {
        minWidth: "140px",
        margin: theme.spacing(2),
    },
    media: {
        height: 140,
        display: "block",
        margin: "5px auto",
        padding: theme.spacing(2, 0, 0),
        // paddingTop: "56.25%", // 16:9,
    },
    imgContainer: {
        // border: "1px solid #DDDDDD",
        // width: "200px",
        // height: "200px",
        position: "relative",
    },
    // leftCorner: {
    //     float: "left",
    //     position: "absolute",
    //     left: "5px",
    //     top: "0px",
    //     width: "40px",
    //     height: "40px",
    //     zIndex: "1000",
    //     fontWeight: "bold",
    //     textAlign: "center",
    //     borderRadius: "5px",
    // },
    leftCorner: {
        float: "left",
        position: "relative",
        left: "5px",
        top: "0px",
        width: "40px",
        height: "40px",
        zIndex: "1000",
        // fontWeight: "bold",
        // textAlign: "center",
        borderRadius: "3px",
        background:
            "linear-gradient(to bottom right, transparent calc(50% - 2px),  white calc(50% - 2px), white 50%, transparent 50%)",
    },
    qPicked: {
        color: "white",
        left: "2px",
        float: "left",
        top: "0px",
        position: "absolute",
    },
    qNeeded: {
        color: "white",
        right: "5px",
        float: "right",
        bottom: "0px",
        position: "absolute",
    },

    qty1: {
        backgroundColor: "#ffb74d",
        color: "white",
    },
    qty2: {
        backgroundColor: "#4caf50",
        color: "white",
    },
    qty3: {
        backgroundColor: "#f44336",
        color: "#FFFFFF",
    },
    cardContent: {
        padding: theme.spacing(0, 1, 1),
    },
    collected: {
        opacity: 0.2,
    },
    missing: { opacity: 0.2 },
    dialog: {
        // heigth: "500px",
        width: "400px",
        margin: "auto",
    },
    missingBtn: {
        margin: 0,
        padding: 13,
    },
    missingBtn1: {
        // backgroundColor: "#ffa726",
        backgroundColor: "#eeeeee",
        color: theme.palette.text,
        "&:hover": {
            backgroundColor: "#bdbdbd",
            color: "white",
        },
    },
    missingBtn2: {
        //missing
        backgroundColor: "#f44336",
        color: "white",
        "&:hover": {
            backgroundColor: "#ba000d",
        },
    },
    missingBtn3: {
        //Collected
        backgroundColor: "#4caf50",
        color: "white",
        "&:hover": {
            backgroundColor: "#087f23",
        },
    },
    textRight: {
        textAlign: "right",
    },
    dialogReason: {
        margin: theme.spacing(1, 0),
    },
}));

export default function ListItem({ itemDetails, handleStatus, handleStatusMissing, disabled }) {
    const classes = useStyles();
    const mounted = useRef(false);
    const [item, setItem] = useState({
        ...itemDetails,
    });
    const theme = useTheme();
    const [collectedNotComplete, setCollectedNotComplete] = useState(0);
    const [missing, setMissing] = useState("");
    const [open, setOpen] = useState(false);
    const [
        showAlert,
        // , setShowAlert
    ] = useState(null);

    const [flagError, setFlagError] = useState(false);

    const handleOnClick = (e) => {
        let collected = 0;
        if (!item.collected) {
            collected = item.quantityNeeded;
        }
        handleStatus(item, !item.collected, collected);
    };

    const handleMissing = (e) => {
        e.preventDefault();

        // if (!(collectedNotComplete % 1 === 0)) {
        //     setShowAlert("Quantity entered must be a whole number.");
        // }
        // if (collectedNotComplete >= itemDetails.quantityNeeded) {
        //     setShowAlert("Quantity entered can not be equal to or greater than quantity needed.");
        // }
        // if (collectedNotComplete < 0) {
        //     setShowAlert("Quantity entered can not be less than zero(0).");
        // }
        // if (missing === "") {
        //     setShowAlert("A reason for the missing item must be included.");
        // }
        // if (
        //     collectedNotComplete % 1 === 0 &&
        //     collectedNotComplete < itemDetails.quantityNeeded &&
        //     collectedNotComplete >= 0 &&
        //     missing !== ""
        // ) {
        //     setShowAlert(null);
        //     handleStatusMissing(item, !item.error, missing, parseInt(collectedNotComplete));
        //     setOpen(false);
        // }
        handleStatusMissing(item, true, missing, parseInt(collectedNotComplete));
        setOpen(false);
    };

    useEffect(() => {
        setItem(itemDetails);
    }, [itemDetails]);

    useEffect(() => {
        mounted.current = true;
        if (collectedNotComplete > item.quantityNeeded) {
            mounted.current && setFlagError(true);
        } else {
            mounted.current && setFlagError(false);
        }
        return () => {
            mounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectedNotComplete]);

    return (
        <div>
            <Card className={classes.itemCard}>
                <CardActionArea disabled={disabled} onClick={(e) => handleOnClick(e, item)}>
                    <div className={classes.imgContainer}>
                        <div
                            className={clsx(
                                classes.leftCorner,
                                !item.collected && !item.error
                                    ? classes.qty1
                                    : item.collected
                                    ? classes.qty2
                                    : classes.qty3
                            )}
                        >
                            {/* <Typography variant="h4">
                                {item.collected ? (
                                    <DoneIcon fontSize="large" />
                                ) : (
                                    item.quantityNeeded - item.quantityPicked
                                )}
                            </Typography> */}
                            <Typography
                                variant={
                                    (item.quantityNeeded - item.quantityPicked).toString().length === 1
                                        ? "h6"
                                        : "subtitle1"
                                }
                                className={classes.qPicked}
                            >
                                {item.quantityPicked}
                            </Typography>
                            <Typography
                                variant={
                                    (item.quantityNeeded - item.quantityPicked).toString().length === 1
                                        ? "h6"
                                        : "subtitle1"
                                }
                                className={classes.qNeeded}
                            >
                                {item.quantityNeeded}
                            </Typography>
                        </div>
                        <img
                            className={clsx(
                                classes.media,
                                (item.collected || item.error) && (classes.collected, classes.missing)
                            )}
                            src={item.thmbUrl}
                            alt={item.productName}
                        />
                    </div>
                    <CardContent className={classes.cardContent}>
                        <Grid container style={{ margin: "0" }}>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1" color="textSecondary" display="inline">
                                    Qty:{" "}
                                </Typography>
                                <Typography variant="subtitle1" display="inline">
                                    {item.quantityNeeded}
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="subtitle1" color="textSecondary" className={classes.textRight}>
                                    {item.productSku}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Typography noWrap variant="subtitle2">
                            {item.productName}
                        </Typography>
                        <div>
                            <Typography variant="body2" color="textSecondary" display="inline">
                                Bin:{" "}
                            </Typography>
                            <Typography variant="body2" display="inline">
                                {/* {item.locations &&
                                    item.locations.map((l, i) =>
                                        i + 1 !== item.locations.length ? `${l.location}, ` : l.location
                                    )} */}
                                {item.locations && item.locations.map((l) => l.levelId === 1 && l.location)}
                            </Typography>
                        </div>
                    </CardContent>
                </CardActionArea>
                <Button
                    fullWidth
                    size="small"
                    variant="contained"
                    onClick={() => setOpen(true)}
                    disabled={disabled}
                    // classes={{ disabled: classes.missingBtn3 }}
                    className={clsx(
                        classes.missingBtn,
                        !item.collected && !item.error
                            ? classes.missingBtn1
                            : item.collected
                            ? classes.missingBtn3
                            : classes.missingBtn2
                    )}
                >
                    {!item.collected && !item.error ? "Flag" : item.collected ? "Collected" : "Missing"}
                </Button>
            </Card>
            <Dialog open={open} aria-labelledby="item status" aria-describedby="item status">
                <form onSubmit={handleMissing}>
                    <DialogTitle id="alert-dialog-title">Flag Item</DialogTitle>
                    {showAlert && (
                        <Alert severity="error" style={{ marginBottom: "15px" }}>
                            {showAlert}
                        </Alert>
                    )}
                    <DialogContent className={classes.dialog}>
                        <TextField
                            error={flagError}
                            id="outlined-basic"
                            type="number"
                            min="0"
                            fullWidth
                            label="Collected"
                            placeholder="Quantity collected"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            required
                            autoFocus
                            value={collectedNotComplete}
                            // onChange={(e) => setCollectedNotComplete(e.target.value)}
                            onChange={(e) => {
                                const regex = /^[0-9]+\d*$/;
                                if (e.target.value === "" || regex.test(e.target.value)) {
                                    setCollectedNotComplete(e.target.value);
                                }
                            }}
                            style={{ margin: theme.spacing(1, 0) }}
                        />
                        <TextField
                            id="outlined-basic"
                            type="text"
                            fullWidth
                            label="Reason"
                            placeholder="Reason for item missing"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={missing}
                            onChange={(e) => setMissing(e.target.value)}
                            style={{ margin: theme.spacing(1, 0) }}
                        />
                    </DialogContent>

                    <DialogActions style={{ marginTop: "20px" }}>
                        <Button
                            onClick={() => {
                                setOpen(false);
                            }}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            disabled={missing === null || collectedNotComplete === null}
                            color="primary"
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}

import React, { useEffect, useState } from "react";
import Title from "../../../layout/Title";
import {
    CircularProgress,
    Divider,
    IconButton,
    InputBase,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    makeStyles,
} from "@material-ui/core";

import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router";
import VisibilityIcon from "@material-ui/icons/Visibility";
import settings from "../../../constants/setting";
import { formatDate } from "../../Reuseables/Functions";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({
    search_bar: {
        height: "60px",
        padding: "0 4px",
        display: "flex",
        alignItems: "center",
        margin: theme.spacing(2, 0),
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    search_select: {
        width: "200px",
        "@media (max-width:900px)": {
            width: "30%",
        },
        paddingLeft: "20px",
        fontSize: "20px",
    },
    iconButton: {
        padding: "10px",
    },
    search_by: {
        paddingLeft: "20px",
    },
}));

const Pallets = () => {
    const classes = useStyles();
    const history = useHistory();
    const [search, setSearch] = useState({
        searchBy: "id",
        searchString: "",
    });
    const [boxBarcode, setBoxBarcode] = useState("");
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showDatePicker, setShowDatePicker] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleClearSearch = () => {
        setSearch({ ...search, searchString: "" });
        if (localStorage.getItem("pallet-search-text")) {
            localStorage.removeItem("pallet-search-text");
        }
    };

    const handleSearch = () => {
        getPallets();
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getPallets();
        return () => {};
    }, []);

    const getPallets = () => {
        // console.log(search);
        setIsLoading(true);
        const query = search.searchString !== "" ? `?${search.searchBy}=${search.searchString}` : "";
        const token = localStorage.getItem("warehouse-token");
        fetch(settings.api().pallets + query, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                // console.log(res);
                return res.json();
            })
            .then((data) => {
                // console.log(data);
                setResults(data);
                setIsLoading(false);
            });
    };

    return (
        <div>
            <Title subtitle="Pallets" title="Pallets" />
            <Paper component="form" className={classes.search_bar}>
                <InputBase
                    autoFocus
                    className={classes.input}
                    placeholder="Search pallet by"
                    inputProps={{
                        "aria-label": "search pallet by",
                        style: {
                            fontSize: "20px",
                        },
                    }}
                    value={search.searchString}
                    onChange={(e) => {
                        setSearch({ ...search, searchString: e.target.value });
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleSearch(search.searchBy, search.searchString);
                        }
                    }}
                />
                {showDatePicker ? (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            className={classes.spacing}
                            fullWidth
                            required={true}
                            // inputVariant=""
                            disablePast={true}
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            value={search.searchString}
                            onChange={(date) => {
                                const d = date.toISOString().split("T")[0];
                                setSearch({ ...search, searchString: d });
                            }}
                            KeyboardButtonProps={{
                                "aria-label": "change pallet release date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                ) : (
                    <IconButton
                        onClick={() => {
                            handleClearSearch();
                        }}
                        className={classes.iconButton}
                        aria-label="search"
                    >
                        <ClearIcon style={{ fontSize: "2rem" }} />
                    </IconButton>
                )}
                <Divider className={classes.divider} orientation="vertical" />
                <Select
                    disableUnderline
                    variant="standard"
                    className={classes.search_select}
                    value={search.searchBy}
                    onChange={(e) => {
                        console.log(e.target.value);
                        setSearch({ ...search, searchBy: e.target.value });
                        localStorage.setItem("pallet-search-by", e.target.value);
                        if (e.target.value === "releaseDate") {
                            setShowDatePicker(true);
                        } else {
                            setShowDatePicker(false);
                        }
                    }}
                >
                    {/* <MenuItem value={"barcode"}>Barcode</MenuItem> */}
                    <MenuItem value={"id"}>Pallet ID</MenuItem>
                    <MenuItem value={"releaseDate"}>Release Date</MenuItem>
                </Select>
                <Divider className={classes.divider} orientation="vertical" />

                <IconButton
                    onClick={() => handleSearch(search.searchBy, search.searchString)}
                    className={classes.iconButton}
                    aria-label="search"
                >
                    <SearchIcon style={{ fontSize: "2rem" }} />
                </IconButton>
            </Paper>
            <div style={{ display: "flex" }}>
                <div style={{ margin: "auto" }}>{isLoading && <CircularProgress />}</div>
            </div>
            <Paper>
                <TableContainer className={classes.puller_list}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Pallet ID</TableCell>
                                <TableCell align="center">Release Date</TableCell>
                                <TableCell align="center">Total Boxes</TableCell>
                                <TableCell align="center">Full</TableCell>
                                <TableCell align="center">On Deck</TableCell>
                                <TableCell align="center">Shipped</TableCell>
                                {/* <TableCell align="center">View</TableCell> */}
                            </TableRow>
                        </TableHead>
                        {results.length > 0 ? (
                            <TableBody>
                                {results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((p, i) => {
                                    return (
                                        <TableRow
                                            hover
                                            key={i}
                                            onClick={() => history.push(`/crm/pallets/${p.palletId}`)}
                                        >
                                            <TableCell>{p.palletId}</TableCell>
                                            <TableCell align="center">
                                                {p.releaseDateUtc && formatDate(p.releaseDateUtc)}
                                            </TableCell>
                                            <TableCell align="center">{p.totalBoxes}</TableCell>
                                            <TableCell align="center">{p.full ? "Full" : ""}</TableCell>
                                            <TableCell align="center">{p.onDeck > 0 ? "On-Deck" : "-"}</TableCell>
                                            <TableCell align="center">{p.shipped ? "Shipped" : "-"}</TableCell>
                                            {/* <TableCell align="center">
                                                <IconButton aria-label="add to shopping cart">
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </TableCell> */}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        ) : (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        There are no pallets in the warehouse
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                {results.length > 0 && (
                    <TablePagination
                        rowsPerPageOptions={[20, 50, 100]}
                        component="div"
                        count={results.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )}
            </Paper>
        </div>
    );
};

export default Pallets;

const settings = {
    background_list: {
        signonBackgrounds: [
            {
                image: "/images/login-background1.png",
                alt: "",
            },
            {
                image: "/images/login-background2.jpeg",
                alt: "",
            },
        ],
    },
    dev_env: {
        env: "DEV", // PROD or DEV
        api_host_locally: "http://localhost:8080",
        api_host_dev: "https://wcrm-staging-api.saphera.com",
        api_host_prod: "https://ss-warehouse-api.saphera.com",
        api_path: "/api/v1/",
        api_request_header_pagination: "x-pagination",
    },
    api() {
        // Set environment variables
        var host = "";
        if (this.dev_env.env === "DEV") {
            host = this.dev_env.api_host_dev;
        } else if (this.dev_env.env === "LOCAL") {
            host = this.dev_env.api_host_locally;
        } else {
            host = this.dev_env.api_host_prod;
        }

        const route = host + this.dev_env.api_path;

        // return route;
        return {
            authenticate: route + "authenticate",
            checkToken: route + "checkToken",
            init_signon: route + "init-signon",
            prepper_list: route + "prepper/package",
            prep_kit_post: route + "prepper/kit",
            prep_detail_post: route + "prepper/detail",
            // pullers: route + "pullers",
            fobs: route + "fobs",
            pickers: route + "pickers",
            puller_kit_list: route + "pullers/kits",
            puller_detail_list: route + "pullers/details",
            packer: route + "packer",
            stocker: route + "stocker/bins",
            pos: route + "pos",
            floating: route + "inventory/floating",
            floating_variation: route + "inventory/floating/variations",
            feeder: route + "feeder",
            product_bins: route + "product-bins",
            floor_inventory: route + "inventory/low-inventory",
            variations: route + "variations",
            barcodes: route + "barcodes",
            floor_bins: route + "floor-bins",
            unaccounted_reasons: route + "unaccounted-reasons",
            order_kit_products: route + "order-kit-products",
            order_detail_products: route + "order-detail-products",
            batch2go: route + "stats/feeder",
            pallets: route + "pallets",
            boxes: route + "boxes",
        };
    },
};
export default settings;

import React, { useEffect, useRef, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    ListSubheader,
    makeStyles,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    // useTheme,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import OrderDetails from "./OrderDetails";
import Title from "../../../layout/Title";
import settings from "../../../constants/setting";
import clsx from "clsx";
import { CreatePackageId } from "../../../helpers/createPackageId";
import Item from "../../Reuseables/CustomGrid";
import NumberPad from "../../Reuseables/NumberPad";
import ClearIcon from "@material-ui/icons/Clear";
import Inner404 from "../404";
import Alert from "@material-ui/lab/Alert";
import { Prompt, useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(4),
    },
    accordian: { margin: theme.spacing(2, 0) },
    accordianDetails: {
        padding: theme.spacing(0, 2, 2),
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    itemInfo: {
        border: "1px solid #3a8435",
        borderRadius: "5px",
    },
    verticalBorder: {
        display: "flex",
        borderRight: "1px solid #3a8435",
        borderLeft: "1px solid #3a8435",
    },
    verticalBorder1: {
        borderRight: "1px solid #3a8435",
        // display: "flex",
    },
    verticalBorder2: {
        // display: "flex",
        borderLeft: "1px solid #3a8435",
    },
    qtyBox: {
        // width: "120px",
        paddingTop: theme.spacing(1),
        // height: "120px",
    },
    divider: {
        background: "#bdbdbd",
    },
    cartQtyBox: {
        paddingTop: theme.spacing(4),
        // width: "120px",
        // alignItems: "flex-end",
        display: "grid",
    },
    cartQtyText: {
        // paddingTop: theme.spacing(1),
    },
    itemDetailsList: { margin: 0, padding: 0 },
    itemImage: {
        // width: "100%",
        maxWidth: "250px",
        maxHeight: "250px",
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    itemImage2: {
        position: "absolute",
        left: "50%",
        top: "50%",
        "-webkit-transform": "translate(-50%, -50%)",
        transform: "translate(-50%, -50%)",
        maxWidth: "90%",
        maxHeight: "90%",
    },
    missingBtn: {
        margin: "auto",
        backgroundColor: "#3F4A3C",
        color: "#A2AF9F",
    },
    cardContent: {
        padding: "0 10px",
        margin: 0,
    },
    orderList: {
        marginTop: theme.spacing(2),
        // maxHeight: "60vh",
        overflowY: "auto",
    },
    orderID: {
        backgroundColor: "white",
        padding: theme.spacing(1, 2),
    },
    tableBody: {
        // height: "650px",
        overflowY: "scroll",
    },
    onItem: { backgroundColor: "rgba(84,188,76,0.3)", color: "#EAE9D6" },
    onError: { backgroundColor: "#ffebee", color: "#EAE9D6" },
    collected: { backgroundColor: "#eeeeee", color: "#9e9e9e" },
    onCollected: { backgroundColor: "rgba(84,188,76,0.3)", color: "#9e9e9e" },
    doneIcon: { color: "#81c784" },
    marginBottom: {
        marginBottom: theme.spacing(2),
    },
    barcodePrev: {
        padding: theme.spacing(1, 2),
    },
    errorBtn: {
        margin: "auto",
        marginBottom: theme.spacing(2),
    },
    textfield: {
        margin: theme.spacing(1, 0),
    },
    rackImage: {
        // "@media (max-width:600px)": {
        //     // eslint-disable-line no-useless-computed-key
        //     display: "none",
        // },
        position: "relative",
        "@media (max-width:960px)": {
            // eslint-disable-line no-useless-computed-key
            display: "none",
        },
    },
    testing: {
        // height: "200px",
        // height: "100%",
        // alignItems: "stretch",

        border: "#D3D3D3 solid 1px",
    },

    // stretch: { height: "100%", padding: theme.spacing(1) },
    // item: { display: "flex", flexDirection: "column" }, // KEY CHANGES
}));

export default function PickList(props) {
    const classes = useStyles();
    const history = useHistory();
    // const theme = useTheme();
    const isMounted = useRef(false);
    const inputRef = useRef();
    // const [itemList, setItemList] = useState([...orderItems]);
    const [completedList, setCompletedList] = useState([]);
    const [barcode, setBarcode] = useState("");
    const [openError, setOpenError] = useState({ open: false, message: "" });
    const [openComplete, setOpenComplete] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [flagItem, setFlagItem] = useState({
        open: false,
        binBarcode: "",
        collected: "",
        reason: "",
        collectedError: false,
        reasonError: false,
        errorMsg: "",
    });
    const [override, setOverride] = useState({
        open: false,
        error: false,
        done: true,
        binBarcode: "",
        binBarcodeError: false,
        collected: 0,
    });
    const [finishItem, setFinishItem] = useState(0);
    const [orderPackage, setOrderPackage] = useState({});
    const [pullerItems, setPullerItems] = useState([]);
    // const [prepperItems, setPrepperItems] = useState([]);
    const [puller, setPuller] = useState(null);
    const [warning, setWarning] = useState("");
    const [collectedFrom, setCollectedFrom] = useState([]);
    const [sendRequest, setSendRequest] = useState(false);
    const [currentProduct, setCurrentProduct] = useState({});
    // console.log(override.done);
    // const overrideInputRef = useRef(null);
    // const [overrideOnFocus, setOverrideOnFocus] = useState(false);
    const [overrideNumberPadOpen, setOverrideNumberPadOpen] = useState(false);
    const [flagNumberPadOpen, setFlagNumberPadOpen] = useState(false);
    const [disableBarcodeScan, setDisableBarcodeScan] = useState(false);
    const [insufficientDataError, setInsufficientDataError] = useState(false);
    const [binMissingError, setBinMissingError] = useState(false);
    const [originalPackageData, setOriginalPackageData] = useState({});

    // Loading pick-list right after everything is mounted
    useEffect(() => {
        isMounted.current = true;
        const obj = props.history.location.state;

        if (obj === undefined) {
            setInsufficientDataError(true);
        } else {
            setPuller(obj.pullerId);

            const url = settings.api().pullers + `/${CreatePackageId(obj.packageType, obj.packageId)}`;
            // url
            (async () => {
                const result = await handleGetPackageData(url);
                setOriginalPackageData(result);

                const sortedProducts = result.products.sort((a, b) => a.pullOrder - b.pullOrder);
                // const sortedProducts = tmpResult.products;
                // console.log(sortedProducts);
                setOrderPackage({ ...result, products: sortedProducts });

                const completedItems = sortedProducts.filter((d) => d.collected);
                const zeroQtyItems = sortedProducts.filter((d) => d.quantityNeeded === 0);
                isMounted.current && setCompletedList([...completedItems, ...zeroQtyItems]);

                const pullItems = sortedProducts.filter((d) => !d.collected && !d.error);
                const flaggedItems = sortedProducts.filter((d) => d.error);
                isMounted.current && setPullerItems([...pullItems, ...flaggedItems]);

                // if package is completely pulled then load the 1st item in the completed list
                setSelectedItem(pullItems.length > 0 ? pullItems[0] : completedItems[0]);
            })();
        }

        return () => {
            isMounted.current = false;

            clearTimeout();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const unblock = history.block((newLocation, action) => {
            if (action === "POP") {
                // when popping from the history stack (going to previous page), stop action.
                return false;
            } else {
                return true;
            }
        });
        return () => {
            unblock();
        };
    }, [history]);

    const handleOverrideQuantity = () => {
        setOverrideNumberPadOpen(true);
    };

    async function handleGetPackageData(url) {
        const token = localStorage.getItem("warehouse-token");

        const result = await fetch(url, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.json();
                }
                // else if (res.status === 401) {
                //     localStorage.clear();
                //     props.history.push("/");
                // }
                else {
                    const error = new Error(res.error);
                    console.log(error);
                    return res.json();
                    // throw error;
                }
            })
            .then((data) => {
                // console.log(data);
                return data;
            })
            .catch((err) => {
                console.error(err);
            });

        return result;
    }

    const handleSelectedItem = (item) => {
        console.log(item);
        setSelectedItem(item);
        handleFocus();
    };

    const handleBarcode = (e) => {
        e.preventDefault();
        const { value } = e.target;
        setBarcode(value);

        if (value.length === 12 && value[0] === "B" && value[1] === "C") {
            let isExist = false;
            //loop through each item in pull list
            for (let index = 0; index < pullerItems.length && !isExist; index++) {
                // find item that match the entered barcode in the puller list
                const foundItem = pullerItems[index].locations.find((l) => l.binBarcode === e.target.value);
                if (foundItem !== undefined) {
                    //when item is found in pull list isExist=true
                    isExist = true;
                    //get found item from pull list
                    let obj = pullerItems[index];
                    if (
                        (Object.entries(currentProduct).length === 0 ||
                            currentProduct.packageProductId === obj.packageProductId) &&
                        currentProduct.quantityNeeded > currentProduct.quantityPicked
                    ) {
                        console.log("in here");
                        obj = {
                            ...obj,
                            quantityPicked: obj.quantityPicked + 1,
                            collected: obj.quantityPicked + 1 === obj.quantityNeeded ? true : false,
                        };

                        const binExists = collectedFrom.find((o) => o.productBinId === foundItem.productBinId);
                        if (binExists) {
                            setCollectedFrom(
                                collectedFrom.map((prev) => {
                                    return prev.productBinId === foundItem.productBinId
                                        ? { ...prev, quantityPicked: prev.quantityPicked + 1 }
                                        : prev;
                                })
                            );
                        } else {
                            setCollectedFrom([
                                ...collectedFrom,
                                {
                                    productBinId: foundItem.productBinId,
                                    quantityPicked: 1,
                                },
                            ]);
                        }

                        if (obj.quantityPicked === obj.quantityNeeded) {
                            // setFinishItem(finishItem + 1);
                            // setPullerItems(
                            //     (prev) => prev.filter((i) => i.packageProductId !== obj.packageProductId) || prev
                            // );
                            // setCompletedList((prev) => [...prev, obj]);
                            // if (tmp_list[0]) {
                            //     handleSelectedItem(tmp_list[0]);
                            // } else {
                            //     setOpenComplete(true);
                            // }

                            // setCurrentProduct(obj);
                            setSendRequest(true);
                        } else {
                            setSelectedItem(obj);
                            setPullerItems((pullerItems) =>
                                pullerItems.map((p) => (p.packageProductId === obj.packageProductId ? obj : p))
                            );
                        }
                        setCurrentProduct(obj);
                    } else {
                        setOpenError({ open: true, message: "" });
                    }
                }
            }
            // this item in not in this package (wrong item)
            if (!isExist) {
                setOpenError({ open: true, message: "" });
            }
            setBarcode("");
        }
    };

    useEffect(() => {
        let inputScanRef = inputRef.current;
        if (!flagItem.open && !override.open) {
            inputScanRef.focus();
        } else {
            setDisableBarcodeScan(true);
        }

        return () => {
            inputScanRef = null;
        };
    }, [flagItem.open, override.open]);

    // BC0000000027
    useEffect(() => {
        if (sendRequest) {
            const token = localStorage.getItem("warehouse-token");
            let url =
                orderPackage.packageType === 1 ? settings.api().puller_kit_list : settings.api().puller_detail_list;
            url = url + `/${orderPackage.packageId}`;

            const request_obj = {
                packageProductId: currentProduct.packageProductId,
                userId: puller,
                error: currentProduct.error,
                errorMsg: currentProduct.errorMsg,
                packageId: orderPackage.packageId,
                packageType: orderPackage.packageType,
                bins: collectedFrom,
            };

            // console.log(JSON.stringify(request_obj));
            // console.log(request_obj);

            fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                method: "POST",
                body: JSON.stringify(request_obj),
            })
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        // when collecting item with flag
                        if (flagItem.open) {
                            // remove from front of the list to the end
                            const restOfPuller = pullerItems.filter(
                                (i) => i.packageProductId !== selectedItem.packageProductId
                            );
                            const a = pullerItems.find((i) => i.packageProductId === selectedItem.packageProductId);

                            setPullerItems([...restOfPuller, { ...a, quantityPicked: a.quantityPicked + 1 }]);
                            handleSelectedItem(restOfPuller[0]);
                        } else {
                            setFinishItem(finishItem + 1);
                            setPullerItems(
                                (prev) =>
                                    prev.filter((i) => i.packageProductId !== currentProduct.packageProductId) || prev
                            );
                            setCompletedList((prev) => [...prev, currentProduct]);
                            const tmp_list = pullerItems.filter(
                                (p) => p.packageProductId !== currentProduct.packageProductId
                            );
                            if (tmp_list[0]) {
                                handleSelectedItem(tmp_list[0]);
                            } else {
                                setOpenComplete(true);
                            }
                        }

                        setCollectedFrom([]);
                        setCurrentProduct({});

                        setFlagItem({
                            open: false,
                            collected: 0,
                            reason: "",
                            collectedError: false,
                            reasonError: false,
                            errorMsg: "",
                        });
                        setDisableBarcodeScan(false);
                    } else if (res.status === 401) {
                        //if unauthorizied, log user out
                        localStorage.clear();
                        props.history.push("/");
                    } else {
                        // const error = new Error(res.error);
                        // throw error;
                        // remove collected from bin set by override
                        setCollectedFrom((prev) => prev.filter((i) => !i.override && !i.flag));
                        setCurrentProduct({});

                        return res.json();
                    }
                })
                .then((data) => {
                    // console.log(data);
                    data !== undefined && setOpenError({ open: true, message: data });
                })
                .catch((err) => {
                    console.log(err);
                });
            setSendRequest(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendRequest]);
    // console.log(collectedFrom);
    const handleFlagItem = () => {
        // console.log("inside");
        const collected = parseInt(flagItem.collected);

        let tmpFlagged = { ...flagItem, collectedError: false, reasonError: false, errorMsg: "" };
        // setFlagItem({ ...flagItem, collectedError: false, reasonError: false, errorMsg: "" });
        // console.log(collected, selectedItem.quantityNeeded);
        // validate form
        if (collected > selectedItem.quantityNeeded) {
            tmpFlagged = { ...flagItem, collectedError: true, errorMsg: "Cannot collect more than needed." };
            setFlagItem(tmpFlagged);
            return;
        } else if (collected === selectedItem.quantityNeeded) {
            tmpFlagged = {
                ...flagItem,
                collectedError: true,
                errorMsg:
                    "Flagging item is not needed if collected quantity is the same as required quantity. Please pull item as normal.",
            };
            setFlagItem(tmpFlagged);
            return;
        }
        if (flagItem.reason === "") {
            tmpFlagged = {
                ...flagItem,
                reasonError: true,
                errorMsg: "Please add a reason for flagging this item.",
            };
            setFlagItem(tmpFlagged);
            return;
        }

        let obj = selectedItem;
        obj = {
            ...obj,
            quantityPicked: flagItem.collected,
            error: true,
            errorMsg: flagItem.reason,
        };
        setCurrentProduct(obj);
        const foundBin = selectedItem.locations.find((l) => {
            // console.log(l, override.binBarcode);
            return l.binBarcode === flagItem.binBarcode;
        });
        // setCollectedFrom([
        //     ...collectedFrom,
        //     { productBinId: foundBin ? foundBin.productBinId : 0, quantityPicked: collected, flag: true },
        // ]);
        setCollectedFrom((prev) =>
            prev.map((i) => {
                console.log(i.productBinId, foundBin.productBinId);
                return i.productBinId === foundBin.productBinId
                    ? { productBinId: foundBin ? foundBin.productBinId : 0, quantityPicked: collected, override: true }
                    : i;
            })
        );
        // setFinishItem(finishItem + 1);
        // setPullerItems((prev) => prev.filter((i) => i.packageProductId !== selectedItem.packageProductId) || prev);
        // setCompletedList((prev) => [...prev, obj]);
        setSendRequest(true);
        // setFlagItem({ open: false, collected: "", reason: "" });
        // setDisableBarcodeScan(false);
        // if (pullerItems[1]) {
        //     const tmp = pullerItems[1];
        //     handleSelectedItem(tmp);
        // } else {
        //     setOpenComplete(true);
        // }
    };

    // munual scan (used to be called override)
    const handleOverride = () => {
        const originalSelected = originalPackageData.products.find((o) => {
            return o.packageProductId === selectedItem.packageProductId;
        });
        // console.log(originalSelected);
        // console.log(override);
        const collected = parseInt(override.collected);
        // console.log(collected);
        // const remainingNeeded = selectedItem.quantityNeeded - selectedItem.quantityPicked;
        const remainingNeeded = originalSelected.quantityNeeded - originalSelected.quantityPicked;
        // console.log(remainingNeeded);

        if (collected > remainingNeeded) {
            setWarning("Number entered cannot be greater than quantity needed.");
            setOverride({ ...override, error: true });
        } else if (collected < remainingNeeded) {
            setWarning("Number entered cannot be less than quantity needed.");
            setOverride({ ...override, error: true });
        } else {
            const foundBin = selectedItem.locations.find((l) => {
                // console.log(l, override.binBarcode);
                return l.binBarcode === override.binBarcode;
            });

            // console.log(selectedItem);

            // console.log(foundBin);
            // const foundBin = currentProduct.locations.find((l) => l.binBarcode === override.binBarcode);

            // console.log(selectedItem);
            // if (foundBin === undefined) {
            //     // incorrect bin barcode is provided.
            //     setWarning("Wrong bin barcode has been provided. Please double-check bin.");
            // } else {
            setWarning("");

            let obj = selectedItem;
            // console.log(foundBin);
            // obj = {
            //     ...obj,
            //     quantityPicked: selectedItem.quantityPicked + collected,
            //     collected: selectedItem.quantityPicked + collected === selectedItem.Needed ? true : false,
            // };
            obj = {
                ...obj,
                quantityPicked: originalSelected.quantityPicked + collected,
                collected: originalSelected.quantityPicked + collected === originalSelected.Needed ? true : false,
            };
            // console.log(obj);
            // set which bin products are collected from
            // setCollectedFrom([...collectedFrom, { productBinId: foundBin.productBinId, quantityPicked: collected }]);
            setCollectedFrom([
                // ...collectedFrom,
                { productBinId: foundBin ? foundBin.productBinId : 0, quantityPicked: collected, override: true },
            ]);

            setCurrentProduct(obj);
            // setFinishItem(finishItem + 1);
            // setPullerItems((prev) => prev.filter((i) => i.packageProductId !== selectedItem.packageProductId) || prev);
            // setCompletedList((prev) => [...prev, obj]);
            setSendRequest(true);

            setOverride({ ...override, open: false, binBarcode: "", collected: 0 });
            setDisableBarcodeScan(false);

            // if (pullerItems[1]) {
            //     const tmp = pullerItems[1];
            //     handleSelectedItem(tmp);
            // } else {
            //     setOpenComplete(true);
            // }
            // }
            // }
            // setOverride({ ...override, open: false, collected: 0 });
        }
    };

    // console.log(override.error, warning);
    const handleOverrideNumber = (value) => {
        setOverride({ ...override, collected: value ? parseInt(value) : 0 });
    };
    const handleOverrideNumberClick = (value) => {
        const tmp = `${override.collected}${value}`;
        console.log(tmp);
        const v = parseInt(tmp);
        setOverride({ ...override, collected: v });
    };

    const handleBinMissing = () => {
        const token = localStorage.getItem("warehouse-token");
        let base_url =
            orderPackage.packageType === 1 ? settings.api().order_kit_products : settings.api().order_detail_products;
        // console.log(selectedItem.packageProductId);
        // console.log(base_url);
        base_url += `/${selectedItem.packageProductId}/missing-bin?`;

        const query_url =
            orderPackage.packageType === 1
                ? `orderKitProductId=${selectedItem.packageProductId}`
                : `orderDetailProductId=${selectedItem.packageProductId}`;
        const missing_item_url = base_url + query_url;

        // console.log(missing_item_url);
        fetch(missing_item_url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: "POST",
        })
            .then((res) => {
                console.log(res);
                if (res.status !== 200) {
                    const error = new Error(res.error);
                    throw error;
                } else {
                    const restOfPuller = pullerItems.filter(
                        (i) => i.packageProductId !== selectedItem.packageProductId
                    );
                    const a = pullerItems.find((i) => i.packageProductId === selectedItem.packageProductId);

                    setPullerItems([...restOfPuller, { ...a, error: true }]);
                    handleSelectedItem(restOfPuller[0]);
                }
            })
            .catch((err) => {
                console.error(err);
                setBinMissingError(true);
            });
    };

    const handleFocus = () => {
        document.getElementById("barcodeField").focus();
    };

    const handleNextStation = () => {
        if (completedList.length === orderPackage.products.length) {
            props.history.push({
                pathname: "/crm/packages/ready-to-pack",
                state: { orderPackage: orderPackage },
            });
        } else {
            const url =
                settings.api().pullers + `/${CreatePackageId(orderPackage.packageType, orderPackage.packageId)}`;
            // console.log(url);
            (async () => {
                const result = await handleGetPackageData(url);
                // console.log(result);

                props.history.push({
                    pathname: `/crm/packages/review_pulllist`,
                    search: props.history.location.search,
                    // state: { ...orderPackage, products: [...pullerItems, ...completedList] },
                    state: result,
                });
            })();
        }
    };

    if (insufficientDataError) {
        return (
            <div>
                <Inner404 />
            </div>
        );
    }
    // console.log(selectedItem);

    // console.log(selectedItem);
    return (
        <div>
            <Grid container justifycontent="space-between" alignItems="center">
                <Grid item xs>
                    <Title subtitle="Fulfillment" title={`Name for Order# ${orderPackage.orderId}`} />
                </Grid>
                <Grid item xs align="right">
                    <Button variant="contained" color="primary" onClick={handleNextStation}>
                        I'm Done
                    </Button>
                </Grid>
            </Grid>
            <Accordion className={classes.accordian}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="Order Details">
                    <Typography variant="subtitle1">Order Details</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordianDetails}>
                    <OrderDetails orderPackage={orderPackage} />
                </AccordionDetails>
            </Accordion>
            <TextField
                disabled={disableBarcodeScan}
                id="barcodeField"
                autoFocus
                inputRef={inputRef}
                className={classes.marginBottom}
                variant="outlined"
                value={barcode}
                onChange={handleBarcode}
                fullWidth
                placeholder="Scan & Collect Item"
                inputProps={{
                    maxLength: 12,
                    style: {
                        textAlign: "center",
                        backgroundColor: "white",
                    },
                }}
                onBlur={() => {
                    if (!flagItem.open && !override.open) {
                        let inputScanRef = inputRef.current;
                        setTimeout(() => {
                            inputScanRef.focus();
                        }, 1000);
                    } else {
                    }
                }}
            />

            {selectedItem.error && (
                <Alert severity="error" style={{ marginBottom: "20px" }}>
                    Flagged Item: {selectedItem.errorMsg}
                </Alert>
            )}

            {/* main item full view */}
            <Grid container spacing={1} justify="space-between" alignItems={"stretch"}>
                <Item
                    md={3}
                    xs={4}
                    align="center"
                    content={
                        <>
                            <Box className={classes.qtyBox}>
                                <Typography variant="h5" display="inline">
                                    Quantity: {selectedItem.quantityNeeded && selectedItem.quantityNeeded}
                                </Typography>
                            </Box>
                            <Divider className={classes.divider} />
                            <Box className={classes.cartQtyBox}>
                                {/* <Divider /> */}
                                <Typography variant="h2" display="inline">
                                    {selectedItem.quantityPicked ? selectedItem.quantityPicked : 0}
                                </Typography>
                                <Typography variant="subtitle1" display="inline">
                                    in Cart
                                </Typography>
                            </Box>

                            <Button
                                variant="outlined"
                                style={{ marginTop: "20px", width: "100%" }}
                                onClick={handleBinMissing}
                                disabled={selectedItem.error || selectedItem.collected}
                            >
                                Bin Missing
                            </Button>
                        </>
                    }
                />
                <Item
                    md={6}
                    xs={8}
                    content={
                        <div>
                            <div>
                                <List className={classes.itemDetailsList}>
                                    <ListItem>
                                        <Typography variant="h6">
                                            <strong>{selectedItem.productName}</strong>
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography>Product ID: {selectedItem.packageProductId}</Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography display="inline">Bin: </Typography>
                                        <Typography display="inline" variant="h5">
                                            {selectedItem.locations &&
                                                selectedItem.locations.map((l, i) =>
                                                    i + 1 !== selectedItem.locations.length
                                                        ? i === 0
                                                            ? `Locations: ${l.location}, `
                                                            : `${l.location}, `
                                                        : `${l.location}`
                                                )}
                                        </Typography>
                                    </ListItem>

                                    <ListItem display="flex">
                                        <Grid container>
                                            <Grid item xs>
                                                <Button
                                                    fullWidth
                                                    color={
                                                        selectedItem?.locations?.length === 0 ? "default" : "secondary"
                                                    }
                                                    variant="outlined"
                                                    style={{ marginRight: "5px" }}
                                                    onClick={() => setFlagItem({ ...flagItem, open: true })}
                                                    disabled={selectedItem?.locations?.length === 0}
                                                >
                                                    Flag
                                                </Button>
                                            </Grid>
                                            <Grid item xs>
                                                {selectedItem?.locations?.length === 0 ? (
                                                    <Tooltip title="No Location Availble" placement="top">
                                                        <span>
                                                            <Button
                                                                component="div"
                                                                fullWidth
                                                                color="primary"
                                                                variant="outlined"
                                                                style={{ marginLeft: "5px" }}
                                                                // onClick={() => setOverride({ ...override, open: true })}
                                                                disabled={selectedItem?.locations?.length === 0}
                                                                // ref={overrideInputRef}
                                                                onFocus={handleOverrideQuantity}
                                                            >
                                                                Manual Scan
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                ) : (
                                                    <Button
                                                        fullWidth
                                                        color="primary"
                                                        variant="outlined"
                                                        style={{ marginLeft: "5px" }}
                                                        onClick={() => {
                                                            setOverride({ ...override, open: true });
                                                            // console.log(selectedItem);
                                                        }}
                                                    >
                                                        Manual Scan
                                                    </Button>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                </List>
                            </div>
                        </div>
                    }
                />
                <Item
                    align="center"
                    md={3}
                    xs={4}
                    className={clsx(classes.rackImage)}
                    content={
                        <div
                            // style={{ height: "100%" }}
                            style={{
                                // width: "30%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img
                                className={classes.itemImage}
                                src={selectedItem.thmbUrl ? selectedItem.thmbUrl : "/images/no-image.jpg"}
                                alt={selectedItem.productName}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "/images/no-image.jpg";
                                }}
                            />
                        </div>
                    }
                />
            </Grid>
            <Card className={classes.orderList} variant="outlined">
                <ListSubheader className={classes.orderID}>
                    <Grid container justify="space-between">
                        <Grid item sm={6}>
                            <Typography variant="subtitle1" display="inline">
                                Puller List
                            </Typography>
                        </Grid>
                        <Grid item sm={6} align="right">
                            <Typography variant="subtitle2" align="right" display="inline">
                                {Object.entries(orderPackage).length > 0 &&
                                    `Items Fulfilled: ${completedList.length}/${orderPackage.products.length}`}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </ListSubheader>
                <CardContent className={classes.cardContent}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">ID</TableCell>
                                <TableCell>Item</TableCell>
                                <TableCell align="center">Quantity</TableCell>
                                <TableCell align="center">Location</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Outstanding</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.tableBody}>
                            {pullerItems.length !== 0 &&
                                pullerItems.map((i, index) => {
                                    // console.log(i);
                                    return (
                                        <TableRow
                                            key={index}
                                            hover
                                            onClick={() => handleSelectedItem(i)}
                                            className={`${
                                                i.packageProductId === selectedItem.packageProductId
                                                    ? classes.onItem
                                                    : i.error
                                                    ? classes.onError
                                                    : null
                                            }`}
                                        >
                                            <TableCell className={`${i.collected && classes.collected}`}>
                                                {i.productSku}
                                            </TableCell>
                                            <TableCell className={`${i.collected && classes.collected}`}>
                                                {i.productName}
                                            </TableCell>
                                            <TableCell align="center" className={`${i.collected && classes.collected}`}>
                                                {i.quantityNeeded}
                                            </TableCell>
                                            <TableCell align="center" className={`${i.collected && classes.collected}`}>
                                                {i.locations &&
                                                    i.locations.map((l, index) =>
                                                        index + 1 !== i.locations.length
                                                            ? `${l.location}, `
                                                            : l.location
                                                    )}
                                            </TableCell>

                                            <TableCell className={`${i.collected && classes.collected}`} align="center">
                                                {i.collected ? (
                                                    <DoneIcon className={classes.doneIcon} />
                                                ) : i.error ? (
                                                    "flagged"
                                                ) : (
                                                    "-"
                                                )}
                                            </TableCell>
                                            <TableCell className={`${i.collected && classes.collected}`} align="center">
                                                {i.quantityNeeded - i.quantityPicked}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}

                            {completedList.map((i, index) => {
                                // console.log(i.packageProductId === selectedItem.packageProductId);
                                if (i.packageProductId === selectedItem.packageProductId) {
                                    console.log(i.packageProductId, selectedItem.packageProductId);
                                }

                                return (
                                    <TableRow
                                        key={index}
                                        hover
                                        onClick={() => {
                                            handleSelectedItem(i);
                                        }}
                                        // double check this condition
                                        // className={`${
                                        //     i.packageProductId === selectedItem.packageProductId
                                        //         ? classes.onItem
                                        //         : classes.onCollected
                                        // }`}
                                    >
                                        <TableCell
                                            // className={classes.collected}
                                            className={`${
                                                i.packageProductId === selectedItem.packageProductId
                                                    ? classes.onCollected
                                                    : classes.collected
                                            }`}
                                        >
                                            {i.productSku}
                                        </TableCell>
                                        <TableCell
                                            className={`${
                                                i.packageProductId === selectedItem.packageProductId
                                                    ? classes.onCollected
                                                    : classes.collected
                                            }`}
                                        >
                                            {i.productName}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            className={`${
                                                i.packageProductId === selectedItem.packageProductId
                                                    ? classes.onCollected
                                                    : classes.collected
                                            }`}
                                        >
                                            {i.quantityNeeded}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            className={`${
                                                i.packageProductId === selectedItem.packageProductId
                                                    ? classes.onCollected
                                                    : classes.collected
                                            }`}
                                        >
                                            {i.locations &&
                                                i.locations.map((l, index) =>
                                                    index + 1 !== i.locations.length ? `${l.location}, ` : l.location
                                                )}
                                        </TableCell>
                                        <TableCell
                                            className={`${
                                                i.packageProductId === selectedItem.packageProductId
                                                    ? classes.onCollected
                                                    : classes.collected
                                            }`}
                                            align="center"
                                        >
                                            {i.collected ? (
                                                <DoneIcon className={classes.doneIcon} />
                                            ) : i.error ? (
                                                "flagged"
                                            ) : (
                                                "-"
                                            )}
                                        </TableCell>
                                        <TableCell
                                            className={`${
                                                i.packageProductId === selectedItem.packageProductId
                                                    ? classes.onCollected
                                                    : classes.collected
                                            }`}
                                            align="center"
                                        >
                                            {i.quantityNeeded - i.quantityPicked}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
            <Dialog open={flagItem.open} aria-labelledby="Flag Item" aria-describedby="Flag Item">
                <DialogTitle>Flag Item</DialogTitle>
                {/* <form onSubmit={}> */}
                <DialogContent>
                    <TextField
                        autoFocus
                        // error={flagItem.binBarcodeError}
                        className={classes.marginBottom}
                        label={"Bin Barcode"}
                        variant="outlined"
                        value={flagItem.binBarcode}
                        onChange={(e) => setFlagItem({ ...flagItem, binBarcode: e.target.value })}
                        fullWidth
                        placeholder="Scan Bin Barcode"
                        inputProps={{
                            maxLength: 12,
                            style: {
                                textAlign: "center",
                                backgroundColor: "white",
                            },
                        }}
                        onFocus={() => setFlagNumberPadOpen(false)}
                    />
                    <TextField
                        error={flagItem.collectedError}
                        id="outlined-basic"
                        type="number"
                        min="0"
                        fullWidth
                        label="Collected"
                        placeholder="Quantity collected"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                        value={flagItem.collected}
                        onFocus={(event) => {
                            event.target.select();
                            setFlagNumberPadOpen(true);
                        }}
                        onChange={(e) => {
                            const regex = /^[0-9]+\d*$/;
                            if (e.target.value === "" || regex.test(e.target.value)) {
                                setFlagItem({ ...flagItem, collected: e.target.value });
                            }
                        }}
                        // className={classes.textfield}
                        className={classes.marginBottom}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="clear-textfield"
                                        onClick={() => setFlagItem({ ...flagItem, collected: "" })}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        fullWidth
                        required
                        error={flagItem.reasonError}
                    >
                        <InputLabel id="demo-simple-select-outlined-label">Reason for missing quantity</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={flagItem.reason}
                            onChange={(e) => setFlagItem({ ...flagItem, reason: e.target.value })}
                            onFocus={() => setFlagNumberPadOpen(false)}
                            label="Reason for missing quantity"
                            defaultValue=""
                        >
                            <MenuItem value={"out-of-stock"}>Out of stock</MenuItem>
                            <MenuItem value={"damaged"}>Damaged</MenuItem>
                            <MenuItem value={"unknown"}>Other</MenuItem>
                        </Select>
                    </FormControl>
                    {flagItem.errorMsg !== "" && (
                        <Typography variant="body1" color="secondary" style={{ marginTop: "10px" }}>
                            {flagItem.errorMsg}
                        </Typography>
                    )}
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {flagNumberPadOpen && (
                            <NumberPad
                                onNumberClick={(v) => {
                                    const tmp = `${flagItem.collected}${v}`;
                                    setFlagItem({ ...flagItem, collected: tmp });
                                }}
                            />
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={(e) => {
                            setFlagItem({
                                ...flagItem,
                                open: false,
                                reason: "",
                                collected: 0,
                                collectedError: false,
                                reasonError: false,
                                errorMsg: "",
                            });
                            setDisableBarcodeScan(false);
                        }}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleFlagItem} color="primary">
                        Done
                    </Button>
                </DialogActions>
                {/* </form> */}
            </Dialog>
            <Dialog open={override.open} aria-labelledby="Override Item" aria-describedby="Override Item">
                <DialogTitle>Manual Scan</DialogTitle>
                <DialogContent style={{ width: "400px", margin: "auto" }}>
                    <TextField
                        autoFocus
                        error={override.binBarcodeError}
                        className={classes.marginBottom}
                        label={"Bin Barcode"}
                        variant="outlined"
                        value={override.binBarcode}
                        onChange={(e) => setOverride({ ...override, binBarcode: e.target.value })}
                        fullWidth
                        placeholder="Scan Bin"
                        inputProps={{
                            maxLength: 12,
                            // pattern: "[BC][0-9]{10}",
                            style: {
                                textAlign: "center",
                                backgroundColor: "white",
                            },
                        }}
                        onFocus={() => setOverrideNumberPadOpen(false)}
                    />
                    <TextField
                        error={override.error}
                        type="number"
                        min="0"
                        fullWidth
                        label="Override"
                        placeholder="Quantity collected"
                        variant="outlined"
                        required
                        value={override.collected}
                        // error={warning !== ""}
                        onChange={(e) => handleOverrideNumber(e.target.value)}
                        // onFocus={() => (overrideInputRef.current = "override")}
                        onFocus={() => setOverrideNumberPadOpen(true)}
                        // onBlur={() => setOverrideNumberPadOpen(false)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setOverride({ ...override, collected: "" })}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        // ref={overrideInputRef}
                    />
                    <DialogContentText color="secondary">{warning}</DialogContentText>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {overrideNumberPadOpen && <NumberPad onNumberClick={(v) => handleOverrideNumberClick(v)} />}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={(e) => {
                            setOverride({ ...override, open: false, collected: "", error: false });
                            setDisableBarcodeScan(false);
                            setWarning("");
                        }}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button variant="contained" disabled={!override.done} onClick={handleOverride} color="primary">
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openError.open}
                aria-labelledby="Item Error Alert"
                aria-describedby="An item is either over scanned or the wrong item is scanned."
            >
                <DialogTitle>Item Error</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {openError.message
                            ? `Error: ${openError.message}`
                            : "This item is over scanned or is the wrong item."}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={classes.errorBtn}
                        variant="contained"
                        onClick={() => setOpenError({ open: false, message: "" })}
                        color="primary"
                    >
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openComplete}
                aria-labelledby="Order Complete"
                aria-describedby="All items has been collected. Confirm order completed."
            >
                <DialogTitle>Package Complete</DialogTitle>
                <DialogContent>
                    <DialogContentText>All items has been collected. Confirm order completed.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => {
                            props.history.push({
                                pathname: "/crm/packages/ready-to-pack",
                                state: { orderPackage: orderPackage },
                            });
                        }}
                        color="primary"
                    >
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={binMissingError}
                aria-labelledby="Bin Missing Error"
                aria-describedby="An error occurred while trying to report a bin missing."
            >
                <DialogTitle>Bin Missing Error</DialogTitle>
                <DialogContent>
                    <DialogContentText>An error occurred while trying to report a bin missing.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => setBinMissingError(false)} color="primary">
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
            {/* <Prompt
                message={(location, action) => {
                    if (action === "POP") {
                        console.log("Backing up...");
                        return location.pathname.startsWith("/app")
                            ? true
                            : `Are you sure you want to go to ${location.pathname}?`;
                    } else {
                        return null;
                    }
                }}
            /> */}
        </div>
    );
}

import React, { useState } from "react";
import { List, ListItem, makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    barcodeScanner: {
        backgroundColor: "white",
        margin: theme.spacing(0, "auto"),
    },
}));

export default function ScanInput(props) {
    const classes = useStyles();
    const [value, setValue] = useState(props.value);
    // console.log(value);
    const handleValueChange = (e) => {
        setValue(e.target.value);
        props.onChange(e.target.value);
    };

    return (
        <div>
            <List>
                <ListItem style={{ padding: 0 }}>
                    <TextField
                        id="barcodeField"
                        autoFocus
                        fullWidth={props.fullWidth}
                        className={classes.barcodeScanner}
                        variant="outlined"
                        // value={value}
                        value={props.value}
                        color="primary"
                        onChange={(e) => handleValueChange(e)}
                        placeholder={props.placeholder}
                        inputProps={{
                            maxLength: props.maxLength,
                            style: {
                                backgroundColor: "white",
                                fontSize: "24px",
                                textAlign: "center",
                                width: "300px",
                            },
                        }}
                    />
                </ListItem>
            </List>
        </div>
    );
}

import React, { useState } from "react";
import {
    Card,
    CardContent,
    IconButton,
    InputBase,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import Title from "../../layout/Title";

const useStyles = makeStyles((theme) => ({
    search_bar: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        margin: theme.spacing(2, 0),
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
}));

export default function Unaccounted() {
    const classes = useStyles();
    const [list, setList] = useState([]);

    return (
        <div>
            <Title title={"Unaccounted Items"} description="List of items that are unaccounted for or damaged." />
            <Paper component="form" className={classes.search_bar}>
                <InputBase
                    autoFocus
                    className={classes.input}
                    placeholder="Search Item"
                    inputProps={{ "aria-label": "Search Item" }}
                    // value={barcode.input}
                    // onChange={handleChange}
                />

                <IconButton
                    // type="submit" onSubmit={handleSearch}
                    // onClick={handleSearch}
                    aria-label="search"
                >
                    <SearchIcon />
                </IconButton>
            </Paper>
            {list.length <= 0 ? (
                <Card>
                    <CardContent style={{ textAlign: "center" }}>There are no item in the damaged bin.</CardContent>
                </Card>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead style={{ backgroundColor: "#eeeeee" }}>
                            <TableRow>
                                <TableCell>Sku</TableCell>
                                {/* <TableCell>Quantity</TableCell> */}
                                <TableCell>Product</TableCell>
                                <TableCell>Variation</TableCell>
                                <TableCell>From Bin</TableCell>
                                <TableCell>Reason</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((i, index) => (
                                <TableRow key={index}>
                                    <TableCell>{i.sku}</TableCell>
                                    <TableCell>{i.quantity}</TableCell>
                                    <TableCell>{i.productName}</TableCell>
                                    <TableCell>{i.productVariationName}</TableCell>
                                    <TableCell>
                                        <IconButton
                                        // onClick={() => handleProductToBin(i.sku)}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
}

import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    List,
    ListItem,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useTheme,
} from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import settings from "../../../constants/setting";
import Title from "../../../layout/Title";
// import ScanInput from "../../Reuseables/ScanInput";
import { CreatePackageId } from "../../../helpers/createPackageId";
import DoneIcon from "@material-ui/icons/Done";
import DisplayDataCell from "../../Reuseables/DisplayDataCell";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
    package_info: {},
    orderType: {
        padding: theme.spacing(0.25, 6),
        fontSize: "18px",
    },
    barcodeScanner: {
        backgroundColor: "white",
        margin: theme.spacing(0, "auto"),
    },
}));

export default function Feeder() {
    const theme = useTheme();
    const classes = useStyles();
    const isMounted = useRef(false);
    const history = useHistory();
    const inputRef = useRef();
    const [barcode, setBarcode] = useState("");
    const [packageInfo, setPackageInfo] = useState({
        readyForBoxing: false,
    });
    const [isComplete, setIsComplete] = useState(false);
    const [token, setToken] = useState("");
    const [feederId, setFeederId] = useState("");
    const [openUserScan, setOpenUserScan] = useState(false);
    const [disableBarcodeScan, setDisableBarcodeScan] = useState(false);
    const [packageError, setPackageError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleBatchComplete = () => {
        setIsComplete(false);
        history.push("/crm/packages");
    };
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        isMounted.current = true;
        setToken(localStorage.getItem("warehouse-token"));

        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        // This whole useEffect is for when dialogs close, so it auto focus back to the input
        let inputScanRef = inputRef.current;
        // if (!isComplete && !openUserScan) {
        if (!packageInfo?.readyForBoxing && !openUserScan) {
            inputScanRef.focus();
        } else {
            setDisableBarcodeScan(true);
        }

        return () => {
            inputScanRef = null;
        };
    }, [packageInfo?.readyForBoxing, openUserScan]);

    const handlePackageAtPacker = (value) => {
        setPackageError("");
        // const { value } = e.target;
        setBarcode(value);
        if (value.length === 12 && value[0] === "P") {
            setIsLoading(true);
            fetch(settings.api().feeder + `/${value}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    // console.log(res);
                    setIsLoading(false);
                    if (res.status !== 200) {
                        const error = new Error(res.error);
                        throw error;
                    }
                    return res.json();
                })
                .then((data) => {
                    // console.log(data);
                    if (data === null) {
                        setPackageError(`${value} is a invalid package ID. Please scan another package`);
                    } else {
                        isMounted.current && setPackageInfo({ ...data, scannedBarcode: value });
                    }

                    setBarcode("");
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };
    // console.log(packageInfo);

    useEffect(() => {
        const c = checkIsBatchCompleted();

        if (c === true) {
            setIsComplete(true);
            setDisableBarcodeScan(true);
        }
    }, [packageInfo, history]);

    const checkIsBatchCompleted = () => {
        if (packageInfo.packages) {
            // console.log(packageInfo.packages);
            let c = true;
            for (let index = 0; c && index < packageInfo.packages.length; index++) {
                const item = packageInfo.packages[index];
                if (!item.completed) {
                    c = false;
                }
            }
            if (c === true) {
                return true;
            }
        } else if (packageInfo.packages === undefined) return null;
        return false;
    };

    const handlePackageReady = (userId) => {
        fetch(settings.api().feeder + `/${packageInfo.scannedBarcode}/close?userID=${userId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            method: "POST",
        })
            .then((res) => {
                console.log(res);
                if (res.status !== 200) {
                    const error = new Error(res.error);
                    throw error;
                } else {
                    return res.json();
                }

                // const tmp = packageInfo.packages.map((prev) =>
                //     prev.packageId === packageInfo.packageId ? { ...prev, completed: true } : prev
                // );
                // console.log(tmp);
                // setPackageInfo({ ...packageInfo, packageFeederComplete: true, packages: tmp });

                // return res.json();
            })
            .then((data) => {
                console.log(data);
                const tmp = packageInfo.packages.map((prev) =>
                    prev.packageId === packageInfo.packageId ? { ...prev, completed: true } : prev
                );
                setPackageInfo({
                    ...packageInfo,
                    packageFeederComplete: true,
                    packages: tmp,
                    readyForFeeder: data.readyForFeeder,
                    readyForBoxing: data.readyForBoxing,
                });
                setDisableBarcodeScan(false);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    // console.log(packageInfo);

    const handleCheckUser = (e) => {
        const { value } = e.target;
        setFeederId(value);

        if (value.length === 13) {
            setIsLoading(true);
            const token = localStorage.getItem("warehouse-token");
            // console.log(token);
            fetch(settings.api().fobs + `/${value}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    // console.log(res);
                    setIsLoading(false);
                    if (res.ok) {
                        return res.json();
                    } else {
                        setErrorMessage("");
                        const error = new Error(res.error);
                        throw error;
                    }
                })
                .then((data) => {
                    // console.log(data);
                    if (data.userId) {
                        // setPackageInfo({ ...packageInfo, userId: data.userId });
                        handlePackageReady(data.userId);
                    }
                    setOpenUserScan(false);
                    setDisableBarcodeScan(false);
                })
                .catch((err) => {
                    setErrorMessage("Invalid user. Try again.");
                    console.error(err);
                });
            // setLoading(false);

            setFeederId("");
        }
    };

    // console.log(isLoading);

    return (
        <div>
            <Title subtitle="Production" title="Feeder" />

            <List>
                <ListItem style={{ padding: 0 }}>
                    <TextField
                        id="barcodeField"
                        inputRef={inputRef}
                        disabled={disableBarcodeScan}
                        autoFocus
                        className={classes.barcodeScanner}
                        variant="outlined"
                        value={barcode}
                        color="primary"
                        onChange={(e) => handlePackageAtPacker(e.target.value)}
                        placeholder="Scan Package"
                        inputProps={{
                            style: {
                                backgroundColor: "white",
                                fontSize: "24px",
                                textAlign: "center",
                                width: "300px",
                            },
                        }}
                        onBlur={() => {
                            // if (!isComplete && !openUserScan) {
                            if (!packageInfo?.readyForBoxing && !openUserScan) {
                                let inputScanRef = inputRef.current;
                                setTimeout(() => {
                                    inputScanRef.focus();
                                }, 1000);
                            }
                        }}
                    />
                </ListItem>
                <ListItem>
                    <div style={{ margin: "auto", color: "red" }}>
                        {packageError !== "" && packageError}
                        {isLoading && <CircularProgress />}
                    </div>
                </ListItem>
            </List>

            <Paper style={{ padding: theme.spacing(2), marginBottom: theme.spacing(2) }}>
                <div
                    style={{
                        padding: theme.spacing(3, "auto"),
                        position: "relative",
                    }}
                >
                    <Typography
                        variant="h3"
                        style={{ textAlign: "center" }}
                        color={packageInfo?.batchNumber ? "textPrimary" : "textSecondary"}
                    >
                        {`${packageInfo.batchSubject ? packageInfo.batchSubject : "Subject"}`}
                    </Typography>
                </div>
                <Divider style={{ margin: theme.spacing(1, 0) }} />
                <Grid container justifycontent="space-evenly">
                    <Grid item xs={12} md={6}>
                        <DisplayDataCell
                            a="Batch #"
                            b={`${packageInfo.batchNumber ? packageInfo.batchNumber : ""}`}
                            i={1}
                        />
                        <DisplayDataCell
                            a="Batch Subject"
                            b={`${packageInfo.batchSubject ? packageInfo.batchSubject : ""}`}
                            i={2}
                        />
                        <DisplayDataCell a="Order #" b={`${packageInfo.orderId ? packageInfo.orderId : ""}`} i={1} />
                        <DisplayDataCell
                            a="Order Type"
                            b={
                                <span
                                    className={classes.orderType}
                                    style={{
                                        color: `#${packageInfo.orderTypeForegroundColour}`,
                                        backgroundColor: `#${packageInfo.orderTypeBackgroundColour}`,
                                    }}
                                >
                                    {packageInfo.orderType && packageInfo.orderType}
                                </span>
                            }
                            i={2}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DisplayDataCell
                            a="Package #"
                            b={`${
                                packageInfo.packageId
                                    ? `${CreatePackageId(packageInfo.packageType, packageInfo.packageId)}`
                                    : ""
                            }`}
                            i={0}
                        />
                        <DisplayDataCell
                            a="Package Type"
                            b={`${
                                packageInfo.packageType === 1 ? "Kit" : packageInfo.packageType === 2 ? "Detail" : ""
                            }`}
                            i={1}
                        />
                        <DisplayDataCell
                            a="Total Packages"
                            b={`${packageInfo.packageId ? packageInfo.totalKits + packageInfo.totalDetails : ""}`}
                            i={0}
                        />
                        <DisplayDataCell
                            a="Delivery Type"
                            b={`${packageInfo.deliveryType ? packageInfo.deliveryType : ""}`}
                            i={1}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ height: "50px", width: "300px", margin: theme.spacing(2, 0) }}
                    onClick={() => setOpenUserScan(true)}
                    disabled={packageInfo?.readyForFeeder === undefined || !packageInfo?.readyForFeeder}
                >
                    {packageInfo?.scannedBarcode === undefined
                        ? "Scan Package"
                        : packageInfo?.readyForFeeder
                        ? "Mark Package Ready"
                        : "Package Not Ready"}
                </Button>
            </div>

            <Typography variant="overline">Packages in Batch</Typography>
            {packageInfo.packages ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead className={classes.head}>
                            <TableRow>
                                <TableCell>Package</TableCell>
                                <TableCell align="center">Type</TableCell>
                                <TableCell align="center">Products</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Ready for Boxing</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {packageInfo.packages.map((p, i) => (
                                <TableRow key={i}>
                                    <TableCell>{p.packageId}</TableCell>
                                    <TableCell align="center">{p.packageType === 1 ? "Kit" : "Details"}</TableCell>
                                    <TableCell align="center">{p.totalProducts}</TableCell>
                                    <TableCell align="center">{p.status}</TableCell>
                                    <TableCell align="center">
                                        {p.completed ? <DoneIcon color="primary" /> : "Incomplete"}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : packageInfo.packages === undefined ? (
                <Card>
                    <CardContent style={{ textAlign: "center" }}>No bag has been scanned.</CardContent>
                </Card>
            ) : (
                <Card>
                    <CardContent style={{ textAlign: "center" }}>
                        There are no other packages in this order/batch.
                    </CardContent>
                </Card>
            )}

            <Dialog
                open={openUserScan}
                onClose={() => {
                    setOpenUserScan(false);
                    setDisableBarcodeScan(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Confirm by Scanning Employee ID</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        id="barcodeField"
                        className={classes.barcodeScanner}
                        variant="outlined"
                        value={feederId}
                        color="primary"
                        onChange={(e) => handleCheckUser(e)}
                        placeholder="Scan Employee ID"
                        inputProps={{
                            style: {
                                backgroundColor: "white",
                                fontSize: "24px",
                                textAlign: "center",
                                width: "300px",
                            },
                        }}
                        disabled={isLoading}
                    />
                    <div style={{ textAlign: "center", marginTop: theme.spacing(1), color: "red" }}>
                        {errorMessage && errorMessage}
                        {isLoading && <CircularProgress />}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpenUserScan(false);
                            setDisableBarcodeScan(false);

                            setErrorMessage("");
                        }}
                        color="primary"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                // open={isComplete}
                open={packageInfo?.readyForBoxing}
                // open={true}
                onClose={() => {
                    setIsComplete(false);
                    setDisableBarcodeScan(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Batch Complete</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        All packages of this batch has been collected. Please pass batch to the next station.
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">Ready to scan a new package.</DialogContentText>
                </DialogContent>
                <DialogActions style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                        //add endpoint(?) to make that package is complete for batching station view?
                        onClick={handleBatchComplete}
                        color="primary"
                        variant="contained"
                        autoFocus
                        size="large"
                        style={{ height: "50px", width: "300px", margin: theme.spacing(2, 0) }}
                    >
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

import React, { useEffect, useRef, useState } from "react";
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    InputBase,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@material-ui/core";
import Title from "../../../layout/Title";
import settings from "../../../constants/setting";
import SearchIcon from "@material-ui/icons/Search";
// import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";
import CachedIcon from "@material-ui/icons/Cached";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
    search_bar: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        margin: theme.spacing(2, 0),
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    clearSearchIcon: {
        color: "#bdbdbd",
    },
    iconButton: {
        padding: 10,
    },
    search_select: {
        width: "150px",
        fontSize: "20px",
    },
    divider: {
        height: 28,
        margin: 4,
    },
    searchResult: {
        padding: theme.spacing(2),
        margin: theme.spacing(1, 0, 2),
    },
    loading: {},
}));
export default function FloatingInventory() {
    const classes = useStyles();
    const isMounted = useRef(false);
    const theme = useTheme();
    const history = useHistory();
    const [floatingItems, setFloatingItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [search, setSearch] = useState({
        searchBy: "product",
        searchString: "",
    });
    const [openDialog, setOpenDialog] = useState(false);
    const [addToBin, setAddToBin] = useState({
        sku: "",
        toBin: "",
        qty: 0,
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        isMounted.current = true;
        if (isMounted.current) {
            handleAPICall();
        }

        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleSearch = () => {
        handleAPICall();
    };
    // console.log(floatingItems);
    const handleAPICall = () => {
        setLoading(true);
        const token = localStorage.getItem("warehouse-token");
        fetch(settings.api().floating, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (res.status !== 200) {
                    const error = new Error(res.error);
                    throw error;
                }
                return res.json();
            })
            .then((data) => {
                setTimeout(function () {
                    setLoading(false);
                    setFloatingItems(data);
                    setFilteredItems(data);
                }, 1000);
            })
            .catch((err) => console.error(err));
    };

    const handleNumber = (e) => {
        const tmp = parseInt(e.target.value);
        if (isNaN(tmp)) {
            setAddToBin({ ...addToBin, qty: 0 });
        } else {
            setAddToBin({ ...addToBin, qty: tmp });
        }
    };

    // const handleProductToBin = (sku) => {
    //     setOpenDialog(true);
    //     setAddToBin({ ...addToBin, sku });
    // };

    const handleFilter = (e) => {
        const { value } = e.target;
        setSearch({ ...search, searchString: value });
    };

    useEffect(() => {
        if (search.searchBy === "product") {
            setFilteredItems(() =>
                floatingItems.filter((i) =>
                    i.productVariationName.toLowerCase().includes(search.searchString.toLowerCase())
                )
            );
        } else {
            setFilteredItems(() =>
                floatingItems.filter((i) => i.sku.toLowerCase().includes(search.searchString.toLowerCase()))
            );
        }
    }, [search.searchString, floatingItems]);

    const clearSearch = () => {
        if (search.searchString !== "") {
            setSearch({ ...search, searchString: "" });
        }
    };

    const handleRefresh = () => {
        handleAPICall();
    };

    // console.log(floatingItems);
    return (
        <div>
            <Title
                title={"Floating Inventory"}
                description="This is a list of items being moved. This list should be empty that the end of everyday."
            />
            <Paper component="form" className={classes.search_bar}>
                <Tooltip title="Refresh results" placement="top">
                    <IconButton onClick={handleRefresh} className={classes.iconButton} aria-label="search">
                        <CachedIcon style={{ fontSize: "2rem" }} />
                    </IconButton>
                </Tooltip>
                <Divider className={classes.divider} orientation="vertical" />
                <InputBase
                    autoFocus
                    className={classes.input}
                    placeholder="Search by"
                    inputProps={{
                        "aria-label": "search by",
                        style: {
                            fontSize: "20px",
                        },
                    }}
                    value={search.searchString}
                    onChange={(e) => handleFilter(e)}
                />
                <Tooltip title="Clear search" placement="top">
                    <IconButton onClick={clearSearch} className={classes.clearSearchIcon} aria-label="search">
                        <CloseIcon style={{ fontSize: "2rem" }} />
                    </IconButton>
                </Tooltip>
                <Divider className={classes.divider} orientation="vertical" />
                <Tooltip title="Search by" placement="top">
                    <Select
                        disableUnderline
                        variant="standard"
                        className={classes.search_select}
                        value={search.searchBy}
                        onChange={(e) => setSearch({ ...search, searchBy: e.target.value })}
                    >
                        <MenuItem value={"product"}>Product</MenuItem>
                        <MenuItem value={"sku"}>SKU</MenuItem>
                    </Select>
                </Tooltip>
                <Divider className={classes.divider} orientation="vertical" />
                <Tooltip title="Search" placement="top">
                    <IconButton onClick={handleSearch} className={classes.iconButton} aria-label="search">
                        <SearchIcon style={{ fontSize: "2rem" }} />
                    </IconButton>
                </Tooltip>
            </Paper>
            {loading ? (
                <CircularProgress style={{ margin: "auto", display: "block" }} />
            ) : filteredItems.length <= 0 ? (
                <Card>
                    <CardContent style={{ textAlign: "center" }}>There are no items with this search</CardContent>
                </Card>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}>Product Variation</TableCell>
                                <TableCell>Sku</TableCell>
                                <TableCell align="center">Quantity</TableCell>
                                <TableCell align="center">View</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredItems.map((i, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <img
                                            src={i.img ? i.img : "/images/no-image.jpg"}
                                            alt={i.productVariationName}
                                            style={{ width: "65px" }}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = "/images/no-image.jpg";
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>{i.productVariationName}</TableCell>
                                    <TableCell>{i.sku}</TableCell>
                                    <TableCell align="center">{i.quantity}</TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            onClick={() => history.push(`/crm/products/${i.productVariationId}`)}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <Dialog
                open={openDialog}
                className={classes.dialog}
                aria-labelledby="form-dialog-title"
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogTitle id="form-dialog-title">BIN</DialogTitle>
                <DialogContent>
                    <Typography>Scan Bin Barcode</Typography>
                    <TextField autoFocus variant="outlined" margin="dense" fullWidth />

                    <Typography>Quantity Added</Typography>
                    <TextField
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        value={addToBin.qty}
                        onChange={handleNumber}
                    />
                </DialogContent>
                <DialogActions style={{ marginRight: theme.spacing(2) }}>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => setOpenDialog(false)} color="primary" variant="contained">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

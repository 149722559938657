import React from "react";
import Title from "../../../layout/Title";
// import { TextField } from "@material-ui/core";
import ScanInput from "../../Reuseables/ScanInput";

const Release = () => {
    const handleScanInput = () => {};

    return (
        <div>
            <Title subtitle="Outgoing" title="Release Shipment" />
            <ScanInput
                fullWidth={false}
                maxLength={12}
                placeholder="Scan Box/Pallet"
                onChange={(e) => handleScanInput(e)}
            />
        </div>
    );
};

export default Release;

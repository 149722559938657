import React from "react";
import { Button, Grid } from "@material-ui/core";

const NumberPad = (props) => {
    const handleNumberClicked = (n) => {
        props.onNumberClick(n);
    };
    return (
        <div style={{ margin: "20px 0 0" }}>
            <Grid container spacing={1} style={{ width: "220px" }}>
                <Grid item xs={4} align="center">
                    <NumberButton n={7} onClick={(n) => handleNumberClicked(n)} />
                </Grid>
                <Grid item xs={4} align="center">
                    <NumberButton n={8} onClick={(n) => handleNumberClicked(n)} />
                </Grid>
                <Grid item xs={4} align="center">
                    <NumberButton n={9} onClick={(n) => handleNumberClicked(n)} />
                </Grid>
                <Grid item xs={4} align="center">
                    <NumberButton n={4} onClick={(n) => handleNumberClicked(n)} />
                </Grid>
                <Grid item xs={4} align="center">
                    <NumberButton n={5} onClick={(n) => handleNumberClicked(n)} />
                </Grid>
                <Grid item xs={4} align="center">
                    <NumberButton n={6} onClick={(n) => handleNumberClicked(n)} />
                </Grid>
                <Grid item xs={4} align="center">
                    <NumberButton n={1} onClick={(n) => handleNumberClicked(n)} />
                </Grid>
                <Grid item xs={4} align="center">
                    <NumberButton n={2} onClick={(n) => handleNumberClicked(n)} />
                </Grid>
                <Grid item xs={4} align="center">
                    <NumberButton n={3} onClick={(n) => handleNumberClicked(n)} />
                </Grid>
                <Grid item xs={12}>
                    <NumberButton n={0} fullWidth={true} onClick={(n) => handleNumberClicked(n)} />
                </Grid>
            </Grid>
        </div>
    );
};

export default NumberPad;

const NumberButton = ({ n, onClick, fullWidth }) => {
    return (
        <Button
            color="primary"
            variant="contained"
            style={fullWidth ? { width: "100%", height: "60px" } : { width: "60px", height: "60px" }}
            onClick={() => onClick(n)}
        >
            {n}
        </Button>
    );
};

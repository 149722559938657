import React, { useEffect, useState } from "react";
import {
    CircularProgress,
    Divider,
    IconButton,
    InputBase,
    ListItem,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    // TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Title from "../../../layout/Title";
import { useHistory } from "react-router-dom";
import settings from "../../../constants/setting";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
    root: {},
    search_bar: {
        height: "60px",
        padding: "0 4px",
        display: "flex",
        alignItems: "center",
        margin: theme.spacing(2, 0),
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        // fontSize: "2rem",
        padding: "10px",
        // padding: 10,
        // border: "1px solid gray",
    },
    search_select: {
        width: "200px",
        "@media (max-width:900px)": {
            width: "30%",
        },

        fontSize: "20px",
    },
    divider: {
        height: 28,
        margin: 4,
    },
    searchResult: {
        padding: theme.spacing(2),
        margin: theme.spacing(1, 0, 2),
    },
}));

export default function Products() {
    const classes = useStyles();
    const history = useHistory();
    const [search, setSearch] = useState({
        searchBy: "title",
        searchString: "",
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [results, setResults] = useState([]);
    const [
        loading,
        // setLoading
    ] = useState(false);
    // const inputRef = useRef();
    const [productBarcode, setProductBarcode] = useState("");

    useEffect(() => {
        //search.searchBy,
        // console.log(search.searchString);
        if (
            !localStorage.getItem("variation-search-by") &&
            !localStorage.getItem("variation-search-text") &&
            localStorage.getItem("variation-search-text") === ""
        ) {
            console.log("here");
            // handleSearch(false);
            handleSearch(search.searchBy, search.searchString);
        } else {
            // console.log("here", localStorage.getItem("variation-search-by"));
            let tmp = { ...search };
            if (!!localStorage.getItem("variation-search-by")) {
                tmp = {
                    ...search,
                    searchBy: localStorage.getItem("variation-search-by"),
                };
            }
            if (
                !!localStorage.getItem("variation-search-text") &&
                localStorage.getItem("variation-search-text") !== ""
            ) {
                tmp = {
                    ...search,
                    searchString: localStorage.getItem("variation-search-text"),
                };
            }
            // console.log(tmp);
            setSearch({
                ...tmp,
            });
            handleSearch(tmp.searchBy, tmp.searchString);
        }

        return () => {};

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // console.log(search);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSearch = (by, str) => {
        // console.log(search, by, str);
        let url = "";
        // if (s) url = `?${search.searchBy}=${search.searchString}`;
        if (str) url = `?${by}=${str}`;

        // console.log(search.searchString);
        if (search.searchString !== "") localStorage.setItem("variation-search-text", search.searchString);

        console.log(url);
        const token = localStorage.getItem("warehouse-token");
        fetch(settings.api().variations + url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                // console.log(res);
                return res.json();
            })
            .then((data) => {
                setResults(data);
                // console.log(data);
            })
            .catch((e) => console.error(e));
    };
    const handleProductBarcodeScan = (e) => {
        const { value } = e.target;
        setProductBarcode(value);
        if (value.length === 12) {
            // setLoading(true);
            // const token = localStorage.getItem("warehouse-token");
            // fetch(settings.api().product_barcode, {
            //     headers: {
            //         Authorization: `Bearer ${token}`,
            //     },
            // })
            //     .then((res) => {
            //         if (res.status === 200) {
            //             return res.json();
            //         } else {
            //             const e = new Error(res.error);
            //             throw e;
            //         }
            //     })
            //     .then((data) => {
            //         history(`/crm/products/${data.productVariation}`);
            //     })
            //     .catch((e) => console.error(e));
        }
    };

    const handleClearSearch = () => {
        // handleSearch(false);
        // localStorage.removeItem("variation-search-text");
        setSearch({ ...search, searchString: "" });
        // console.log(localStorage.getItem("variation-search-text"));
        if (localStorage.getItem("variation-search-text")) {
            localStorage.removeItem("variation-search-text");
        }
        // handleSearch(search.searchBy, "");
    };
    return (
        <div>
            <Title subtitle="Products" title="Search Warehouse Products" />
            <Paper className={classes.search_bar}>
                <InputBase
                    autoFocus
                    className={classes.input}
                    placeholder="Scan Product"
                    inputProps={{
                        "aria-label": "search product by scanning product barcode",
                        maxLength: 12,
                        style: {
                            fontSize: "20px",
                            textAlign: "center",
                        },
                    }}
                    value={productBarcode}
                    onChange={(e) => handleProductBarcodeScan(e)}
                />
            </Paper>
            {loading && (
                <ListItem>
                    <div style={{ margin: "auto" }}>
                        <CircularProgress />
                    </div>
                </ListItem>
            )}
            <Paper component="form" className={classes.search_bar}>
                <InputBase
                    // autoFocus
                    className={classes.input}
                    placeholder="Search by"
                    inputProps={{
                        "aria-label": "search by",
                        style: {
                            fontSize: "20px",
                        },
                    }}
                    value={search.searchString}
                    onChange={(e) => {
                        // console.log(e.target.value);
                        setSearch({ ...search, searchString: e.target.value });
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            // handleSearch(true);
                            handleSearch(search.searchBy, search.searchString);
                        }
                    }}
                />
                <IconButton
                    onClick={() => {
                        handleClearSearch();
                    }}
                    className={classes.iconButton}
                    aria-label="search"
                >
                    <ClearIcon style={{ fontSize: "2rem" }} />
                </IconButton>
                <Divider className={classes.divider} orientation="vertical" />
                <Select
                    disableUnderline
                    variant="standard"
                    className={classes.search_select}
                    // defaultValue={"title"}
                    value={search.searchBy}
                    onChange={(e) => {
                        setSearch({ ...search, searchBy: e.target.value });
                        localStorage.setItem("variation-search-by", e.target.value);
                    }}
                >
                    <MenuItem value={"title"}>Product Name</MenuItem>
                    <MenuItem value={"id"}>Product ID</MenuItem>
                    <MenuItem value={"sku"}>SKU</MenuItem>
                </Select>
                <Divider className={classes.divider} orientation="vertical" />

                <IconButton
                    onClick={
                        () => handleSearch(search.searchBy, search.searchString)
                        // handleSearch(true)
                    }
                    className={classes.iconButton}
                    aria-label="search"
                >
                    <SearchIcon style={{ fontSize: "2rem" }} />
                </IconButton>
            </Paper>
            <Paper>
                <TableContainer className={classes.puller_list}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}>Product Variation</TableCell>
                                <TableCell align="left">Attributes</TableCell>
                                <TableCell align="left">Variation ID</TableCell>
                                <TableCell align="left">SKU</TableCell>
                                <TableCell>Bins</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((r, i) => {
                                return (
                                    // <TableRow hover key={i} component={Link} to={`/crm/products/${r.variationId}`}>
                                    <TableRow
                                        hover
                                        key={i}
                                        onClick={() => history.push(`/crm/products/${r.variationId}`)}
                                    >
                                        <TableCell align="center">
                                            <img
                                                src={r.thumbnailURL ? r.thumbnailURL : "/images/no-image.jpg"}
                                                alt={r.variation}
                                                style={{ width: "65px" }}
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; // prevents looping
                                                    currentTarget.src = "/images/no-image.jpg";
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>{r.variation}</TableCell>
                                        <TableCell>{r.attributeValues}</TableCell>
                                        <TableCell align="left">{r.variationId}</TableCell>
                                        <TableCell>{r.variationSku}</TableCell>
                                        <TableCell>{r.locations.length === 0 ? "N/A" : r.locations.length}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[20, 50, 100]}
                    component="div"
                    count={results.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

const printer_settings = {
    dev_env: {
        env: "DEV", // PROD or DEV
        api_host_dev: "https://print-server.saphera.com",
        api_host_prod: "",
        api_path: "/api/v1/printing/",
        api_request_header_pagination: "x-pagination",
    },
    api() {
        // Set environment variables
        var host = "";
        if (this.dev_env.env === "DEV") {
            host = this.dev_env.api_host_dev;
        } else {
            host = this.dev_env.api_host_prod;
        }

        const route = host + this.dev_env.api_path;

        // return route;
        return {
            product_variations: route + "variations",
            bag_tag: route + "bag",
        };
    },
};
export default printer_settings;

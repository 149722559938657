import React from "react";
import { Typography, useTheme } from "@material-ui/core";

const DisplayDataCell = ({ a, b, i }) => {
    const theme = useTheme();
    return (
        <div style={{ display: "flex", backgroundColor: i % 2 === 0 ? "#f4f6f9" : null, padding: theme.spacing(1) }}>
            <div style={{ width: "40%" }}>
                <Typography variant="body1" color="textSecondary">
                    {a}
                </Typography>
            </div>
            <div style={{ width: "60%" }}>
                <Typography variant="body1">{b}</Typography>
            </div>
        </div>
    );
};

export default DisplayDataCell;

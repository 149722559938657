import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, AppBar, Toolbar, List, Divider, IconButton, Grid, Typography, Avatar } from "@material-ui/core";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
// import AccountCircle from "@material-ui/icons/AccountCircle";
import SortIcon from "@material-ui/icons/Sort";
const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginRight: drawerWidth,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        // justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    avatar: {
        display: "inline-flex",
        height: "45px",
        width: "45px",
        backgroundColor: "#f5f5f5",
        borderRadius: "50%",
        color: "#577C50",
        margin: theme.spacing(1),
        fontSize: "26px",
    },
    // avatar: {
    //     color: "white",
    //     fontSize: "26px",
    //     marginRight: theme.spacing(3),
    // },
    cap: {
        display: "inline-flex",
        margin: theme.spacing(1),
        // backgroundColor: "#f5f5f5",
        // borderRadius: "10%",
        // color: "#577C50",
        color: "white",
        height: "45px",
        width: "45px",
        fontSize: "26px",
        padding: theme.spacing(0.5),
    },
    score: {
        // backgroundColor: "white",
        // borderRadius: "3px",
        // color: "#577C50",
        fontSize: "36px",
        padding: theme.spacing(0.5, 3),
    },
    border: {
        borderRight: "3px solid white",
    },
    current: {
        backgroundColor: "rgba(84,188,76,0.3)",
    },
    dot: {
        height: "12px",
        width: "12px",
        backgroundColor: "#bbb",
        borderRadius: "50%",
        display: "inline-block",
        margin: "0 10px",
    },
}));

export default function NonManagerHeader(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(props.open);

    const handleDrawer = () => {
        setOpen(!open);
    };

    useEffect(() => {
        props.handleOpen(open);
    }, [open, props]);
    return (
        <div className={classes.root}>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <Grid container>
                        <Grid item sm={3} className={classes.border}>
                            <Grid container alignItems="center">
                                <Grid item>
                                    {/* <Avatar className={classes.avatar}>Q1</Avatar> */}
                                    <span className={classes.cap}>Q1:</span>
                                </Grid>
                                <Grid item>
                                    <span className={classes.score}>407</span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={3} className={classes.border}>
                            <Grid container alignItems="center">
                                <Grid item>
                                    {/* <Avatar className={classes.avatar}>Q2</Avatar> */}
                                    <span className={classes.cap}>Q2:</span>
                                </Grid>
                                <Grid item>
                                    <span className={classes.score}>312</span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={3} className={classes.border}>
                            <Grid container alignItems="center">
                                <Grid item>
                                    {/* <Avatar className={classes.avatar}>Q3</Avatar> */}
                                    <span className={classes.cap}>Q3:</span>
                                </Grid>
                                <Grid item>
                                    <span className={classes.score}>- -</span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={3} className={!open ? classes.border : null}>
                            <Grid container alignItems="center">
                                <Grid item>
                                    {/* <Avatar className={classes.avatar}>Q4</Avatar> */}
                                    <span className={classes.cap}>Q4:</span>
                                </Grid>
                                <Grid item>
                                    <span className={classes.score}>- -</span>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawer}
                        className={clsx(open && classes.hide)}
                    >
                        <SortIcon />
                        {/* <MenuIcon />? */}
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawer}>
                        <ChevronRightIcon />
                    </IconButton>
                    <Typography variant="h6">TOP SCORE</Typography>
                </div>
                <Divider />
                <List>
                    <ListItem>
                        <Grid container>
                            <Grid item>
                                <Avatar style={{ backgroundColor: "#577C50" }}>1</Avatar>
                            </Grid>
                            <Grid item>
                                <p
                                    style={{
                                        lineHeight: 0,
                                        fontSize: "20px",
                                        margin: "10px",
                                    }}
                                >
                                    32
                                </p>
                                <p style={{ fontSize: "16px", margin: "10px" }}>Tammy T.</p>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem>
                        <Grid container>
                            <Grid item>
                                <Avatar style={{ backgroundColor: "#577C50" }}>2</Avatar>
                            </Grid>
                            <Grid item>
                                <p
                                    style={{
                                        lineHeight: 0,
                                        fontSize: "20px",
                                        margin: "10px",
                                    }}
                                >
                                    29
                                </p>
                                <p style={{ fontSize: "16px", margin: "10px" }}>Rain P.</p>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem>
                        <Grid container>
                            <Grid item>
                                <Avatar style={{ backgroundColor: "#577C50" }}>3</Avatar>
                            </Grid>
                            <Grid item>
                                <p
                                    style={{
                                        lineHeight: 0,
                                        fontSize: "20px",
                                        margin: "10px",
                                    }}
                                >
                                    27
                                </p>
                                <p style={{ fontSize: "16px", margin: "10px" }}>Steve A.</p>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem>
                        <Grid container>
                            <Grid item>
                                {/* <AccountCircle style={{ color: "rgba(87,124,80,0.8)", fontSize: 45 }} /> */}
                                <Avatar style={{ backgroundColor: "#577C50" }}>4</Avatar>
                            </Grid>
                            <Grid item>
                                <p
                                    style={{
                                        lineHeight: 0,
                                        fontSize: "20px",
                                        margin: "10px",
                                    }}
                                >
                                    23
                                </p>
                                <p style={{ fontSize: "16px", margin: "10px" }}>Jenna S.</p>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem style={{ margin: "10px 0" }}>
                        <div style={{ margin: "0 auto" }}>
                            <span className={classes.dot} />
                            <span className={classes.dot} />
                            <span className={classes.dot} />
                        </div>
                    </ListItem>
                    <ListItem className={classes.current}>
                        <Grid container alignItems="center">
                            <Grid item>
                                {/* <AccountCircle style={{ color: "rgba(87,124,80,0.8)", fontSize: 45 }} /> */}
                                <Avatar style={{ backgroundColor: "#577C50" }}>9</Avatar>
                            </Grid>
                            <Grid item>
                                <div style={{ marginLeft: "10px" }}>
                                    <p
                                        style={{
                                            lineHeight: 0,
                                            fontSize: "20px",
                                        }}
                                    >
                                        19
                                    </p>
                                    <p
                                        style={{
                                            lineHeight: 0,
                                            fontSize: "16px",
                                        }}
                                    >
                                        Sunny W.
                                    </p>
                                </div>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem style={{ margin: "10px 0" }}>
                        <div style={{ margin: "0 auto" }}>
                            <span className={classes.dot} />
                            <span className={classes.dot} />
                            <span className={classes.dot} />
                        </div>
                    </ListItem>
                </List>
            </Drawer>
        </div>
    );
}

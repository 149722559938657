import React from "react";
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: 0,
    },
    tableRow: {
        "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: "#eceff1",
        },
    },
    card: {
        margin: theme.spacing(3, 0),
    },
}));

export default function SchoolHistory(props) {
    const classes = useStyles();
    const { invoice, purchases: oldPurchases } = props.history;

    return (
        <div>
            <Card>
                <CardHeader title={<Typography variant="subtitle1">School Invoices</Typography>} />
                <Divider />
                <CardContent className={classes.content}>
                    <Table>
                        <TableHead>
                            <TableRow selected>
                                <TableCell>ID</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Payment Method</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invoice.map((i) => (
                                <TableRow key={i.id} hover>
                                    <TableCell>{i.id}</TableCell>
                                    <TableCell>{i.date}</TableCell>
                                    <TableCell>{i.description}</TableCell>
                                    <TableCell>{i.total}</TableCell>
                                    <TableCell>{i.status}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
            <Card className={classes.card}>
                <CardHeader title={<Typography variant="subtitle1">Previous Purchases</Typography>} />
                <Divider />
                <CardContent className={classes.content}>
                    <Table>
                        <TableHead>
                            <TableRow selected>
                                <TableCell>ID</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Payment Method</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {oldPurchases.map((i) => (
                                <TableRow key={i.id} hover>
                                    <TableCell>{i.id}</TableCell>
                                    <TableCell>{i.date}</TableCell>
                                    <TableCell>{i.description}</TableCell>
                                    <TableCell>{i.total}</TableCell>
                                    <TableCell>{i.status}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </div>
    );
}

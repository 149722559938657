import { intersection } from "lodash";

function isArrayWithLength(arr) {
    return Array.isArray(arr) && arr.length;
}

export default function getAllowedRoutes(routes) {
    const roles = JSON.parse(localStorage.getItem("roles"));

    const r = routes.filter(({ permission }) => {
        if (!permission) return true;
        else if (!isArrayWithLength(permission)) return true;
        else return intersection(permission, roles).length;
    });

    return r;
}

export function getAllowedMeunItems(fullMenu) {
    const roles = JSON.parse(localStorage.getItem("roles"));

    const r = fullMenu.filter(({ permission }) => {
        if (!permission) return true;
        else if (!isArrayWithLength(permission)) return true;
        else return intersection(permission, roles).length;
    });

    return r;
}

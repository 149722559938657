import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, TextField, withStyles } from "@material-ui/core";

const LeftButton = withStyles(() => ({
    root: {
        borderRadius: "5px 0px 0px 5px",
    },
}))(Button);
const CenterTextField = withStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderRadius: "0px",
                border: `1px solid ${theme.palette.primary.main}`,
            },
            "&.Mui-focused fieldset": {
                marginTop: "1px",
            },
        },
    },
}))(TextField);
const RightButton = withStyles(() => ({
    root: {
        borderRadius: "0px 5px 5px 0px",
    },
}))(Button);

export default function NumberIncrementDecrement(props) {
    const [value, setValue] = useState(props.value);

    const handleDecrement = () => {
        setValue(value - 1);
    };

    const handleIncrement = () => {
        setValue(value + 1);
    };

    useEffect(() => {
        props.handleValue(value);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleNumber = (e) => {
        const tmp = parseInt(e.target.value);
        if (isNaN(tmp)) {
            setValue(0);
        } else {
            setValue(tmp);
        }
    };

    return (
        <ButtonGroup color="primary">
            {/* {props.indecrementers && ( )} */}
            <LeftButton color="primary" variant="contained" onClick={handleDecrement}>
                -
            </LeftButton>
            <CenterTextField
                autoFocus
                variant="outlined"
                inputProps={{ style: { padding: "10px", textAlign: "center" } }}
                value={value}
                onChange={handleNumber}
            />
            {/* {props.indecrementers && ( )} */}
            <RightButton color="primary" variant="contained" onClick={handleIncrement}>
                +
            </RightButton>
        </ButtonGroup>
    );
}

//   /* <ButtonGroup color="primary" className={classes.qtyBtnGroup}>
//                             <Button variant="contained" onClick={handleDecrement}>
//                                 -
//                             </Button>
//                             <TextField
//                                 autoFocus
//                                 inputProps={{ style: { padding: "10px", textAlign: "center" } }}
//                                 value={receiveQty}
//                                 onChange={(e) => setReceiveQty(parseInt(e.target.value))}
//                             />
//                             <Button variant="contained" onClick={handleIncrement}>
//                                 +
//                             </Button>
//                    </ButtonGroup> */

import React, { useState } from "react";
import {
    TableCell,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TablePagination,
    makeStyles,
    Card,
    CardHeader,
    Divider,
    Avatar,
    // IconButton,
} from "@material-ui/core";
// import VisibilityIcon from "@material-ui/icons/Visibility";
import Title from "../../../../layout/Title";

function createData(name, email, role, status) {
    return { name, email, role, status };
}

const rows = [
    createData("Tarik Fraser", "tarik@fraser.com", "Puller", "On Duty"),
    createData("Osama Shelton", "osama@shelton.com", "Shipper", "On Duty"),
    createData("Drake Preece", "drake@preece.com", "Puller", "On Duty"),
    createData("Amandeep Herman", "amandeep@herman.com", "Manager", "On Duty"),
    createData("Bentley Sheehan", "bentley@sheehan.com", "Boxer"),
    createData("Aisha Shannon", "aisha@shannon.com", "Puller"),
    createData("Alessia Sanders", "alessia@sanders.com", "Printer"),
    createData("Tarik Fraser", "tarik@fraser.com", "Packer"),
    createData("Osama Shelton", "osama@shelton.com", "Shipper"),
    createData("Drake Preece", "drake@preece.com", "Boxer"),
    createData("Amandeep Herman", "amandeep@herman.com", "Manager"),
    createData("Bentley Sheehan", "bentley@sheehan.com", "Shipper"),
    createData("Aisha Shannon", "aisha@shannon.com", "Puller"),
    createData("Alessia Sanders", "alessia@sanders.com", "Shipper"),
    createData("Tarik Fraser", "tarik@fraser.com", "Puller"),
    createData("Osama Shelton", "osama@shelton.com", "packer"),
    createData("Drake Preece", "drake@preece.com", "Puller"),
    createData("Amandeep Herman", "amandeep@herman.com", "Manager"),
    createData("Bentley Sheehan", "bentley@sheehan.com", "Shipper"),
    createData("Aisha Shannon", "aisha@shannon.com", "Printer"),
    createData("Alessia Sanders", "alessia@sanders.com", "Printer"),
];

const useStyles = makeStyles((theme) => ({
    spacer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    nonactive: {
        // backgroundColor: "#eeeeee",
    },
    nameCell: {
        display: "flex",
        alignItems: "center",
    },
    avatar: {
        height: 42,
        width: 42,
        backgroundColor: theme.palette.primary.main,
        marginRight: theme.spacing(1),
    },
}));

export default function Staff(props) {
    const classes = useStyles();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div>
            <Title title="Warehouse Staff" description="You can change your avatar by editing your account below." />
            {/* <h1>Warehouse Staff</h1>
            <p>You can change your avatar by editing your account below.</p> */}

            <Card>
                <CardHeader title="All Staff"></CardHeader>
                <Divider />
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Status</TableCell>
                                {/* <TableCell align="center">Details</TableCell> */}
                                {/* <TableCell></TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((staff) => (
                                <TableRow
                                    hover
                                    key={staff.name}
                                    className={staff.status !== "On Duty" ? classes.nonactive : null}
                                    onClick={() => {
                                        props.history.push("/crm/staff/1");
                                    }}
                                >
                                    <TableCell>
                                        <div className={classes.nameCell}>
                                            <Avatar
                                                className={classes.avatar}
                                                // alt={`Avatar n°${value + 1}`}
                                                // src={`/static/images/avatar/${value + 1}.jpg`}
                                            />
                                            {staff.name}
                                        </div>
                                    </TableCell>
                                    <TableCell>{staff.email}</TableCell>
                                    <TableCell>{staff.role}</TableCell>
                                    <TableCell>{staff.status}</TableCell>
                                    {/* <TableCell align="center">
                                        <IconButton
                                            onClick={() => {
                                                props.history.push("/crm/staff/1");
                                            }}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                    </TableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 15, 50]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Card>
            <div className={classes.spacer} />
        </div>
    );
}

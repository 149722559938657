import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, IconButton, makeStyles } from "@material-ui/core";
// import { Card, CardContent, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import Title from "../../../layout/Title";
// import NumberPad from "../../Reuseables/NumberPad";
import settings from "../../../constants/setting";
import clsx from "clsx";
import useTimer from "../../Reuseables/Timer/useTimer";
import FormatTimer from "../../Reuseables/Timer/Timer";
import RefreshIcon from "@material-ui/icons/Refresh";

const useStyles = makeStyles((theme) => ({
    ready: {
        // backgroundColor: "#c7f0cf",
        // color: "#579756",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    almost: {
        backgroundColor: "#d4e157",
        color: "#000",
    },
    allClear: {
        backgroundColor: "#c7f0cf",
        color: "#579756",
    },
    cellSize: {
        width: "75px",
        height: "65px",
        border: "2px black solid",
        textAlign: "center",
    },

    header: {
        backgroundColor: "#ffec9d",
    },
}));

const Batch2Go = () => {
    const classes = useStyles();
    const [stations, setStations] = useState({});
    const { seconds, startTimer, stopTimer, setSecondsHook } = useTimer();
    const [loading, setLoading] = useState(false);

    const isReady = (i, need) => {
        const result = i / need;
        if (result === 1) {
            //ready
            return 1;
        } else if (result >= 0.6) {
            //almost ready
            return 2;
        } else {
            //not ready
            return 3;
        }
    };

    useEffect(() => {
        refreshData();
        // getStationsData();
        // setSecondsHook(180);

        // getStationsData();
        startTimer();

        return () => {
            stopTimer();
        };
        // setInterval(getStationsData, 30000);
    }, []);

    useEffect(() => {
        if (seconds === 0) {
            refreshData();
        }
        return () => {
            stopTimer();
        };
    }, [seconds]);

    const refreshData = () => {
        setLoading(true);
        stopTimer();
        setSecondsHook(180);
        getStationsData();
        startTimer();
    };
    // console.log(seconds);

    const getStationsData = () => {
        const token = localStorage.getItem("warehouse-token");
        fetch(settings.api().batch2go, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                // console.log(res);
                return res.json();
            })
            .then((data) => {
                // console.log(data);
                setStations(data);
                setLoading(false);
            })
            .catch((error) => console.log(error));
    };

    return (
        <div>
            {/* <Title title={"Batch2Go"} description="Batches waiting to be complete." /> */}
            {loading ? (
                <CircularProgress style={{ margin: "150px auto", display: "block" }} />
            ) : (
                <table>
                    <tbody>
                        {stations.subjects &&
                            stations.subjects.map((s, i) => (
                                <tr key={i}>
                                    <th className={clsx(classes.cellSize, classes.header)}>{s.subject}</th>
                                    {s.batches.length > 0 ? (
                                        s.batches.map((b) => (
                                            <td
                                                key={i}
                                                className={clsx(
                                                    classes.cellSize,
                                                    b.status === 0 ? null : classes.ready
                                                )}
                                            >
                                                <div>{b.batchId}</div>
                                                <div>
                                                    <strong>{b.packagesFinished + "/" + b.packagesTotal}</strong>
                                                </div>
                                                <div>{b.status === 0 ? "WAITING" : "BOXING"}</div>
                                            </td>
                                        ))
                                    ) : (
                                        <td className={clsx(classes.cellSize, classes.allClear)}>ALL CLEAR</td>
                                    )}
                                </tr>
                            ))}
                    </tbody>
                </table>
            )}
            <Button
                variant="contained"
                color="primary"
                style={{ marginTop: "10px", width: "160px", height: "60px" }}
                startIcon={<RefreshIcon />}
                onClick={() => refreshData()}
            >
                {FormatTimer(seconds)}
            </Button>
        </div>
    );
};

export default Batch2Go;

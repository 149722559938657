import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Icon,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
} from "@material-ui/core";
import Roles from "../../constants/Roles";
import { getAllowedMeunItems } from "../../helpers/getAllowedRoutes";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    logout: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.primary.light,
        },
        marginTop: theme.spacing(4),
    },
    nested: {
        paddingLeft: theme.spacing(6),
    },
}));

const Menu = ({ open, ...props }) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const isMounted = useRef(false);
    const [logout, setLogout] = useState(false);
    // const [section, setSection] = useState(1);

    const [allowedMenu, setAllowedMenu] = useState([]);
    const [selectedPath, setSelectedPath] = useState("");

    useEffect(() => {
        isMounted.current = true;
        isMounted.current && setSelectedPath(history.location.pathname);

        // const tmp = getAllowedMeunItems(items);
        setAllowedMenu(getAllowedMeunItems(items));

        return () => {
            isMounted.current = false;
        };
    }, [history]);

    const handleLogout = (event) => {
        event.preventDefault();
        localStorage.clear();
        history.push("/");
    };

    const handleListItemClick = (path) => {
        setSelectedPath(path);
        history.push(path);
    };
    let section = 1;

    useEffect(() => {
        const currentPath = location.pathname;
        // console.log(currentPath);
        setSelectedPath(currentPath);
    }, [location.pathname]);

    return (
        <div>
            <List>
                {allowedMenu.map((m, i) => {
                    let tmp = null;
                    if (section !== m.section) {
                        tmp = (
                            <div key={m.path}>
                                <Divider />
                                <ListItem
                                    // key={m.path}
                                    selected={selectedPath === m.path}
                                    onClick={() => handleListItemClick(m.path, i)}
                                    button
                                >
                                    <ListItemIcon>
                                        <Icon>{m.icon}</Icon>
                                    </ListItemIcon>
                                    <ListItemText primary={m.label} />
                                </ListItem>
                            </div>
                        );
                        section++;
                    } else {
                        tmp = (
                            <ListItem
                                key={m.path}
                                selected={selectedPath === m.path}
                                onClick={() => handleListItemClick(m.path, i)}
                                button
                            >
                                <ListItemIcon>
                                    <Icon>{m.icon}</Icon>
                                </ListItemIcon>
                                <ListItemText primary={m.label} />
                            </ListItem>
                        );
                    }

                    return tmp;
                })}
            </List>
            {open && (
                <ListItem button onClick={() => setLogout(true)} className={classes.logout}>
                    <ListItemIcon>{/*<Icon></Icon> */}</ListItemIcon>
                    <ListItemText primary="LOGOUT" />
                </ListItem>
            )}
            <Dialog
                open={logout}
                onClose={() => setLogout(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Logout</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to logout?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setLogout(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleLogout} color="primary" variant="contained">
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

// export default Menu;
const mapStateToProps = (state) => {
    return {
        open: state.drawerOpen,
    };
};

export default connect(mapStateToProps)(Menu);

const items = [
    {
        icon: "dashboard",
        path: "/crm",
        label: "Dashboard",
        // permission: [Roles.SALES_REP, Roles.WCRM],
        section: 1,
    },
    {
        icon: "shopping_cart",
        path: "/crm/packages",
        label: "Puller",
        permission: [Roles.WCRM],
        section: 2,
    },
    // {
    //     // icon: "local_shipping",
    //     icon: "archive",
    //     path: "/crm/packing",
    //     label: "Packing",
    //     permission: [Roles.WCRM],
    //     section: 2,
    // },
    {
        icon: "bubble_chart",
        path: "/crm/sorting",
        label: "Feeder",
        permission: [Roles.WCRM],
        section: 2,
    },

    {
        icon: "bar_chart",
        // icon: "inventory",
        path: "/crm/floor-inventory",
        label: "Floor Inventory",
        permission: [Roles.SALES_REP, Roles.WCRM],
        section: 2,
    },
    {
        // icon: "dashboard_customize",
        icon: "add_box",
        path: "/crm/stocker",
        label: "Stocker",
        permission: [Roles.SALES_REP, Roles.WCRM],
        section: 2,
    },
    {
        icon: "find_in_page",
        path: "/crm/purchase-orders",
        label: "Receiver",
        permission: [Roles.SALES_REP, Roles.WCRM],
        section: 2,
    },
    {
        icon: "people",
        path: "/crm/staff",
        label: "Staff",
        permission: [Roles.SALES_REP],
        section: 3,
    },
    {
        icon: "multiline_chart",
        path: "/crm/report",
        label: "Report",
        permission: [Roles.SALES_REP],
        section: 3,
    },
    {
        icon: "school",
        path: "/crm/schools",
        label: "Schools",
        permission: [Roles.SALES_REP],
        section: 4,
    },
    {
        icon: "map",
        path: "/crm/map",
        label: "Map",
        permission: [Roles.SALES_REP],
        section: 5,
    },
    {
        icon: "settings_system_daydream",
        path: "/crm/floating-inventory",
        label: "Floating Inventory",
        permission: [Roles.WCRM],
        section: 5,
    },
    // {
    //     icon: "work",
    //     path: "/crm/pickups",
    //     label: "Pickup Order",
    //     permission: [Roles.WCRM],
    //     section: 6,
    // },
    {
        icon: "broken_image",
        path: "/crm/unaccounted",
        label: "Unaccounted Items",
        permission: [Roles.WCRM, Roles.SALES_REP],
        section: 7,
    },
    {
        icon: "inbox",
        path: "/crm/boxing",
        label: "Release Box",
        permission: [Roles.WCRM],
        section: 8,
    },
    // {
    //     icon: "view_module",
    //     path: "/crm/pallet",
    //     label: "Release Pallet",
    //     permission: [Roles.WCRM],
    //     section: 8,
    // },
    // {
    //     icon: "local_shipping",
    //     path: "/crm/release",
    //     label: "Release",
    //     permission: [Roles.WCRM],
    //     section: 8,
    // },
    {
        icon: "search",
        path: "/crm/products",
        label: "Products",
        permission: [Roles.WCRM, Roles.SALES_REP],
        section: 8,
    },
    {
        icon: "view_module",
        path: "/crm/pallets",
        label: "Pallets",
        permission: [Roles.WCRM, Roles.SALES_REP],
        section: 8,
    },
    {
        icon: "drag_indicator",
        path: "/crm/batch2go",
        label: "Batch2Go",
        permission: [Roles.WCRM, Roles.SALES_REP],
        section: 8,
    },
    // {
    //     icon: "account_box",
    //     path: "/crm/user",
    //     label: "Scan User",
    //     permission: [Roles.SALES_REP, Roles.WCRM],
    //     section: 6,
    // },
];

export function CreatePackageId(type, id) {
    let pId = "";
    let digits = 0;
    if (type === 1) {
        pId = "PK";
    } else {
        pId = "PD";
    }

    if (id === 0) {
        digits = 1;
    } else {
        digits = Math.floor(Math.log10(Math.abs(id))) + 1;
    }
    let add0s = 10 - digits;

    while (add0s !== 0) {
        pId += "0";
        add0s--;
    }

    pId += id;

    return pId;
}

import React, { useState } from "react";
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    // Checkbox,
    CssBaseline,
    Dialog,
    DialogContent,
    // FormControlLabel,
    Grid,
    Paper,
    TextField,
    Typography,
    // Link,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import { RandomSignonBackground } from "../helpers/RandomBackgrounds";
import CopyRight from "../components/CopyRight";

import settings from "../constants/setting";
import CropFreeIcon from "@material-ui/icons/CropFree";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
    },
    image: {
        backgroundImage: "url(" + RandomSignonBackground() + ")",
        backgroundRepeat: "no-repeat",
        backgroundColor: theme.palette.grey[50],
        backgroundSize: "cover",
        backgroundPosition: "left",
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.icon,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    barcode: {
        marginTop: theme.spacing(8),
        backgroundColor: theme.palette.icon,
    },
}));

export default function Login(props) {
    const classes = useStyles();
    const [credentials, setCredentials] = useState({
        email: "",
        password: "",
    });
    const [barcode, setBarcode] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (event) => {
        const { value, name } = event.target;
        setCredentials({
            ...credentials,
            [name]: value,
        });
    };

    const onSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);

        fetch(settings.api().authenticate, {
            method: "POST",
            body: JSON.stringify(credentials),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                // console.log(res);
                if (res.status === 200) {
                    return res.json();
                } else {
                    const error = new Error(res.error);
                    throw error;
                }
            })
            .then((data) => {
                // console.log(data);
                localStorage.setItem("warehouse-token", data.token);
                return getInitSignon(data.token);
            })
            .catch((err) => {
                setIsLoading(false);
                console.error(err);
                alert("Error logging in please try again");
            });
    };

    const getInitSignon = (token) => {
        fetch(settings.api().init_signon, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                // console.log(res);
                if (res.status === 200) {
                    return res.json();
                } else {
                    const error = new Error(res.error);
                    throw error;
                }
            })
            .then((data) => {
                localStorage.setItem("roles", JSON.stringify(data.roles));
                localStorage.setItem("user", JSON.stringify(data));
                setIsLoading(false);

                props.history.push("/crm");
            })
            .catch((err) => {
                setIsLoading(false);
                console.error(err);
            });
    };

    const handleBarcodeScan = (e) => {
        e.preventDefault();
        const { value } = e.target;

        setBarcode(value);
        if (value.length === 13) {
            setIsLoading(true);
            const data = JSON.stringify({
                email: value,
                password: process.env.REACT_APP_LOGIN_PASS,
            });
            fetch(settings.api().authenticate, {
                method: "POST",
                body: data,
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    // console.log(res);
                    if (res.status === 200) {
                        return res.json();
                    } else {
                        const error = new Error(res.error);
                        throw error;
                    }
                })
                .then((data) => {
                    // console.log(data);
                    localStorage.setItem("warehouse-token", data.token);
                    return getInitSignon(data.token);
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.error(error);
                    alert("Error logging in please try again");
                    setBarcode("");
                });
        }
    };

    return (
        <>
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOpenIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Email Sign in
                        </Typography>
                        <form className={classes.form} noValidate onSubmit={onSubmit}>
                            <TextField
                                variant="outlined"
                                type="email"
                                name="email"
                                margin="normal"
                                fullWidth
                                label="Enter email"
                                autoComplete="email"
                                value={credentials.email}
                                required
                                onChange={handleInputChange}
                                id="email"
                            />
                            <TextField
                                variant="outlined"
                                type="password"
                                name="password"
                                margin="normal"
                                fullWidth
                                label="Enter password"
                                autoComplete="current-password"
                                value={credentials.password}
                                onChange={handleInputChange}
                                required
                                id="password"
                            />
                            {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" /> */}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                value="submit"
                                disabled={isLoading}
                            >
                                Sign In
                            </Button>
                        </form>

                        <Avatar className={classes.barcode}>
                            <CropFreeIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Employee ID Sign in
                        </Typography>
                        <TextField
                            variant="outlined"
                            name="barcode"
                            margin="normal"
                            fullWidth
                            label="Scan Employee ID"
                            autoFocus
                            value={barcode}
                            onChange={handleBarcodeScan}
                            disabled={isLoading}
                        />
                        <Box mt={5}>
                            <CopyRight />
                        </Box>
                    </div>
                </Grid>
            </Grid>
            <Dialog
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                    },
                }}
                open={isLoading}
                aria-labelledby="loading"
                aria-describedby="loading"
            >
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            </Dialog>
        </>
    );
}

import React from "react";
import { Provider } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core";

import componentsStore from "../components/Portal/redux/componentsStore";
import theme from "../theme/index";
import AppRouter from "../routes/AppRouter";

const store = componentsStore();

function App() {
    return (
        <div>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <AppRouter />
                </ThemeProvider>
            </Provider>
        </div>
    );
}

export default App;

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    // Card,
    // CardContent,
    Divider,
    Grid,
    makeStyles,
    Paper,
    // Table,
    // TableBody,
    // TableCell,
    // TableContainer,
    // TableHead,
    // TableRow,
    Typography,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Title from "../../../layout/Title";
import ScanInput from "../../Reuseables/ScanInput";
import DisplayDataCell from "../../Reuseables/DisplayDataCell";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import settings from "../../../constants/setting";
import { formatDate } from "../../Reuseables/Functions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
    infoCard: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    box_id: {
        margin: theme.spacing(2, 0, 1),
    },
    divider: {
        margin: theme.spacing(0, 0, 3),
    },
    btns: {
        margin: theme.spacing(2, 0),
    },
    container: {
        display: "flex",
        alignItems: "stretch",
    },
    testing: {
        [theme.breakpoints.up("xs")]: {
            marginTop: theme.spacing(1),
        },
        [theme.breakpoints.up("md")]: {
            marginTop: theme.spacing(0),
            paddingLeft: theme.spacing(1),
        },
    },
    datepicker: {},
}));

export default function BoxInfo() {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const matches = useMediaQuery("(min-width:960px)");
    const [boxInfo, setBoxInfo] = useState({});
    const [openRelease, setOpenRelease] = useState(false);

    const [releasedDate, setReleasedDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [boxBarcode, setBoxBarcode] = useState("");

    useEffect(() => {
        setReleasedDate(new Date());

        return () => {};
    }, []);

    const handleReleasedBox = () => {
        const token = localStorage.getItem("warehouse-token");
        const data = JSON.stringify({ releasedDate: releasedDate });
        console.log(data);
        fetch(settings.api().boxes + `/${boxInfo.id}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: data,
        }).then((res) => {
            console.log(res);
            if (res.status === 200) {
                setOpenRelease(false);
                setReleasedDate(null);
                history.go(0);
            }
            // return res.json();
        });
    };

    const handleDateChange = (date) => {
        setReleasedDate(date);
    };

    const getBox = (v) => {
        setIsLoading(true);
        // console.log(boxBarcode);
        const token = localStorage.getItem("warehouse-token");
        fetch(settings.api().boxes + `/${v}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                console.log(res);
                return res.json();
            })
            .then((data) => {
                console.log(data);
                setBoxInfo(data);
                setIsLoading(false);
                setBoxBarcode("");
            });
    };

    const handleScanBox = (value) => {
        // const value = e.target;
        console.log(value);
        setBoxBarcode(value);
        if (value.length === 12 && value[0] === "B" && value[1] === "X") {
            getBox(value);
        }
    };
    // console.log(boxInfo, boxInfo?.releaseDateUtc);
    return (
        <div>
            <Title subtitle="SHIPPING" title="Box Info" />
            <ScanInput
                fullWidth={false}
                maxLength={12}
                placeholder="Scan Box"
                value={boxBarcode}
                onChange={(e) => handleScanBox(e)}
            />
            <div style={{ display: "flex" }}>
                <div style={{ margin: "auto" }}>{isLoading && <CircularProgress />}</div>
            </div>
            <Paper className={classes.infoCard}>
                <div>
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                        Box Information
                    </Typography>
                    <Divider />
                    {/* {boxInfo.id && ( */}
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <DisplayDataCell a="Box ID" b={boxInfo?.id} i={1} />
                            <DisplayDataCell a="Batch ID" b={boxInfo?.batchId} i={2} />
                            <DisplayDataCell a="Weight" b={boxInfo?.weight} i={1} />
                            <DisplayDataCell
                                a="Release Date"
                                b={
                                    boxInfo?.releaseDateUtc && boxInfo?.releaseDateUtc !== "0001-01-01T00:00:00"
                                        ? formatDate(boxInfo?.releaseDateUtc)
                                        : ""
                                }
                                i={2}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DisplayDataCell a="Purolator PIN" b={boxInfo?.purolatorPIN} i={2} />
                            <DisplayDataCell a="Created" b={boxInfo?.id && formatDate(boxInfo?.createdUtc)} i={1} />
                            <DisplayDataCell
                                a="Packages"
                                b={boxInfo?.id && parseInt(boxInfo?.totalKits) + parseInt(boxInfo?.totalDetails)}
                                i={2}
                            />
                            <DisplayDataCell
                                a="Released"
                                b={boxInfo?.released ? formatDate(boxInfo?.dateReleased) : ""}
                                i={1}
                            />
                        </Grid>
                    </Grid>
                    {/* )} */}
                </div>
            </Paper>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ height: "50px", width: "300px", margin: theme.spacing(2, 0) }}
                    onClick={() => setOpenRelease(true)}
                    disabled={boxInfo?.id === undefined}
                >
                    Release Box
                </Button>
            </div>
            {/* <Typography variant="overline">Packages</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead className={classes.head}>
                        <TableRow>
                            <TableCell>Package ID</TableCell>
                            <TableCell>Products</TableCell>
                            <TableCell align="center">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {boxInfo?.kits &&
                            boxInfo?.kits.map((m) => (
                                <TableRow>
                                    <TableCell>{m.orderKitId}</TableCell>
                                    <TableCell>14</TableCell>
                                    <TableCell align="center">Boxed</TableCell>
                                </TableRow>
                            ))}
                        {boxInfo?.details &&
                            boxInfo?.details.map((m) => (
                                <TableRow>
                                    <TableCell>{m.orderDetailId}</TableCell>
                                    <TableCell>14</TableCell>
                                    <TableCell align="center">Boxed</TableCell>
                                </TableRow>
                            ))}
                        {boxInfo?.id && boxInfo?.details.length === 0 && boxInfo?.kits.length === 0 && (
                            <TableRow>No Packages</TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer> */}
            <Dialog open={openRelease} onClose={() => setOpenRelease(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Release Box</DialogTitle>
                <DialogContent>
                    <DialogContentText>Please set date the box is released.</DialogContentText>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            className={classes.spacing}
                            fullWidth
                            disablePast={true}
                            required={true}
                            inputVariant="outlined"
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Date of Release"
                            value={releasedDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                "aria-label": "change box release date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenRelease(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleReleasedBox} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Title from "../../../layout/Title";
import Item from "../../Reuseables/CustomGrid";
import { formatNumber } from "../../Reuseables/Functions";
import StyledTableCell from "../../Reuseables/StyledTableCell";
import ClearIcon from "@material-ui/icons/Clear";
import settings from "../../../constants/setting";
import { useParams } from "react-router";
import { Alert } from "@mui/material";
// import SlideButton from "react-slide-button";
import CloseIcon from "@mui/icons-material/Close";
import printer_settings from "../../../constants/printingAPISetting";
import { update } from "lodash";

const useStyles = makeStyles((theme) => ({
    buttonGroup: {
        "@media (min-width:960px)": {
            position: "absolute",
            bottom: "16px",
        },
    },
}));

const Product = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { id } = useParams();
    const [product, setProduct] = useState({});
    const [linkProductBinOpen, setLinkProductBinOpen] = useState(false);
    const [unlinkProductBinOpen, setUnlinkProductBinOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [scannedBarcode, setScannedBarcode] = useState("");
    const [timeToGet, setTimeToGet] = useState(0);
    const [binError, setBinError] = useState({ hasError: false, errorMsg: "", validBin: false, validBinId: null });
    const [linkError, setLinkError] = useState({ error: false, errorMsg: "", value: 0 });
    const [token] = useState(localStorage.getItem("warehouse-token"));
    const [scannedUnlinkBin, setScannedUnlinkBin] = useState("");
    const [updateWeight, setUpdateWeight] = useState({
        open: false,
        value: "",
    });

    const [printLabelDialog, setPrintLabelDialog] = useState(false);
    // const [reset, setReset] = useState(0);

    useEffect(() => {
        getProductVariationData();
        // console.log(id);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (scannedBarcode.length === 12 && scannedBarcode[0] === "B" && scannedBarcode[1] === "C") {
            getProductBinData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scannedBarcode]);

    const getProductVariationData = () => {
        fetch(settings.api().variations + `/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                // console.log(res);
                return res.json();
            })
            .then((data) => {
                setProduct(data);
                setIsLoading(false);
                // console.log(data);
            })
            .catch((err) => console.error(err));
    };

    const getProductBinData = () => {
        fetch(settings.api().barcodes + `/${scannedBarcode}/bins`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                // console.log(res);
                return res.json();
            })
            .then((data) => {
                // console.log(data);
                if (data.error) {
                    setBinError({ hasError: data.error, errorMsg: data.errorMsg });
                } else {
                    setBinError({ hasError: false, errorMsg: "", validBin: true, validBinId: data.binId });
                }
            })
            .catch((err) => console.error(err));
    };

    const handleLinkProductToBin = () => {
        let url = `/${binError.validBinId}/variation/${product.variationId}/link`;

        if (timeToGet > 0) {
            url = `/${binError.validBinId}/variation/${product.variationId}/link?timetoget=${timeToGet}`;
        }
        // fetch(settings.api().floor_bins + `/${binError.validBinId}/variations/${product.variationId}/link`, {
        fetch(settings.api().floor_bins + url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: "POST",
            // body: data,
        })
            .then((res) => {
                console.log(res);
                return res.json();
            })
            .then((data) => {
                console.log(data);
                if (data.error) {
                    setLinkError({ error: data.error, errorMsg: data.errorMsg, value: 0 });
                } else {
                    window.location.reload();
                }
            })
            .catch((err) => {
                console.error(err);
                setLinkError(err.value);
            });
    };

    const handleProductLabelPrint = () => {
        // const url = `https://print-server.saphera.com/api/v1/printing/variation?variationid=${id}`;
        // const url = `https://print-server.saphera.com/api/v1/printing/variations/${id}`;
        const url = printer_settings.api().product_variations + "/" + id;

        fetch(url, {
            method: "POST",
        })
            .then((res) => {
                console.log(res);
                if (res.status !== 200) {
                    const err = new Error(res.error);
                    throw err;
                }
                return res.json();
            })
            .then((data) => console.log(data))
            .catch((error) => console.error(error));

        setPrintLabelDialog(false);
    };

    const handleUpdateWeight = () => {};

    return (
        <div>
            {isLoading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CircularProgress />
                </div>
            ) : (
                <div>
                    <Title subtitle="Products" title="Product Variation" />
                    {/* <TextField
                        variant="outlined"
                        value={testPrintString}
                        onChange={(e) => setTestPrintString(e.target.value)}
                    /> */}
                    <Grid
                        container
                        spacing={1}
                        justify="space-between"
                        alignItems={"stretch"}
                        style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(3) }}
                    >
                        <Item
                            item
                            xs={12}
                            sm={12}
                            md={"auto"}
                            align="center"
                            content={
                                <img
                                    className={classes.image}
                                    src={product.thumbUrl ? product.thumbUrl : "/images/no-image.jpg"}
                                    alt="product"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = "/images/no-image.jpg";
                                    }}
                                />
                            }
                        />
                        <Item
                            style={{ position: "relative" }}
                            item
                            xs={12}
                            md
                            content={
                                <div>
                                    <Grid container justify="space-between">
                                        <Grid item xs>
                                            {/* <div style={{ marginBottom: theme.spacing(1) }}>
                                                <Typography variant="body1" display="inline" color="textSecondary">
                                                    Variation ID{" "}
                                                </Typography>
                                                <Typography display="inline" variant="h6">
                                                    {product.variationId ? product.variationId : "Variation"}
                                                </Typography>
                                            </div> */}
                                            <Typography variant="h5" style={{ marginBottom: theme.spacing(1) }}>
                                                <strong>
                                                    {product.variationName ? product.variationName : "Variation Name"}
                                                </strong>
                                            </Typography>
                                            <Typography variant="body1" style={{ marginBottom: theme.spacing(1) }}>
                                                <strong>
                                                    {product.attributeValues ? product.attributeValues : "Attributes"}
                                                </strong>
                                            </Typography>
                                            <div style={{ marginBottom: theme.spacing(1) }}>
                                                <Typography variant="body1" color="textSecondary" display="inline">
                                                    Product{" "}
                                                </Typography>
                                                <Typography display="inline" variant="body1">
                                                    {product.productName && product.productName} [{product.productId}]
                                                </Typography>
                                            </div>
                                            <div style={{ marginBottom: theme.spacing(1) }}>
                                                <Typography variant="body1" color="textSecondary" display="inline">
                                                    SKU{" "}
                                                </Typography>
                                                <Typography display="inline" variant="body1">
                                                    {product.productSku && product.productSku}
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography variant="body1" display="inline" color="textSecondary">
                                                    Var. ID{" "}
                                                </Typography>
                                                <Typography display="inline" variant="body1">
                                                    {product.variationId && product.variationId}
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container justify="space-evenly" spacing={2} className={classes.buttonGroup}>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <Button
                                                disabled={!product.variationId}
                                                fullWidth
                                                variant="outlined"
                                                color="primary"
                                                // onClick={() => setLinkProductBinOpen(true)}
                                                onClick={() => setUpdateWeight({ ...updateWeight, open: true })}
                                            >
                                                UPDATE WEIGHT
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <Button
                                                disabled={!product.variationId}
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                onClick={() => setLinkProductBinOpen(true)}
                                            >
                                                Link with Bin
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <Button
                                                disabled={!product.variationId}
                                                fullWidth
                                                variant="outlined"
                                                color="primary"
                                                // onClick={() => setLinkProductBinOpen(true)}
                                                onClick={() => setPrintLabelDialog(true)}
                                            >
                                                Print Label
                                            </Button>
                                        </Grid>

                                        {/* <Grid item xs={12} sm={6} md={4}>
                                            <Button
                                                // disabled={!product.productBin}
                                                fullWidth
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => setUnlinkProductBinOpen(true)}
                                            >
                                                Unlink from Bin
                                            </Button>
                                        </Grid> */}
                                    </Grid>
                                </div>
                            }
                        />
                    </Grid>
                    <Grid container spacing={3} style={{ marginBottom: theme.spacing(2) }}>
                        <Grid item xs={6} md={3}>
                            <Card align="center">
                                <CardContent style={{ padding: theme.spacing(1, 0) }}>
                                    <span>Total Inventory</span>
                                    <h3 className={classes.cardHeader}>
                                        {product.totalInventory ? formatNumber(product.totalInventory) : 0}
                                    </h3>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Card align="center">
                                <CardContent style={{ padding: theme.spacing(1, 0) }}>
                                    <span>Floating Inventory</span>
                                    <h3 className={classes.cardHeader}>
                                        {product.totalFloating ? formatNumber(product.totalFloating) : 0}
                                    </h3>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Card align="center">
                                <CardContent style={{ padding: theme.spacing(1, 0) }}>
                                    <span>Needed on Deck</span>
                                    <h3 className={classes.cardHeader}>
                                        {product.totalProductsOnDeck ? formatNumber(product.totalProductsOnDeck) : 0}
                                    </h3>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Card align="center">
                                <CardContent style={{ padding: theme.spacing(1, 0) }}>
                                    <span>Total in Orders</span>
                                    <h3 className={classes.cardHeader}>
                                        {product.totalProductsOrdered ? formatNumber(product.totalProductsOrdered) : 0}
                                    </h3>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Typography style={{ margin: "30px 0 20px" }} variant="overline">
                        All Locations
                    </Typography>
                    {product.bins.length > 0 ? (
                        <TableContainer component={Paper}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Location</StyledTableCell>
                                        <StyledTableCell align="center">Level</StyledTableCell>
                                        <StyledTableCell align="center">Rack</StyledTableCell>
                                        <StyledTableCell align="center">Shelf</StyledTableCell>
                                        <StyledTableCell align="center">Bin</StyledTableCell>
                                        <StyledTableCell align="center">Bin Inventory</StyledTableCell>
                                        <StyledTableCell align="center">Time to Get (min)</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {product.bins.map((b, i) => (
                                        <TableRow key={i}>
                                            <TableCell>{b.location}</TableCell>
                                            <TableCell align="center">{b.levelName}</TableCell>
                                            <TableCell align="center">{b.rack}</TableCell>
                                            <TableCell align="center">{b.shelf}</TableCell>
                                            <TableCell align="center">{b.bin}</TableCell>
                                            <TableCell align="center">{b.quantity}</TableCell>
                                            <TableCell align="center">{b.timeToGet}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Card>
                            <CardContent style={{ textAlign: "center" }}>No Locations Available</CardContent>
                        </Card>
                    )}

                    {/* <div style={{ margin: "20px 0 0" }}>
                        <Typography variant="overline">Other Variations</Typography>
                        {product.otherVariations.length > 0 ? (
                            <TableContainer component={Paper}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Location</StyledTableCell>
                                            <StyledTableCell>Variation Name</StyledTableCell>
                                            <StyledTableCell align="center">Bin Inventory</StyledTableCell>
                                            <StyledTableCell align="center">Units in Var.</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {product.otherVariations.map((b, i) => (
                                            <TableRow
                                                key={i}
                                                className={
                                                    product?.productBin?.binBarcode === b.binBarcode
                                                        ? classes.onItem
                                                        : undefined
                                                    // barcode === b.binBarcode ? classes.onItem : undefined
                                                }
                                            >
                                                <TableCell>{b.location}</TableCell>
                                                <TableCell>{b.variationName}</TableCell>
                                                <TableCell align="center">{b.quantity}</TableCell>
                                                <TableCell align="center">{b.itemsInVariation}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <Card>
                                <CardContent style={{ textAlign: "center" }}>No Other Variations Available</CardContent>
                            </Card>
                        )}
                    </div> */}
                    <Dialog open={updateWeight.open}>
                        <DialogTitle>Update Product Weight</DialogTitle>

                        <DialogContent dividers>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <TextField
                                    label="Enter product weigth"
                                    variant="outlined"
                                    value={updateWeight.value}
                                    onChange={(e) => setUpdateWeight({ ...updateWeight, value: e.target.value })}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={(e) => setUpdateWeight({ open: false, value: "" })}
                                color="primary"
                                variant="outlined"
                            >
                                Cancel
                            </Button>
                            <Button onClick={handleUpdateWeight} color="primary" variant="contained">
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={printLabelDialog}>
                        <DialogTitle>
                            <Grid container alignItems="center" justify="space-between">
                                <Grid item>Print Product Label</Grid>
                                <Grid item>
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => {
                                            setPrintLabelDialog(false);
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>

                        <DialogContent dividers>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}></div>
                            <Button
                                onClick={handleProductLabelPrint}
                                color="primary"
                                variant="contained"
                                size="large"
                                style={{ width: "250px", height: "60px" }}
                            >
                                Print
                            </Button>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={linkProductBinOpen}>
                        <DialogTitle>Link Product to Bin</DialogTitle>
                        <DialogContent dividers>
                            <Typography style={{ marginBottom: "10px" }}>
                                Adding <strong>{product.variationName}</strong> to:
                            </Typography>
                            <TextField
                                id="barcodeField"
                                autoFocus
                                variant="outlined"
                                value={scannedBarcode}
                                onChange={(e) => setScannedBarcode(e.target.value)}
                                fullWidth
                                placeholder="Scan Bin Barcore"
                                inputProps={{
                                    maxLength: 12,
                                    style: {
                                        textAlign: "center",
                                        backgroundColor: "white",
                                    },
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setScannedBarcode("")}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Typography style={{ marginTop: "20px", marginBottom: "10px" }}>
                                Time to get (minutes):
                            </Typography>
                            <TextField
                                id="barcodeField"
                                variant="outlined"
                                value={timeToGet}
                                onChange={(e) => setTimeToGet(e.target.value)}
                                fullWidth
                                placeholder="Time to get item (mins)"
                                type="number"
                                inputProps={{
                                    style: {
                                        textAlign: "center",
                                        backgroundColor: "white",
                                    },
                                }}
                            />
                            {binError.hasError && (
                                <Alert severity="error" style={{ marginTop: "20px" }}>
                                    {binError.errorMsg}
                                </Alert>
                            )}
                            {linkError.error && (
                                <Alert severity="error" style={{ marginTop: "20px" }}>
                                    {linkError.errorMsg}
                                </Alert>
                            )}
                        </DialogContent>
                        <DialogActions style={{ justify: "space-between" }}>
                            <Button
                                onClick={() => {
                                    setLinkProductBinOpen(false);
                                    setBinError({ hasError: false, errorMsg: "" });
                                    setLinkError({ error: false, errorMsg: "", value: 0 });
                                    setScannedBarcode("");
                                }}
                                color="primary"
                                variant="outlined"
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={!binError.validBin}
                                onClick={handleLinkProductToBin}
                                color="primary"
                                variant="contained"
                            >
                                Link
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={unlinkProductBinOpen}>
                        <DialogTitle>Unlink Product from Bin</DialogTitle>
                        <DialogContent dividers>
                            <Typography style={{ marginBottom: "10px" }}>
                                Remove <strong>{product.variationName}</strong> from:
                            </Typography>
                            <TextField
                                id="barcodeField"
                                autoFocus
                                variant="outlined"
                                value={scannedUnlinkBin}
                                onChange={(e) => setScannedUnlinkBin(e.target.value)}
                                fullWidth
                                placeholder="Scan Bin Barcore"
                                inputProps={{
                                    maxLength: 12,
                                    style: {
                                        textAlign: "center",
                                        backgroundColor: "white",
                                    },
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setScannedUnlinkBin("")}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            {binError.hasError && (
                                <Alert severity="error" style={{ marginTop: "20px" }}>
                                    {binError.errorMsg}
                                </Alert>
                            )}
                            {linkError.error && (
                                <Alert severity="error" style={{ marginTop: "20px" }}>
                                    {linkError.errorMsg}
                                </Alert>
                            )}
                        </DialogContent>
                        <DialogActions style={{ justify: "space-between" }}>
                            <Button
                                onClick={() => {
                                    setUnlinkProductBinOpen(false);
                                    setScannedUnlinkBin("");
                                }}
                                color="primary"
                                variant="outlined"
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={true}
                                onClick={handleLinkProductToBin}
                                color="primary"
                                variant="contained"
                            >
                                Unlink
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}
        </div>
    );
};

export default Product;

import React, { useEffect, useRef, useState } from "react";
import { Avatar, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import Title from "../../../layout/Title";
import settings from "../../../constants/setting";
import { CreatePackageId } from "../../../helpers/createPackageId";

const useStyles = makeStyles((theme) => ({
    container: {
        margin: theme.spacing(0, 4),
    },
    staffIcon: { margin: theme.spacing(1, 0) },
    icon: {
        // color: "#EAE9D6",
        //rgba(87,124,80,0.8)
        color: "white",
        borderRadius: "50%",
        width: theme.spacing(8),
        height: theme.spacing(8),
        border: "none",
    },
    // icon: {
    //     // color: "#EAE9D6",
    //     //rgba(87,124,80,0.8)
    //     color: "white",
    //     backgroundColor: "#3a8435",
    //     borderRadius: "50%",
    //     width: theme.spacing(8),
    //     height: theme.spacing(8),
    //     border: "none",
    // },
}));

export default function SelectPuller(props) {
    const classes = useStyles();
    const isMounted = useRef(false);
    const [pullersList, setPullersList] = useState([]);
    const [selectedPuller, setSelectedPuller] = useState("");
    const [orderPackage, setOrderPackage] = useState({});

    useEffect(() => {
        isMounted.current = true;

        if (isMounted.current) {
            const obj = props.history.location.state;
            // const obj = JSON.parse(localStorage.getItem("current_package"));
            setOrderPackage(obj);
        }

        const token = localStorage.getItem("warehouse-token");
        fetch(settings.api().pickers, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    localStorage.clear();
                    props.history.push("/");
                } else {
                    const error = new Error(res.error);
                    throw error;
                }
            })
            .then((data) => {
                isMounted.current && setPullersList(data);
            })
            .catch((err) => {
                console.error(err);
            });
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const handleSelectPuller = (e, puller) => {
    //     setSelectedPuller(puller.displayName);
    //     setTimeout(() => {
    //     props.history.push({
    //         pathname: "/crm/package/picklist",
    //         // state: orderPackage
    //     });
    //     }, 1000);
    // };
    const handleSelectPuller = (e, puller) => {
        // props.history.push("/crm/package/picklist");
        // history.push("/crm/package/picklist");
        setSelectedPuller(puller.displayName);

        props.history.push({
            pathname: "/crm/package/picklist",
            search: `?package=${CreatePackageId(orderPackage.packageType, orderPackage.packageId)}`,
            state: {
                // orderId: orderPackage.orderId,
                packageId: orderPackage.packageId,
                packageType: orderPackage.packageType,
                puller,
            },
            // state: {
            //     orderPackage,
            //puller
            // },
        });
        // console.log(puller);
    };

    // console.log(orderPackage);

    return (
        <div>
            <Title subtitle="Fulfillment" title="Select Puller" />
            <div>
                <Typography variant="body1" color="textSecondary" display="inline">
                    Order#{" "}
                </Typography>
                <Typography variant="body1" display="inline">
                    {orderPackage.orderId}
                </Typography>
            </div>
            <div>
                <Typography variant="h6" color="textSecondary" display="inline">
                    Package#{" "}
                </Typography>
                <Typography variant="h6" display="inline">
                    {orderPackage.packageType && CreatePackageId(orderPackage.packageType, orderPackage.packageId)}
                </Typography>
            </div>
            {/* <div>
                <Typography variant="body1" color="textSecondary" display="inline">
                    Total Product{" "}
                </Typography>
                <Typography variant="body1" display="inline">
                    {orderPackage.products && orderPackage.products.length}
                </Typography>
            </div> */}
            {/* {orderPackage.packageId && (
                <Card
                    style={{
                        padding: theme.spacing(2),
                        marginBottom: theme.spacing(2),
                    }}
                >
                    <OrderDetails orderPackage={orderPackage} />
                </Card>
            )} */}

            <Grid container>
                {pullersList.length > 0 &&
                    pullersList.map((s) => (
                        <Grid key={s.pickerId} item sm={3} align="center" className={classes.staffIcon}>
                            <Avatar
                                component={Button}
                                className={classes.icon}
                                style={{
                                    // backgroundColor: selectedPuller === s.firstName ? "#54BC4C" : `#${s.backColour}`,
                                    backgroundColor: selectedPuller === s.firstName ? "#54BC4C" : "#3a8435",

                                    // color: `#${s.foreColour}`,
                                    color: "#ffffff",
                                }}
                                // src="/"
                                onClick={(e) => handleSelectPuller(e, s)}
                            >
                                <Typography variant="h6">{s.firstName.charAt(0)}</Typography>
                            </Avatar>
                            <Typography variant="subtitle1">
                                {s.firstName} {s.lastName.charAt(0)}.
                            </Typography>
                        </Grid>
                    ))}
            </Grid>
        </div>
    );
}

import React, { useEffect, useRef, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import OrderDetails from "./OrderDetails";
import ListItem from "./ListItem";
import { CreatePackageId } from "../../../helpers/createPackageId";
import settings from "../../../constants/setting";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
    },
    orderDetails: {
        padding: theme.spacing(2),
    },
    accordian: {
        marginTop: theme.spacing(2),
    },
    accordianDetails: {
        backgroundColor: "#eeeeee",
        height: "55vh",
        overflowY: "auto",
        padding: "0",
    },
    disabled: { opacity: 0.4 },
    checkbox: { margin: 0, padding: 0 },
    btn: {
        margin: "30px 0",
    },
    orderType: {
        padding: theme.spacing(0.25, 6),
        fontSize: "18px",
        color: "white",
        backgroundColor: "#FF8040",
    },
}));

export default function PrepList(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [expanded, setExpanded] = useState("panel1");
    const [done, setDone] = useState(false);
    const isMounted = useRef(false);
    const [orderPackage, setOrderPackage] = useState({});
    const [prepper, setPrepper] = useState([]);
    const [puller, setPuller] = useState([]);
    const [completePackage, setCompletePackage] = useState(false);

    const handleChange = (panel) => (e, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        isMounted.current = true;
        if (isMounted.current) {
            const obj = props.history.location.state;
            setOrderPackage(obj);
            setPrepper(obj.products.filter((d) => d.prepperProduct));
            setPuller(obj.products.filter((d) => !d.prepperProduct));
        }
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleItemStatus = (item, s, collectedQuantity) => {
        let tmp = {
            quantityPicked: collectedQuantity,
            collected: s,
        };
        if (s === false) {
            tmp = {
                quantityPicked: collectedQuantity,
                collected: s,
                error: false,
                errorMsg: "",
            };
        }

        setPrepper(prepper.map((i) => (item.packageProductId === i.packageProductId ? Object.assign(item, tmp) : i)));
    };

    const handleItemStatusMissing = (item, s, message, collectedQuantity) => {
        setPrepper(
            prepper.map((i) =>
                item.packageProductId === i.packageProductId
                    ? {
                          ...item,
                          quantityPicked: collectedQuantity,
                          collected: false,
                          error: s,
                          errorMsg: message,
                      }
                    : i
            )
        );
    };

    useEffect(() => {
        if (prepper.filter((i) => i.collected || i.error).length === prepper.length || orderPackage.prepperComplete) {
            setDone(true);
        } else {
            setDone(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prepper]);

    const handleFinishPrep = () => {
        // console.log((prepper.filter((i) => i.collected || i.error).length === prepper.length && puller.length) !== 0);
        if (
            (prepper.filter((i) => i.collected || i.error).length === prepper.length && puller.length !== 0) ||
            orderPackage.prepperComplete
        ) {
            const token = localStorage.getItem("warehouse-token");
            let url = orderPackage.packageType === 1 ? settings.api().prep_kit_post : settings.api().prep_detail_post;
            url = url + `/${orderPackage.packageId}`;
            let preplistDone = prepper.map((p) => {
                const bin = p.locations.find((l) => l.levelId === 1);

                p = {
                    ...p,
                    productBinId: bin ? bin.productBinId : null,
                };
                delete p.productSku;
                delete p.productName;
                delete p.quantityNeeded;
                delete p.quantityAvailable;
                delete p.quantityWarning;
                delete p.prepperProduct;
                delete p.thmbUrl;
                delete p.imgUrl;
                delete p.locations;
                delete p.collected;

                return p;
            });

            const postObj = {
                orderId: orderPackage.orderId,
                packageId: orderPackage.packageId,
                packageType: orderPackage.packageType,
                products: preplistDone,
            };
            console.log(url, JSON.stringify(postObj));
            fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                method: "POST",
                body: JSON.stringify(postObj),
            })
                .then((res) => {
                    if (res.status === 200) {
                        props.history.push({
                            pathname: "/crm/package/selectpuller",
                            search: `?package=${CreatePackageId(orderPackage.packageType, orderPackage.packageId)}`,
                            state: orderPackage,
                        });
                        return res.json();
                    } else if (res.status === 401) {
                        localStorage.clear();
                        props.history.push("/ ");
                    } else {
                        const error = new Error(res.error);
                        console.error(error);
                        return res.json();
                    }
                })
                .then((data) => {
                    console.log(data);
                });
        } else {
            setCompletePackage(true);
        }
    };

    return (
        <div className={classes.root}>
            {orderPackage.packageId && (
                <Card
                    style={{
                        padding: theme.spacing(2),
                        marginBottom: theme.spacing(2),
                    }}
                >
                    <OrderDetails orderPackage={orderPackage} />
                </Card>
            )}
            <Alert variant="filled" severity="warning">
                An item in this order has low quantity
            </Alert>
            {orderPackage.products && (
                <Accordion
                    className={classes.accordian}
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="Prep-List" id="prep-list">
                        <Grid container justify="space-between">
                            <Grid item sm={6}>
                                <Typography variant="subtitle1" display="inline">
                                    Prepper Items
                                </Typography>
                            </Grid>
                            <Grid item sm={6} align="right">
                                {done ? (
                                    <Button variant="contained" color="primary" onClick={handleFinishPrep}>
                                        Done
                                    </Button>
                                ) : (
                                    <Typography variant="subtitle1" align="right" display="inline">
                                        {/* Items Fulfilled: {prepList.filter((i) => i.status !== "-").length}/ */}
                                        Items Fulfilled: {prepper.filter((i) => i.collected || i.error).length}/
                                        {prepper.length}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails
                        className={clsx(
                            classes.accordianDetails,
                            orderPackage.prepperComplete ? classes.disabled : undefined
                        )}
                    >
                        <Grid container>
                            <Grid container>
                                {prepper.length !== 0 ? (
                                    prepper.map((p) => (
                                        <Grid item lg={3} md={4} sm={6} xs={12} key={p.packageProductId}>
                                            <ListItem
                                                disabled={orderPackage.prepperComplete}
                                                handleStatus={(item, status, collectedQuantity) =>
                                                    handleItemStatus(item, status, collectedQuantity)
                                                }
                                                handleStatusMissing={(i, s, m, c) =>
                                                    handleItemStatusMissing(i, s, m, c)
                                                }
                                                itemDetails={p}
                                            />
                                        </Grid>
                                    ))
                                ) : (
                                    <div style={{ margin: "auto" }}>
                                        <Typography variant="subtitle1" align="center">
                                            There are no items for the puller to fulfill.
                                        </Typography>
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            )}
            <Accordion className={classes.accordian} expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography className={classes.heading}>Puller Items</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordianDetails}>
                    {puller.length !== 0 ? (
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>SKU</TableCell>
                                    <TableCell>Product</TableCell>
                                    <TableCell align="center">Quantity</TableCell>
                                    <TableCell align="center">Available Quantity</TableCell>
                                    <TableCell align="center">Location</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {puller.map((i) => (
                                    <TableRow key={i.packageProductId}>
                                        <TableCell>{i.productSku}</TableCell>
                                        <TableCell>{i.productName}</TableCell>
                                        <TableCell align="center">{i.quantityNeeded}</TableCell>
                                        <TableCell align="center">{i.quantityAvailable}</TableCell>

                                        <TableCell align="center">
                                            {i.locations &&
                                                i.locations.map((l, index) =>
                                                    index + 1 !== i.locations.length ? `${l.location}, ` : l.location
                                                )}
                                        </TableCell>
                                        <TableCell align="center" padding="none">
                                            {i.collected ? "collected" : "-"}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <div style={{ margin: "auto" }}>
                            <Typography variant="subtitle1" align="center">
                                There are no items for the puller to fulfill.
                            </Typography>
                        </div>
                    )}
                </AccordionDetails>
            </Accordion>
            <Dialog
                open={completePackage}
                onClose={() => setCompletePackage(!completePackage)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Package Complete</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This package has been completed. There are no puller items.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.history.push("/crm/package")} color="primary" autoFocus>
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import "./Map.css";
import { Grid, makeStyles, Typography } from "@material-ui/core";
// import settings from "../../../settings";
// import MarkerClusterer from "@googlemaps/markerclustererplus";
import { useTheme } from "@material-ui/core/styles";
import Title from "../../../layout/Title";

const useStyles = makeStyles((theme) => ({
    legend: {
        background: "#fff",
        padding: theme.spacing(1),
        margin: theme.spacing(2, 0),
    },
    legendTitle: {
        margin: theme.spacing(0),
    },
    icon: {
        marginRight: "8px",
    },
    listItem: {
        padding: 0,
    },
    radio: {
        padding: theme.spacing(0, 2),
    },
    nested: {
        backgroundColor: "#eeeeee",
        paddingLeft: theme.spacing(4),
    },
}));

export default function GoogleMap() {
    const isMounted = useRef(false);
    const classes = useStyles();
    const mapRef = useRef();
    const mapsRef = useRef();

    const [idle, setIdle] = useState(0);
    const [finishLoading, setFinishLoading] = useState(false);
    const [mapDefaults] =
        // , setMapDefaults]
        useState({
            zoom: 13,
            center: { lat: 51.014226280648025, lng: -114.03348454676633 }, // school start location
        });
    const [schools] =
        // , setSchools]
        useState([]);
    const [markers, setMarkers] = useState(null);
    const theme = useTheme();

    // var styles = [
    //     {
    //         width: 30,
    //         height: 30,
    //         className: "custom-clustericon-1",
    //     },
    // ];

    useEffect(() => {
        isMounted.current = true;

        const script = document.createElement("script");
        script.src =
            "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js";
        // script.async = true;
        isMounted.current && document.body.appendChild(script);

        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleMapChange(bounds) {
        setFinishLoading(false);

        if (idle) {
            clearTimeout(idle);
        }
        setIdle(
            setTimeout(() => {
                if (markers) {
                    markers.clearMarkers();
                    setMarkers(null);
                }
                // const token = localStorage.getItem("warehouse-token");
                // const boundsString = `?neLat=${bounds.ne.lat}&neLng=${bounds.ne.lng}&swLat=${bounds.sw.lat}&swLng=${bounds.sw.lng}`;

                // fetch(settings.api().mapping_schools + boundsString, {
                //     headers: {
                //         Authorization: `Bearer ${token}`,
                //     },
                // })
                //     .then((res) => {
                //         if (res.ok) {
                //             return res.json();
                //         } else {
                //             const error = new Error(res.error);
                //             throw error;
                //         }
                //     })
                //     .then((data) => {
                //         setSchools([...data.schools]);
                //         const finish1 = createMarkers(data.schools);
                //         // const finish2 = createZones(data.zones);

                //         if (finish1) {
                //             // && finish2)
                //             setFinishLoading(true);
                //         }
                //     })
                //     .catch((err) => {
                //         console.error(err);
                //     });
                setFinishLoading(true);
            }, 2300)
        );
    }

    // function createMarkers(list) {
    //     const markers = list.map((s, i) => {
    //         const marker = new mapsRef.current.Marker({
    //             position: { lat: s.latitude, lng: s.longitude },
    //             icon: createMarkerIcon(s.pinColourHex),
    //         });
    //         const content = `<p>ID: ${s.schoolId}</p><p>${s.schoolName}</p><p>${s.schoolAddress}</p>`;
    //         const infowindow = new mapsRef.current.InfoWindow({
    //             content: content,
    //         });
    //         mapsRef.current.event.addListener(
    //             marker,
    //             "click",
    //             (function (marker, content, infowindow) {
    //                 return function () {
    //                     infowindow.setContent(content);
    //                     infowindow.open(mapRef.current, marker);
    //                 };
    //             })(marker, content, infowindow)
    //         );
    //         return marker;
    //     });
    //     try {
    //         const markerClusterer = new MarkerClusterer(mapRef.current, markers, {
    //             styles: styles,
    //             clusterClass: "custom-clustericon",
    //         });
    //         setMarkers(markerClusterer);
    //         return true;
    //     } catch (error) {
    //         console.error(error);
    //         return false;
    //     }
    // }

    // function createMarkerIcon(color) {
    //     // customize icon
    //     var pinSVGHole =
    //         "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z";
    //     // var pinSVGFilled =
    //     //     "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z";
    //     return {
    //         path: pinSVGHole,
    //         anchor: new mapsRef.current.Point(12, 17),
    //         fillOpacity: 1,
    //         fillColor: `#${color}`,
    //         strokeWeight: 1,
    //         strokeColor: "#424242",
    //         scale: 1.6,
    //         labelOrigin: new mapsRef.current.Point(12, 9),
    //     };
    // }

    return (
        <div>
            <Title
                title="Map View"
                description="This page give you a visual image of all SchoolStart schools on a map. You can drap the map around to location schools."
            />

            <Grid container style={{ marginTop: theme.spacing(2) }}>
                <Grid item xs={6}>
                    <Typography className={classes.title} variant="body1">
                        Schools in View: {schools.length}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    {finishLoading ? (
                        <Typography variant="subtitle1" align="right">
                            Done!
                        </Typography>
                    ) : (
                        <Typography variant="subtitle1" align="right">
                            Loading . . .
                        </Typography>
                    )}
                </Grid>
            </Grid>

            <div style={{ height: "70vh", width: "100%" }}>
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: process.env.REACT_APP_GOOGLE_MAP_KEY,
                    }}
                    defaultCenter={mapDefaults.center}
                    defaultZoom={mapDefaults.zoom}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => {
                        mapRef.current = map;
                        mapsRef.current = maps;
                    }}
                    onChange={({ bounds }) => handleMapChange(bounds)}
                ></GoogleMapReact>
            </div>
        </div>
    );
}

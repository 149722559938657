import React, { useEffect, useState } from "react";
import Title from "../../../layout/Title";
import ScanInput from "../../Reuseables/ScanInput";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    makeStyles,
    useTheme,
} from "@material-ui/core";
import DisplayDataCell from "../../Reuseables/DisplayDataCell";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import settings from "../../../constants/setting";
import { formatDate } from "../../Reuseables/Functions";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
    infoCard: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

const Pallet = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const params = useParams();
    const [openRelease, setOpenRelease] = useState(false);
    const [releaseDate, setReleaseDate] = useState(null);
    const [palletInfo, setPalletInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [palletBarcode, setPalletBarcode] = useState("");

    useEffect(() => {
        setReleaseDate(new Date());
        getPalletInfo(params.id);
        // console.log(params.id);

        return () => {};
    }, []);

    const handleSetDateReleased = () => {
        const token = localStorage.getItem("warehouse-token");
        const data = JSON.stringify({ releasedDate: releaseDate });
        console.log(data);
        fetch(settings.api().pallets + `/${params.id}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: data,
        }).then((res) => {
            console.log(res);
            if (res.status === 200) {
                setOpenRelease(false);
                setReleaseDate(null);
                history.go(0);
            }
            // return res.json();
        });
        // .then((data) => {
        //     console.log(data);
        // });
    };

    const handleDateChange = (date) => {
        console.log(date.toJSON());
        setReleaseDate(date.toJSON());
    };
    const linkPalletToBin = () => {};

    const getPalletInfo = (id) => {
        setIsLoading(true);
        const token = localStorage.getItem("warehouse-token");
        fetch(settings.api().pallets + `/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                console.log(res);
                return res.json();
            })
            .then((data) => {
                // console.log(data);
                setPalletInfo(data);
                setIsLoading(false);
            });
    };

    const handlePalletBarcode = (e) => {
        const value = e.target;
        setPalletBarcode(value);
    };

    useEffect(() => {
        if (palletBarcode.length === 12) {
            getPalletInfo();
        }
    }, []);

    return (
        <div>
            <Title subtitle="Pallet" title="Pallet Info" />

            <ScanInput
                fullWidth={false}
                maxLength={12}
                placeholder="Scan Pallet"
                value={palletBarcode}
                onChange={handlePalletBarcode}
            />
            <div style={{ display: "flex" }}>
                <div style={{ margin: "auto" }}>{isLoading && <CircularProgress />}</div>
            </div>
            <Paper className={classes.infoCard}>
                <div>
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                        Pallet Information
                    </Typography>
                    <Divider />
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <DisplayDataCell a="Pallet ID" b={palletInfo.palletId} i={1} />
                            <DisplayDataCell a="Batches" b={"5"} i={2} />
                            <DisplayDataCell a="On Deck" b={palletInfo.onDeck > 0 ? "On Deck" : ""} i={1} />
                            <DisplayDataCell a="Location" b={""} i={2} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DisplayDataCell a="Boxes" b={palletInfo.totalBoxes} i={2} />
                            <DisplayDataCell
                                a="Date of Release"
                                b={palletInfo.releaseDateUtc && formatDate(palletInfo.releaseDateUtc)}
                                i={1}
                            />
                            <DisplayDataCell a="Full" b={palletInfo.full ? "FULL" : "-"} i={2} />
                            <DisplayDataCell
                                a="Released Date"
                                b={palletInfo.dateReleasedUtc && formatDate(palletInfo.dateReleasedUtc)}
                                i={1}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Paper>
            {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ height: "50px", width: "300px", margin: theme.spacing(2, 0) }}
                    onClick={() => setOpenRelease(true)}
                    disabled={palletInfo?.palletId == null}
                >
                    Release Pallet
                </Button>
            </div> */}
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Button
                    variant="outlined"
                    color="primary"
                    style={{ height: "50px", width: "300px", margin: theme.spacing(2, 0) }}
                    onClick={linkPalletToBin}
                    disabled={palletInfo?.palletId == null}
                >
                    Link to Bin
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ height: "50px", width: "300px", margin: theme.spacing(2, 0) }}
                    onClick={() => setOpenRelease(true)}
                    disabled={palletInfo?.palletId == null || palletInfo?.shipped}
                >
                    Release Pallet
                </Button>
            </div>
            <Typography variant="overline">Boxes</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead className={classes.head}>
                        <TableRow>
                            <TableCell>Box ID</TableCell>
                            <TableCell>Batch ID</TableCell>
                            <TableCell align="center">Date Created</TableCell>
                            <TableCell align="center">Carrier Pin</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {palletInfo?.boxes?.length > 0 ? (
                            palletInfo.boxes.map((b) => (
                                <TableRow>
                                    <TableCell>{b.boxID}</TableCell>
                                    <TableCell>{b.batchId}</TableCell>
                                    <TableCell align="center">{formatDate(b.createdUtc)}</TableCell>
                                    <TableCell align="center">{b.pin}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={5} align="center">
                                    There are no boxes on this pallet.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={openRelease} onClose={() => setOpenRelease(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Release Pallet</DialogTitle>
                <DialogContent>
                    <DialogContentText>Please set date the pallet is released.</DialogContentText>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            className={classes.spacing}
                            fullWidth
                            required={true}
                            inputVariant="outlined"
                            disablePast={true}
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Date of Release"
                            value={releaseDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                "aria-label": "change pallet release date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenRelease(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSetDateReleased} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Pallet;

import { format } from "date-fns";

const formatNumber = (num) => {
    if (num) return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    else return num;
};

const formatDate = (date) => {
    const d = new Date(date);
    return format(d, "dd-MMM-yyyy");
};

export { formatNumber, formatDate };

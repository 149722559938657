const FormatTimer = (seconds) => {
    const getSeconds = `0${seconds % 60}`.slice(-2);

    const minutes = `${Math.floor(seconds / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);

    const hours = `${Math.floor(minutes / 60)}`;
    const getHours = `0${hours % 60}`.slice(-2);

    if (seconds > 3599) {
        return `${getHours}: ${getMinutes}: ${getSeconds}`;
    }
    return `${getMinutes}: ${getSeconds}`;
};

export default FormatTimer;

import React, { useState } from "react";
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    // CardHeader,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Title from "../../../../layout/Title";
// import Autocomplete from "@material-ui/lab/Autocomplete";

// import { Prompt } from "react-router";

const useStyles = makeStyles((theme) => ({
    backBtn: {
        marginBottom: theme.spacing(3),
    },
    content: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        textAlgin: "center",
    },
    name: {
        marginTop: theme.spacing(1),
        textAlign: "center",
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        height: 100,
        width: 100,
        marginBottom: theme.spacing(2),
    },
    removeBotton: {
        width: "100%",
    },
    subTitle: {
        // margin: theme.spacing(3),
    },
    textFieldSpacing: {
        marginTop: 15,
        marginBottom: 15,
    },
    select: {
        marginTop: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const Staffer = (props) => {
    const classes = useStyles();

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        setEmployee({
            ...employee,
            [name]: value,
        });
    };

    const [employee, setEmployee] = useState({
        first: "Levi",
        last: "Ackerman",
        position: "Puller",
        email: "leviackerman@email.com",
        phone: "9876543210",
        address: "",
        address2: "",
        city: "",
        province: "AB",
        postal: "",
    });

    return (
        <div>
            <Title subtitle="Staff" title={`${employee.first} ${employee.last}`} />
            <Button
                className={classes.backBtn}
                variant="outlined"
                color="primary"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                    props.history.push("/crm/staff");
                }}
            >
                All Staff
            </Button>

            <Grid container spacing={2}>
                <Grid item md={4} xl={3} xs={12}>
                    <Card className={classes.container}>
                        <CardContent className={classes.content}>
                            <Avatar className={classes.avatar} src="/" />
                            <Typography className={classes.name} gutterBottom variant="body1">
                                {employee.first} {employee.last}
                            </Typography>
                            <Typography color="textSecondary" variant="body1">
                                {employee.position}
                            </Typography>
                        </CardContent>
                        <Divider />
                        <CardActions>
                            <Button className={classes.removeBotton} variant="text">
                                Edit picture
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item md={8} xl={9} xs={12}>
                    <Card className={classes.container}>
                        {/* <CardHeader title="Employee" />
                        <Divider /> */}
                        <CardContent>
                            <form>
                                <div className={classes.subTitle}>
                                    <Typography variant="overline">Overview</Typography>
                                    <Divider className={classes.divider} />
                                    <div className={classes.formContainer}>
                                        <Grid container>
                                            <Grid item xs>
                                                <TextField
                                                    className={classes.textFieldSpacing}
                                                    fullWidth
                                                    label="First Name"
                                                    variant="outlined"
                                                    value={employee.first}
                                                    name="first"
                                                    onChange={handleFormDataChange}
                                                />
                                            </Grid>

                                            <Grid item xs>
                                                <TextField
                                                    className={classes.textFieldSpacing}
                                                    fullWidth
                                                    label="Last Name"
                                                    variant="outlined"
                                                    value={employee.last}
                                                    name="last"
                                                    onChange={handleFormDataChange}
                                                />
                                            </Grid>

                                            <TextField
                                                className={classes.textFieldSpacing}
                                                fullWidth
                                                label="Job Position"
                                                variant="outlined"
                                                name="position"
                                                value={employee.position}
                                                onChange={handleFormDataChange}
                                            />
                                        </Grid>
                                    </div>

                                    {/* </form> */}
                                </div>

                                <div className={classes.subTitle}>
                                    <Typography variant="overline">Contact Information</Typography>
                                    <Divider className={classes.divider} />
                                    {/* <form className={classes.formContainer}> */}
                                    <div className={classes.formContainer}>
                                        <TextField
                                            className={classes.textFieldSpacing}
                                            fullWidth
                                            label="Email"
                                            variant="outlined"
                                            name="email"
                                            value={employee.email}
                                            onChange={handleFormDataChange}
                                        />
                                        <TextField
                                            className={classes.textFieldSpacing}
                                            fullWidth
                                            label="Phone"
                                            variant="outlined"
                                            name="phoneCell"
                                            value={employee.phone}
                                            onChange={handleFormDataChange}
                                        />
                                        <Grid container spacing={1}>
                                            <Grid item xs>
                                                <TextField
                                                    className={classes.textFieldSpacing}
                                                    fullWidth
                                                    label="Home Address"
                                                    variant="outlined"
                                                    name="address1"
                                                    value={employee.address1}
                                                    onChange={handleFormDataChange}
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                <TextField
                                                    className={classes.textFieldSpacing}
                                                    fullWidth
                                                    label="Home Address 2"
                                                    variant="outlined"
                                                    name="address2"
                                                    value={employee.address2}
                                                    onChange={handleFormDataChange}
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                <TextField
                                                    className={classes.textFieldSpacing}
                                                    fullWidth
                                                    label="City"
                                                    variant="outlined"
                                                    value={employee.city}
                                                    name="city"
                                                    onChange={handleFormDataChange}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs>
                                                <FormControl fullWidth variant="outlined" className={classes.select}>
                                                    <InputLabel id="province-label">Province</InputLabel>
                                                    <Select
                                                        labelId="province-label"
                                                        name="province"
                                                        value={employee.province}
                                                        onChange={handleFormDataChange}
                                                        label="Province"
                                                    >
                                                        <MenuItem value="">
                                                            <em>-- SELECT --</em>
                                                        </MenuItem>
                                                        <MenuItem value={"AB"}>Alberta</MenuItem>
                                                        <MenuItem value={"BC"}>British Columbia</MenuItem>
                                                        <MenuItem value={"MB"}>Manitoba</MenuItem>
                                                        <MenuItem value={"NB"}>New Brunswick</MenuItem>
                                                        <MenuItem value={"NL"}>Newfoundland and Labrador</MenuItem>
                                                        <MenuItem value={"NS"}>Nova Scotia</MenuItem>
                                                        <MenuItem value={"ON"}>Ontario</MenuItem>
                                                        <MenuItem value={"PE"}>Prince Edward Island</MenuItem>
                                                        <MenuItem value={"QC"}>Quebec</MenuItem>
                                                        <MenuItem value={"SK"}>Saskatchewan</MenuItem>
                                                        <MenuItem value={"NT"}>Northwest Territories</MenuItem>
                                                        <MenuItem value={"NU"}>Nunavut</MenuItem>
                                                        <MenuItem value={"YT"}>Yukon</MenuItem>
                                                    </Select>
                                                </FormControl>

                                                {/* <Autocomplete
                                                        className={classes.textFieldSpacing}
                                                        // value=""
                                                        // options={provincesAndStates}
                                                        getOptionLabel={(option) => option}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Province / State"
                                                                fullWidth
                                                                variant="outlined"
                                                                name="province"
                                                                value={employee.province}
                                                                onChange={handleFormDataChange}
                                                            />
                                                        )}
                                                    /> */}
                                            </Grid>
                                            <Grid item xs>
                                                <TextField
                                                    className={classes.textFieldSpacing}
                                                    fullWidth
                                                    label="Postal / Zip Code"
                                                    variant="outlined"
                                                    name="postal"
                                                    value={employee.postal}
                                                    inputProps={{ maxLength: 6 }}
                                                    onChange={handleFormDataChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </form>

                            <div className={classes.button} align="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    // onClick={handleSaveChanges}
                                >
                                    Save Changes
                                </Button>
                                {/* <Prompt
                                        // when={dataChanged}
                                        message="Leave page without saving your changes?"
                                    /> */}
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default Staffer;

import React from "react";

import { makeStyles, Typography, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(4),
    },
    logo: { width: "25%" },
    text: {
        // margin: theme.spacing(4, 0, 4)
    },
    img: { width: "50%" },
}));

export default function Inner404() {
    const classes = useStyles();

    return (
        <Grid container alignItems="center" className={classes.root}>
            <Grid item md={5} align="right">
                <img className={classes.img} src="/images/shrug.png" alt="Error 404" />
            </Grid>
            <Grid item md={1} />
            <Grid item md={6}>
                <div className={classes.text}>
                    <Typography variant="h3">Error 404</Typography>
                    <Typography variant="h5">Page Not Found ¯\_(ツ)_/¯</Typography>
                    <Typography component={Link} to={"/crm"} variant="subtitle1">
                        Go Back
                    </Typography>
                </div>
            </Grid>
        </Grid>
    );
}

import React, { useState } from "react";
import {
    Divider,
    IconButton,
    InputBase,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Title from "../../../layout/Title";
import { Link, useHistory } from "react-router-dom";
import settings from "../../../constants/setting";
import dateFormatter from "../../../helpers/DateFormatter";

const useStyles = makeStyles((theme) => ({
    root: {},
    search_bar: {
        height: "60px",
        padding: "0 4px",
        display: "flex",
        alignItems: "center",
        margin: theme.spacing(2, 0),
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        // fontSize: "2rem",
        padding: "10px",
        // padding: 10,
        // border: "1px solid gray",
    },
    search_select: {
        width: "150px",
        fontSize: "20px",
    },
    divider: {
        height: 28,
        margin: 4,
    },
    searchResult: {
        padding: theme.spacing(2),
        margin: theme.spacing(1, 0, 2),
    },
}));

export default function PurchaseOrders() {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    // const [poSearch, setPoSearch] = useState("");
    // const [searchBy, setSearchBy] = useState("po#");
    const [search, setSearch] = useState({
        searchBy: "po",
        searchString: "",
    });
    const [results, setResults] = useState(null);

    const handleSearch = (e) => {
        e.preventDefault();

        let tmp = `?search-type=${search.searchBy}&value=${search.searchString}`;
        const token = localStorage.getItem("warehouse-token");
        fetch(settings.api().pos + tmp, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setResults(data);
                // console.log(data);
            });
    };

    return (
        <div>
            <Title subtitle="Purchase Order" title="Search Purchase Order" />
            <Paper component="form" className={classes.search_bar}>
                <InputBase
                    autoFocus
                    className={classes.input}
                    placeholder="Search by"
                    inputProps={{
                        "aria-label": "search by",
                        style: {
                            fontSize: "20px",
                        },
                    }}
                    value={search.searchString}
                    onChange={(e) => setSearch({ ...search, searchString: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") handleSearch(e);
                    }}
                />
                <Divider className={classes.divider} orientation="vertical" />
                <Select
                    disableUnderline
                    variant="standard"
                    className={classes.search_select}
                    value={search.searchBy}
                    onChange={(e) => setSearch({ ...search, searchBy: e.target.value })}
                >
                    <MenuItem value={"po"}>PO #</MenuItem>
                    <MenuItem value={"supplier"}>Supplier</MenuItem>
                    <MenuItem value={"sku"}>SKU</MenuItem>
                </Select>
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton onClick={handleSearch} className={classes.iconButton} aria-label="search">
                    <SearchIcon style={{ fontSize: "2rem" }} />
                </IconButton>
            </Paper>

            {results === null ? (
                <Paper style={{ padding: theme.spacing(3, 0), textAlign: "center" }}>No searches has been made.</Paper>
            ) : results.length > 0 ? (
                <>
                    <Typography style={{ padding: theme.spacing(1) }} variant="subtitle2">
                        {results.length} Results for Search:{" "}
                    </Typography>

                    <TableContainer component={Paper} className={classes.puller_list}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">ID</TableCell>
                                    <TableCell>PO Number</TableCell>
                                    <TableCell>Supplier</TableCell>
                                    <TableCell align="center">Ordered Date</TableCell>
                                    <TableCell align="center">Last Modified</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {results.map((r) => (
                                    // <TableRow hover key={r.id} component={Link} to={`/crm/purchase-orders/${r.id}`}>
                                    <TableRow
                                        hover
                                        key={r.id}
                                        onClick={() => history.push(`/crm/purchase-orders/${r.id}`)}
                                    >
                                        <TableCell>{r.id}</TableCell>
                                        <TableCell>{r.poNumber}</TableCell>
                                        <TableCell>{r.supplierName}</TableCell>
                                        <TableCell align="center">{dateFormatter(r.createdUtc)}</TableCell>
                                        <TableCell align="center">{dateFormatter(r.modifiedUtc)}</TableCell>
                                        <TableCell>{r.poStatusName}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            ) : (
                <Paper style={{ padding: theme.spacing(3, 2) }}>
                    {results.length} Results for {search.searchString}
                </Paper>
            )}
        </div>
    );
}

import React, { useEffect, useState } from "react";
import {
    Grid,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    makeStyles,
    Card,
    CardContent,
    Button,
    TextField,
    InputAdornment,
    IconButton,
    DialogContent,
    Typography,
    Dialog,
    DialogTitle,
    DialogActions,
    // styled,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import settings from "../../../../constants/setting";
import CloseIcon from "@mui/icons-material/Close";

// import { tableCellClasses } from "@mui/material/TableCell";

// function createData(id, name, warning_level, inventory, floor_inventory) {
//     return { id, name, warning_level, inventory, floor_inventory };
// }

// const rows = [
//     createData("1", "Pencil", "NO Quantity", "-15 W1, 600 W2", "-3"),
//     createData("2", "Pen", "NO Quantity", "0 W1, 2,000 W2", "0"),
//     createData("3", "Caryon", "Low Quantity", "100 W1, 950 W2", "4"),
//     createData("4", "Eraser", "Low Quantity", "340 W1, 3,200 W2", "12"),
//     createData("5", "Shapener", "Moderate Quantity", "400 W1, 1,500 W2", "20"),
//     createData("6", "Paint Brush", "High Quantity", "1,000 W1, 3,000 W2", "34"),
//     createData("7", "Ruler", "High Quantity", "2,000 W1, 8,000 W2", "73"),
// ];

const useStyles = makeStyles((theme) => ({
    top: { margin: theme.spacing(1, 0, 2) },
    searchbar: { backgroundColor: "white" },
    cardHeader: {
        fontSize: 30,
        paddingLeft: 0,
        fontWeight: 700,
    },
    no_quantity: {
        backgroundColor: "#f44336",
        color: "white",
    },
    low_quantity: {
        backgroundColor: "#ff9800",
        color: "white",
    },
    moderate_quantity: {
        backgroundColor: "#af52bf",
        color: "white",
    },
    high_quantity: {
        backgroundColor: "#4caf50",
        color: "white",
    },
    button: {
        backgroundColor: theme.palette.background.default,
    },
}));

export default function Inventory() {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState("");
    const [floorInventory, setfloorInventory] = useState({});
    const [openLocate, setOpenLocate] = useState(false);
    const [locateProduct, setLocateProduct] = useState({});

    useEffect(() => {
        const token = localStorage.getItem("warehouse-token");
        fetch(settings.api().floor_inventory, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                // console.log(res);
                if (res.status === 200) {
                    return res.json();
                } else {
                    throw new Error(res.error);
                }
            })
            .then((data) => {
                // console.log(data);
                setfloorInventory(data);
            })
            .catch((err) => {
                console.error(err);
            });

        return () => {};
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getColor = (level) => {
        let result = "";
        switch (level) {
            case 0:
                result = "no_quantity";
                break;
            case 1:
                result = "low_quantity";
                break;
            case 2:
                result = "moderate_quantity";
                break;
            default:
                result = "high_quantity";
                break;
        }
        return classes[result];
    };

    const getWarningText = (level) => {
        let result = "";
        switch (level) {
            case 0:
                result = "EMPTY";
                break;
            case 1:
                result = "LOW";
                break;
            case 2:
                result = "MODERATE";
                break;
            default:
                result = "HIGH";
                break;
        }
        return result;
    };

    const handleSearch = (e) => {
        setSearch(e.target.value);
    };

    const getBinQuantity = (product) => {
        // console.log(product.bins);
        let result = 0;
        product?.bins.forEach((b) => {
            if (product.productBinId === b.productBinId) {
                result = b.quantity;
            }
        });

        return result;
    };

    const handleLocate = (product) => {
        setOpenLocate(true);
        setLocateProduct(product);
    };

    return (
        <div>
            <TextField
                id="barcodeField"
                autoFocus
                fullWidth
                className={classes.searchbar}
                variant="outlined"
                value={search}
                color="primary"
                onChange={handleSearch}
                // onKeyPress={handleBarcode}
                placeholder="Search products by keywords"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            <Grid container className={classes.top} spacing={1}>
                <Grid item xs={4}>
                    <Card align="center" className={classes.no_quantity}>
                        <CardContent>
                            <Typography variant="h3" style={{ margin: "15px auto 25px" }}>
                                {floorInventory?.totalWarningsHight}
                            </Typography>
                            <Typography variant="body1">No Quantity Items</Typography>

                            {/* <h3 className={classes.cardHeader}>{floorInventory?.totalWarningsHight}</h3>
                            <span>No Quantity Items</span> */}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card align="center" className={classes.low_quantity}>
                        <CardContent>
                            <Typography variant="h3" style={{ margin: "15px auto 25px" }}>
                                {floorInventory?.totalWarningsModerate}
                            </Typography>
                            <Typography variant="body1">Low Quantity Items</Typography>
                            {/* <h3 className={classes.cardHeader}>{floorInventory?.totalWarningsModerate}</h3>
                            <span>Low Quantity Items</span> */}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card align="center" className={classes.moderate_quantity}>
                        <CardContent>
                            <Typography variant="h3" style={{ margin: "15px auto 25px" }}>
                                {floorInventory?.totalWarningsLow}
                            </Typography>
                            <Typography variant="body1">Moderate Quantity Items</Typography>
                            {/* <h3 className={classes.cardHeader}>{floorInventory?.totalWarningsLow}</h3>
                            <span>Moderate Quantity Items</span> */}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* <Alert style={{ margin: "0 0 20px", backgroundColor: "black" }} variant="filled">
                Attention: There is 0 quantity of an item. Please restock now!
            </Alert> */}

            <Paper>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Variation SKU</TableCell>
                                <TableCell align="center">Name</TableCell>
                                <TableCell align="center">Warning Level</TableCell>
                                <TableCell align="center">Inventory (I/F)</TableCell>
                                <TableCell align="center">Bin Inventory</TableCell>
                                <TableCell align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        {floorInventory?.inventory ? (
                            <TableBody>
                                {floorInventory?.inventory.map((product, index) => {
                                    return (
                                        <TableRow key={index} hover>
                                            <TableCell>{product.productVariationSku}</TableCell>
                                            <TableCell>{product.productVariationName}</TableCell>
                                            <TableCell align="center" className={getColor(product.warningLevel)}>
                                                {/* {product.warningLevel} */}
                                                {getWarningText(product.warningLevel)}
                                            </TableCell>
                                            <TableCell align="center">
                                                {product.totalInventory}/{product.floatingInventory}
                                            </TableCell>
                                            <TableCell align="center">
                                                {getBinQuantity(product)} /{product.productdLowQuantityWarningLevel}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    variant="contained"
                                                    className={classes.button}
                                                    onClick={() => handleLocate(product)}
                                                >
                                                    Locate
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        ) : (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={6}>
                                        There is no inventory warning.
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={floorInventory?.inventory ? floorInventory?.inventory.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            <Dialog open={openLocate}>
                <DialogTitle>
                    <Grid container alignItems="center" justify="space-between">
                        <Grid item>Locate Product</Grid>
                        <Grid item>
                            <IconButton aria-label="close" onClick={() => setOpenLocate(false)}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent dividers>
                    <Typography style={{ marginBottom: "20px" }}>{locateProduct?.productVariationName}</Typography>
                    <Typography style={{ marginBottom: "20px" }}>
                        Floating Inventory: {locateProduct?.floatingInventory}
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Location</TableCell>
                                    <TableCell align="center">Level</TableCell>
                                    <TableCell align="center">Rack</TableCell>
                                    <TableCell align="center">Shelf</TableCell>
                                    <TableCell align="center">Bin</TableCell>
                                    <TableCell align="center">Bin Inventory</TableCell>
                                    <TableCell align="center">Time to Get (min)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {locateProduct?.bins?.map((b, i) => (
                                    <TableRow key={i}>
                                        <TableCell>{b.location}</TableCell>
                                        <TableCell align="center">{b.levelName}</TableCell>
                                        <TableCell align="center">{b.rack}</TableCell>
                                        <TableCell align="center">{b.shelf}</TableCell>
                                        <TableCell align="center">{b.bin}</TableCell>
                                        <TableCell align="center">{b.quantity}</TableCell>
                                        <TableCell align="center">{b.timeToGet}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenLocate(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

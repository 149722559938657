import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import componentsStore from "../components/Portal/redux/componentsStore";
import { drawerToggle } from "../components/Portal/redux/componentsActions";
// import { Link } from "react-router-dom";

// import UserMenu from "../components/Portal/Manager/UserMenu";
import Menu from "./menu/Menu";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import { Badge, Button, Dialog, DialogContent, DialogTitle, Grid, InputAdornment, TextField } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
// import NotificationsIcon from "@material-ui/icons/Notifications";
import PrintIcon from "@material-ui/icons/Print";
import CloseIcon from "@mui/icons-material/Close";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import FaceIcon from "@material-ui/icons/Face";
import ClearIcon from "@material-ui/icons/Clear";
import printer_settings from "../constants/printingAPISetting";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: "none",
    },
    title: {
        flexGrow: 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        height: "100vh",
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

const store = componentsStore();

store.subscribe(() => {
    console.log(store.getState());
});

// export default function
const Header = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    const toggleDraw = props.open;
    const handleDrawerOpen = props.handleDrawerOpen;
    const [isProduction, setIsProduction] = useState(false);
    const [printLabelDialog, setPrintLabelDialog] = useState(false);
    const [barcodeScan, setBarcodeScan] = useState("");
    const [isValidPackage, setIsValidPackage] = useState({
        valid: null,
        errorMsg: "",
    });
    const [printToggleValue, setPrintToggleValue] = useState("");
    const [packageInfo, setPackageInfo] = useState({});

    const packageBarcodeScanRef = useRef(null);

    useEffect(() => {
        setIsProduction(JSON.parse(localStorage.getItem("user")).isProduction);
    }, []);

    const handlePackageScanned = () => {
        if (barcodeScan[0] === "P" && (barcodeScan[1] === "K" || barcodeScan[1] === "D")) {
            //fetch package info
            // set setIsValidPackage true if successful
            // set error msg that barcode is invalid
            setIsValidPackage({
                valid: false,
                errorMsg: "This is not a valid package barcode, please try again.",
            });
        }
    };

    const handlePrintBagTag = () => {
        // if (isValidPackage.valid) {
        if (barcodeScan.length === 12 && barcodeScan[0] === "P" && barcodeScan[1] === "K") {
            const url = printer_settings.api().bag_tag + "/" + barcodeScan;
            fetch(url, {
                method: "POST",
            })
                .then((res) => {
                    console.log(res);
                    if (res.status !== 200) {
                        const err = new Error(res.error);
                        throw err;
                    }
                    return res.json();
                })
                .then((data) => console.log(data))
                .catch((error) => console.error(error));
        }
        setPrintLabelDialog(false);
        setPrintToggleValue("");
        setBarcodeScan("");
    };

    const handlePrintStudentLabel = () => {
        if (isValidPackage.valid) {
            //fetch to print server

            // const url = `https://print-server.saphera.com/api/v1/printing/student?name=${id}`;
            const url = "";
            fetch(url, {
                method: "GET",
                // headers: {},
            })
                .then((res) => {
                    console.log(res);
                    if (res.status !== 200) {
                        const err = new Error(res.error);
                        throw err;
                    }
                    return res.json();
                })
                .then((data) => {
                    console.log(data);
                    // check if successful calls return any data
                    //when successful close dialog
                })
                .catch((error) => console.error(error));
        }
        setPrintLabelDialog(false);
        setPrintToggleValue("");
        setBarcodeScan("");
    };

    useEffect(() => {
        if (printToggleValue === "bag_tag") {
            handlePrintBagTag();
        } else if (printToggleValue === "student_label") {
            handlePrintStudentLabel();
        }
    }, [printToggleValue]);

    return (
        <div className={classes.root}>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: toggleDraw,
                })}
                color={isProduction ? "primary" : "secondary"}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: toggleDraw,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap className={classes.title}>
                        Warehouse CRM
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<PrintIcon />}
                        onClick={() => setPrintLabelDialog(true)}
                    >
                        Print Labels
                    </Button>
                    {/*  <UserMenu /> */}
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: toggleDraw,
                    [classes.drawerClose]: !toggleDraw,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: toggleDraw,
                        [classes.drawerClose]: !toggleDraw,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerOpen}>
                        {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <Menu />
                </List>
            </Drawer>
            <Dialog open={printLabelDialog}>
                <DialogTitle>
                    <Grid container alignItems="center" justify="space-between">
                        <Grid item>Print Labels</Grid>
                        <Grid item>
                            <IconButton
                                aria-label="close"
                                onClick={() => {
                                    setPrintLabelDialog(false);
                                    setPrintToggleValue("");
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent dividers>
                    <TextField
                        autoFocus
                        inputRef={packageBarcodeScanRef}
                        error={isValidPackage.valid === false}
                        className={classes.barcode}
                        label={"Package Barcode"}
                        variant="outlined"
                        value={barcodeScan}
                        onChange={(e) => setBarcodeScan(e.target.value)}
                        fullWidth
                        placeholder="Scan Package"
                        inputProps={{
                            maxLength: 12,
                            // pattern: "[BC][0-9]{10}",
                            style: {
                                textAlign: "center",
                                backgroundColor: "white",
                            },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="clear scanned barcode"
                                        onClick={() => {
                                            setBarcodeScan("");
                                            setIsValidPackage({ ...isValidPackage, valid: null });
                                            packageBarcodeScanRef.current.focus();
                                        }}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "20px 0",
                        }}
                    >
                        <ToggleButtonGroup
                            value={printToggleValue}
                            exclusive
                            onChange={(e, newAlignment) => {
                                setPrintToggleValue(newAlignment);
                            }}
                            aria-label="text alignment"
                            style={{ width: "100%" }}
                        >
                            <ToggleButton value="bag_tag" aria-label="print bag Tag" style={{ width: "100%" }}>
                                <LocalMallIcon />
                                <Typography variant="h6" style={{ marginLeft: "10px" }}>
                                    Print Bag Tag
                                </Typography>
                            </ToggleButton>
                            {/* <ToggleButton value="student_label" aria-label="print student label">
                                <FaceIcon />
                                <Typography variant="h6" style={{ marginLeft: "10px" }}>
                                    Print Student Label
                                </Typography>
                            </ToggleButton> */}
                        </ToggleButtonGroup>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        open: state.drawerOpen,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleDrawerOpen: () => {
            dispatch(drawerToggle());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

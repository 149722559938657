import React from "react";
import Title from "../../../layout/Title";
import Graph from "./Graph";

export default function Report() {
    return (
        <div>
            <Title subtitle="Management" title="Reports" />
            <Graph />
        </div>
    );
}

import React from "react";
import { Route, Switch } from "react-router-dom";
// import Home from "../components/Home";
import Login from "../components/Login";
import Portal from "../components/Portal/Portal";
import Error404 from "../components/404";

import withAuth from "../withAuth";
// import PickerPortal from "../components/Portal/PickerPortal";

export default function AppRouter() {
    return (
        <Switch>
            {/* <Route path="/" exact component={Home} /> */}
            <Route path="/" exact component={Login} />
            <Route path="/crm" component={withAuth(Portal)} />
            {/* <Route path="/crm" component={Portal} /> */}

            {/* <Route path="/user" component={withAuth(PickerPortal)} /> */}

            <Route path="*" component={Error404} />
        </Switch>
    );
}

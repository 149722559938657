import React, { useState } from "react";
import Title from "../../../layout/Title";
import {
    Avatar,
    Card,
    CardHeader,
    Divider,
    // IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@material-ui/core";
import SchoolIcon from "@material-ui/icons/School";
// import VisibilityIcon from "@material-ui/icons/Visibility";

const useStyles = makeStyles((theme) => ({
    nameCell: {
        display: "flex",
        alignItems: "center",
    },
    avatar: {
        // height: 42,
        // width: 42,
        backgroundColor: theme.palette.primary.main,
        marginRight: theme.spacing(1),
    },
}));
export default function Schools(props) {
    const classes = useStyles();
    const [staff] = useState({
        name: "name",
        email: "email@email.com",
        address: "address",
        status: "Order Processed",
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div>
            <Title
                subtitle="Management"
                title="Schools"
                description="This page lists all Schools available in SchoolStart. You can use filters to search for a particular school, add or edit schools, or view individual school details."
            />
            <Card>
                <CardHeader title="All Schools" />
                <Divider />
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Primary Contact</TableCell>
                                <TableCell>Address</TableCell>
                                <TableCell>Status</TableCell>
                                {/* <TableCell align="center">Details</TableCell> */}
                                {/* <TableCell></TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((staff) => ( */}
                            <TableRow
                                hover
                                onClick={() => {
                                    props.history.push("/crm/schools/1");
                                }}
                                // key={staff.name}
                                // className={staff.status !== "On Duty" ? classes.nonactive : null}
                            >
                                <TableCell>
                                    <div className={classes.nameCell}>
                                        <Avatar className={classes.avatar}>
                                            <SchoolIcon />
                                        </Avatar>
                                        {staff.name}
                                    </div>
                                </TableCell>
                                <TableCell>{staff.email}</TableCell>
                                <TableCell>{staff.address}</TableCell>
                                <TableCell>{staff.status}</TableCell>
                                {/* <TableCell align="center">
                                    <IconButton
                                        onClick={() => {
                                            props.history.push("/crm/schools/id");
                                        }}
                                    >
                                        <VisibilityIcon />
                                    </IconButton>
                                </TableCell> */}
                            </TableRow>
                            {/* ))} */}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 15, 50]}
                    component="div"
                    count={1}
                    // {rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Card>
        </div>
    );
}
